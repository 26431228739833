import classnames from 'classnames';
import dayjs from 'dayjs';
import numeral from 'numeral';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { mapProps, withHandlers, withProps, withState } from 'recompose';

import { compose } from 'redux';
import { createSelector } from 'reselect';
import {
	mapDispatchEditPostCommentToProps,
	selectCreatePostComment,
	selectEditPostComment
} from '../../../store/selectors/posts';
import CommentForm from '../../forms/commentForm';
import Link from '../../lib/link';
import Spinner from '../../lib/spinner';
import { VoteButton } from '../../lib/voteButton';
import RenderedMarkdown from '../../renderedMarkdown';

import './postComment.scss';

export const enhance = compose(
	withProps((props) => ({
		...props,
		userVote: _.get(props, `userCommentVotes[${props.comment.id}]`) || 0
	})),
	connect(
		createSelector(
			selectCreatePostComment,
			selectEditPostComment,
			(createPostComment, editComment) => ({
				...createPostComment,
				...editComment
			})
		),
		{
			...mapDispatchEditPostCommentToProps
		}
	),
	mapProps((props) => ({
		...props,
		fullComment: props.comments[props.comment.id],
	})),
	withState('showReply', 'setShowReply', false),
	withState('showEdit', 'setShowEdit', false),
	withState('commentToEdit', 'setCommentToEdit', null),
	withHandlers({
		handleVote: (props) => (vote) => {
			return props.onVote(props.comment.id, vote);
		},
		handleAddComment: (props) => (comment) => {
			const {
				setShowReply
			} = props;
			return props.onAddComment({ ...comment, parentCommentId: props.fullComment.id })
			.then((data) => {
				if (!data.error) {
					setShowReply(false);
				}
				return data;
			});
		},
		handleUpdateComment: (props) => (fields) => {
			const {
				onUpdateComment,
				setShowEdit,
				setCommentToEdit,
				fullComment
			} = props;

			return onUpdateComment(fullComment.id, { ...fields })
			.then((data) => {
				if (!data.error) {
					setShowEdit(false);
					setCommentToEdit(null);
				}
				return data;
			});
		},
		handleGetFullCommentForEditing: (props) => () => {
			const {
				getUserComment,
				sessionData,
				setCommentToEdit,
				fullComment
			} = props;

			return getUserComment(sessionData.id, fullComment.id)
			.then((data) => {
				if (!data.error) {
					setCommentToEdit(data.payload);
				}
			});
		},
		handleRemoveComment: (props) => () => {
			return props.onRemove(props.fullComment.id);
		}
	})
);

function formatPostedDate(createdAt) {
	return dayjs(createdAt).fromNow();
}

export function PostComment(props) {
	const {
		comments,
		comment,
		users,
		sessionData,
		fullComment,
		handleVote,
		showReply,
		setShowReply,
		handleAddComment,
		creatingPostComment,
		onAddComment,
		userCommentVotes,
		onVote,
		userVote,
		showEdit,
		setShowEdit,
		gettingUserComment,
		commentToEdit,
		handleGetFullCommentForEditing,
		onUpdateComment,
		handleUpdateComment,
		updatingPostComment,
		showModActions,
		handleRemoveComment,
		onRemove
	} = props;

	if (!fullComment) {
		return null;
	}

	const karma = parseInt(fullComment.upvotes) - parseInt(fullComment.downvotes);

	const author = users[fullComment.userId];

	return (
		<div className={classnames('post-comment', props.className)}>
			<div className="comment-content-wrapper">
				<div className="vote-container">
					<VoteButton
						direction="up"
						onClick={handleVote}
						vote={userVote}
						size="small"
					/>
					<VoteButton
						direction="down"
						onClick={handleVote}
						vote={userVote}
						size="small"
					/>
				</div>
				<div className="comment-content">
					<div className="comment-meta">
						<div className="comment-meta-item author">
							<Link to={`/users/${author.username}`}>
								{author.username}
							</Link>
						</div>
						<div className="comment-meta-item score">
							{numeral(karma).format('0,0')} points {formatPostedDate(fullComment.createdAt)}
						</div>
					</div>
					<RenderedMarkdown content={fullComment.compiledBody} />
					{fullComment.isEdited && (
						<div className="comment-edited">
							Edited on {dayjs(fullComment.editedAt).format('MMMM D, YYYY @ h:mm A')}
						</div>
					)}
					{fullComment.isRemoved && (
						<div className="comment-edited">
							Removed on {dayjs(fullComment.removedAt).format('MMMM D, YYYY @ h:mm A')}
						</div>
					)}
					<div className="comment-actions">
						<div className="comment-action-item reply" onClick={() => setShowReply(true)}>
							reply
						</div>
						{!!(sessionData.hasSession && fullComment.userId === sessionData.id) && (
							<div
								className="comment-action-item edit"
								onClick={() => {
									setShowEdit(true);
									handleGetFullCommentForEditing()
								}}
							>
								edit
							</div>
						)}
						{showModActions === true && (
							<div className="comment-action-item moderate" onClick={handleRemoveComment}>
								remove
							</div>
						)}
						{/*<div className="comment-action-item">
							report
						</div>*/}
					</div>
					{showReply && (
						<div className="reply-comment">
							<CommentForm
								formName={`add-reply-comment-form-${fullComment.id}`}
								onSubmit={handleAddComment}
								loading={creatingPostComment.loading}
								formError={creatingPostComment.error}
								parentCommentId={fullComment.id}
								formAction="create"
							/>
						</div>
					)}
					{showEdit && (
						<div className="reply-comment">
							{commentToEdit ? (
								<CommentForm
									formName={`edit-reply-comment-form-${fullComment.id}`}
									onSubmit={handleUpdateComment}
									loading={updatingPostComment.loading}
									formError={updatingPostComment.error}
									formAction="edit"
									initialValues={{
										rawBody: commentToEdit.rawBody
									}}
								/>
							) : (
								<Spinner />
							)}
						</div>
					)}
				</div>
			</div>
			{!!(comment.children || []).length && (
				<div className="child-comments">
					{_.map(comment.children, (c) => (
						<EnhancedPostComment
							key={c.id}
							sessionData={sessionData}
							comment={c}
							comments={comments}
							users={users}
							onVote={onVote}
							onAddComment={onAddComment}
							userCommentVotes={userCommentVotes}
							onUpdateComment={onUpdateComment}
							showModActions={showModActions}
							onRemove={onRemove}
						/>
					))}
				</div>
			)}
		</div>
	);
}

export const PostCommentType = propTypes.shape({
	id: propTypes.string,
	numericId: propTypes.number,
	depth: propTypes.number,
	children: propTypes.arrayOf(propTypes.object)
})

PostComment.propTypes = {
	sessionData: propTypes.shape({
		hasSession: propTypes.bool
	}).isRequired,
	comment: PostCommentType.isRequired,
	comments: propTypes.objectOf(propTypes.shape({
		id: propTypes.string,
		compileBody: propTypes.string,
		depth: propTypes.number,
		upvotes: propTypes.string,
		downvotes: propTypes.string,
		createdAt: propTypes.string,
		updatedAt: propTypes.string,
		isEdited: propTypes.bool,
		editedAt: propTypes.string,
		isRemoved: propTypes.bool,
		removedAt: propTypes.string,
	})).isRequired,
	users: propTypes.objectOf(propTypes.shape({
		username: propTypes.string
	})).isRequired,
	onVote: propTypes.func.isRequired,
	onAddComment: propTypes.func.isRequired,
	onUpdateComment: propTypes.func.isRequired,
	userCommentVotes: propTypes.objectOf(propTypes.number),
	showModActions: propTypes.bool,
	onRemove: propTypes.func
};

const EnhancedPostComment = enhance(PostComment);
export default EnhancedPostComment;
