import { generateCRUDActions, spreadCRUDActions } from '../helpers';
import { CALL_API } from '../middleware/api';

const namespace = 'POSTS';

export const POSTS = {
	GET_ALL_POSTS: generateCRUDActions(namespace, 'GET_ALL_POSTS'),
	CREATE_POST_COMMENT: generateCRUDActions(namespace, 'CREATE_POST_COMMENT'),
	GET_POST_COMMENTS: generateCRUDActions(namespace, 'GET_POST_COMMENTS'),
	UPSERT_POST_VOTE: generateCRUDActions(namespace, 'UPSERT_POST_VOTE'),
	UPSERT_COMMENT_VOTE: generateCRUDActions(namespace, 'UPSERT_COMMENT_VOTE'),
	UPDATE_POST_CONTENT: generateCRUDActions(namespace, 'UPDATE_POST_CONTENT'),
	GET_USER_COMMENT: generateCRUDActions(namespace, 'GET_USER_COMMENT'),
	UPDATE_POST_COMMENT: generateCRUDActions(namespace, 'UPDATE_POST_COMMENT'),
};

export function getAllPosts(query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(POSTS.GET_ALL_POSTS),
			endpoint: '/posts',
			method: 'get',
			query,
			options: {
				...options,
			}
		}
	};
}

export function createPostComment(communityId, postId, data, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(POSTS.CREATE_POST_COMMENT),
			endpoint: `/communities/${communityId}/posts/${postId}/comments`,
			method: 'post',
			data,
			options: {
				...options,
				communityId,
				postId,
				data
			}
		}
	};
}

export function getPostComments(displaySlug, postShortId, query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(POSTS.GET_POST_COMMENTS),
			endpoint: `/communities-by-slug/${displaySlug}/posts-by-short-id/${postShortId}/comments`,
			method: 'get',
			query,
			options: {
				...options,
				displaySlug,
				postShortId,
			}
		}
	};
}

export function upsertPostVote(communityId, postId, vote, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(POSTS.UPSERT_POST_VOTE),
			endpoint: `/communities/${communityId}/posts/${postId}/vote`,
			method: 'put',
			data: { vote },
			options: {
				...options,
				communityId,
				postId
			}
		}
	};
}

export function upsertCommentVote(postId, commentId, vote, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(POSTS.UPSERT_COMMENT_VOTE),
			endpoint: `/posts/${postId}/comments/${commentId}/vote`,
			method: 'put',
			data: { vote },
			options: {
				...options,
				commentId,
				postId
			}
		}
	};
}

export function updatePostContent(userId, postId, data, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(POSTS.UPDATE_POST_CONTENT),
			endpoint: `/users/${userId}/posts/${postId}`,
			method: 'patch',
			data,
			options: {
				...options,
				postId
			}
		}
	};
}

export function getUserComment(userId, commentId, query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(POSTS.GET_USER_COMMENT),
			endpoint: `/users/${userId}/comments/${commentId}`,
			method: 'get',
			query,
			options: {
				...options,
				userId,
				commentId
			}
		}
	};
}

export function updatePostComment(userId, commentId, data, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(POSTS.UPDATE_POST_COMMENT),
			endpoint: `/users/${userId}/comments/${commentId}`,
			method: 'patch',
			data,
			options: {
				...options,
				userId,
				commentId
			}
		}
	};
}
