import { CALL_API } from '../middleware/api';

export const ACCOUNT = {
	CREATE_ACCOUNT_REQUEST: 'ACCOUNT/CREATE_ACCOUNT_REQUEST',
	CREATE_ACCOUNT_SUCCESS: 'ACCOUNT/CREATE_ACCOUNT_SUCCESS',
	CREATE_ACCOUNT_FAILURE: 'ACCOUNT/CREATE_ACCOUNT_FAILURE',
	CREATE_PAYMENT_SETUP_INTENT_REQUEST: 'ACCOUNT/CREATE_PAYMENT_SETUP_INTENT_REQUEST',
	CREATE_PAYMENT_SETUP_INTENT_SUCCESS: 'ACCOUNT/CREATE_PAYMENT_SETUP_INTENT_SUCCESS',
	CREATE_PAYMENT_SETUP_INTENT_FAILURE: 'ACCOUNT/CREATE_PAYMENT_SETUP_INTENT_FAILURE',
	CREATE_PAYMENT_METHOD_REQUEST: 'ACCOUNT/CREATE_PAYMENT_METHOD_REQUEST',
	CREATE_PAYMENT_METHOD_SUCCESS: 'ACCOUNT/CREATE_PAYMENT_METHOD_SUCCESS',
	CREATE_PAYMENT_METHOD_FAILURE: 'ACCOUNT/CREATE_PAYMENT_METHOD_FAILURE',
	LIST_PAYMENT_METHODS_REQUEST: 'ACCOUNT/LIST_PAYMENT_METHODS_REQUEST',
	LIST_PAYMENT_METHODS_SUCCESS: 'ACCOUNT/LIST_PAYMENT_METHODS_SUCCESS',
	LIST_PAYMENT_METHODS_FAILURE: 'ACCOUNT/LIST_PAYMENT_METHODS_FAILURE',
	CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_REQUEST: 'ACCOUNT/CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_REQUEST',
	CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_SUCCESS: 'ACCOUNT/CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_SUCCESS',
	CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_FAILURE: 'ACCOUNT/CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_FAILURE',
	GET_DETAILED_SUBSCRIPTION_REQUEST: 'ACCOUNT/GET_DETAILED_SUBSCRIPTION_REQUEST',
	GET_DETAILED_SUBSCRIPTION_SUCCESS: 'ACCOUNT/GET_DETAILED_SUBSCRIPTION_SUCCESS',
	GET_DETAILED_SUBSCRIPTION_FAILURE: 'ACCOUNT/GET_DETAILED_SUBSCRIPTION_FAILURE',
	UPDATE_USER_REQUEST: 'ACCOUNT/UPDATE_USER_REQUEST',
	UPDATE_USER_SUCCESS: 'ACCOUNT/UPDATE_USER_SUCCESS',
	UPDATE_USER_FAILURE: 'ACCOUNT/UPDATE_USER_FAILURE',
	UPDATE_USER_PASSWORD_REQUEST: 'ACCOUNT/UPDATE_USER_PASSWORD_REQUEST',
	UPDATE_USER_PASSWORD_SUCCESS: 'ACCOUNT/UPDATE_USER_PASSWORD_SUCCESS',
	UPDATE_USER_PASSWORD_FAILURE: 'ACCOUNT/UPDATE_USER_PASSWORD_FAILURE',
	REQUEST_PASSWORD_RESET_REQUEST: 'ACCOUNT/REQUEST_PASSWORD_RESET_REQUEST',
	REQUEST_PASSWORD_RESET_SUCCESS: 'ACCOUNT/REQUEST_PASSWORD_RESET_SUCCESS',
	REQUEST_PASSWORD_RESET_FAILURE: 'ACCOUNT/REQUEST_PASSWORD_RESET_FAILURE',
	RESET_PASSWORD_REQUEST: 'ACCOUNT/RESET_PASSWORD_REQUEST',
	RESET_PASSWORD_SUCCESS: 'ACCOUNT/RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_FAILURE: 'ACCOUNT/RESET_PASSWORD_FAILURE',
	CREATE_SUBSCRIPTION_REQUEST: 'ACCOUNT/CREATE_SUBSCRIPTION_REQUEST',
	CREATE_SUBSCRIPTION_SUCCESS: 'ACCOUNT/CREATE_SUBSCRIPTION_SUCCESS',
	CREATE_SUBSCRIPTION_FAILURE: 'ACCOUNT/CREATE_SUBSCRIPTION_FAILURE',
	CREATE_BETA_SIGNUP_REQUEST: 'ACCOUNT/CREATE_BETA_SIGNUP_REQUEST',
	CREATE_BETA_SIGNUP_SUCCESS: 'ACCOUNT/CREATE_BETA_SIGNUP_SUCCESS',
	CREATE_BETA_SIGNUP_FAILURE: 'ACCOUNT/CREATE_BETA_SIGNUP_FAILURE',
};

export function createBetaSignup(data, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.CREATE_BETA_SIGNUP_REQUEST, ACCOUNT.CREATE_BETA_SIGNUP_SUCCESS, ACCOUNT.CREATE_BETA_SIGNUP_FAILURE],
			endpoint: '/waitlist',
			method: 'post',
			data,
			options
		}
	};
}

export function createUserAccount(data, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.CREATE_ACCOUNT_REQUEST, ACCOUNT.CREATE_ACCOUNT_SUCCESS, ACCOUNT.CREATE_ACCOUNT_FAILURE],
			endpoint: '/accounts',
			method: 'post',
			data,
			options
		}
	};
}

export function createPaymentSetupIntent(organizationId, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.CREATE_PAYMENT_SETUP_INTENT_REQUEST, ACCOUNT.CREATE_PAYMENT_SETUP_INTENT_SUCCESS, ACCOUNT.CREATE_PAYMENT_SETUP_INTENT_FAILURE],
			endpoint: `/organizations/${organizationId}/setup-card`,
			method: 'post',
			options
		}
	};
}

export function createPaymentMethod(organizationId, data, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.CREATE_PAYMENT_METHOD_REQUEST, ACCOUNT.CREATE_PAYMENT_METHOD_SUCCESS, ACCOUNT.CREATE_PAYMENT_METHOD_FAILURE],
			endpoint: `/organizations/${organizationId}/payment-methods`,
			method: 'post',
			data,
			options
		}
	};
}

export function listOrganizationPaymentMethods(organizationId, query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.LIST_PAYMENT_METHODS_REQUEST, ACCOUNT.LIST_PAYMENT_METHODS_SUCCESS, ACCOUNT.LIST_PAYMENT_METHODS_FAILURE],
			endpoint: `/organizations/${organizationId}/payment-methods`,
			method: 'get',
			options: {
				...options,
				organizationId
			},
			query
		}
	};
}

export function createPaymentMethodAndSubscription(organizationId, data, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_REQUEST, ACCOUNT.CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_SUCCESS, ACCOUNT.CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_FAILURE],
			endpoint: `/organizations/${organizationId}/start-subscription`,
			method: 'post',
			data,
			options
		}
	};
}

export function getDetailedSubscriptionsForOrganization(organizationId, data, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.GET_DETAILED_SUBSCRIPTION_REQUEST, ACCOUNT.GET_DETAILED_SUBSCRIPTION_SUCCESS, ACCOUNT.GET_DETAILED_SUBSCRIPTION_FAILURE],
			endpoint: `/organizations/${organizationId}/subscriptions`,
			method: 'get',
			data,
			options: {
				...options,
				organizationId
			}
		}
	};
}

export function updateUser(userId, data, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.UPDATE_USER_REQUEST, ACCOUNT.UPDATE_USER_SUCCESS, ACCOUNT.UPDATE_USER_FAILURE],
			endpoint: `/users/${userId}`,
			method: 'patch',
			data,
			options
		}
	};
}

export function updateUserPassword(userId, data, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.UPDATE_USER_PASSWORD_REQUEST, ACCOUNT.UPDATE_USER_PASSWORD_SUCCESS, ACCOUNT.UPDATE_USER_PASSWORD_FAILURE],
			endpoint: `/users/${userId}/password`,
			method: 'put',
			data,
			options
		}
	};
}

export function requestPasswordReset(email, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.REQUEST_PASSWORD_RESET_REQUEST, ACCOUNT.REQUEST_PASSWORD_RESET_SUCCESS, ACCOUNT.REQUEST_PASSWORD_RESET_FAILURE],
			endpoint: `/password-reset`,
			method: 'post',
			data: { email },
			options
		}
	};
}

export function resetPassword(token, data, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.RESET_PASSWORD_REQUEST, ACCOUNT.RESET_PASSWORD_SUCCESS, ACCOUNT.RESET_PASSWORD_FAILURE],
			endpoint: `/password-reset/${token}`,
			method: 'put',
			data,
			options
		}
	};
}

export function createSubscriptionForOrganization(organizationId, data, options = {}) {
	return {
		[CALL_API]: {
			types: [ACCOUNT.CREATE_SUBSCRIPTION_REQUEST, ACCOUNT.CREATE_SUBSCRIPTION_SUCCESS, ACCOUNT.CREATE_SUBSCRIPTION_FAILURE],
			endpoint: `/organizations/${organizationId}/subscriptions`,
			method: 'post',
			data,
			options
		}
	};
}
