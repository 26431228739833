import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { compose, withHandlers } from 'recompose';
import Headline from '../headline';
import Heroicon from '../heroicon';

import './modal.scss';

export const enhance = compose(
	withHandlers({
		handleClose: (props) => () => {
			if (props.onClose) {
				props.onClose();
			}
		}
	})
);

export function Modal(props) {
	const { handleClose, className, showing, children, closeable } = props;

	return (
		<div className={classnames('modal', className, { showing, closeable })}>
			<div className="modal-wrapper">
				{props.title && (
					<div className="modal-title">
						<Headline tag="h3" as="h4">
							{props.title}
						</Headline>
					</div>
				)}
				<Heroicon icon="XMark" className="modal-close" onClick={handleClose} />
				<div className="modal-content">
					{children}
				</div>
			</div>
		</div>
	);
}

Modal.propTypes = {
	showing: propTypes.bool.isRequired,
	closeable: propTypes.bool,
	onClose: propTypes.func,
	title: propTypes.any
};

Modal.defaultProps = {
	showing: false,
	closeable: true
};

export default enhance(Modal);
