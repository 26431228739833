import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, mapProps } from 'recompose';
import { createSelector } from 'reselect';
import { buildLoginReturnTo } from '../../../lib/helpers';
import { selectApp } from '../../../store/selectors/app';

import { dispatchSession, selectSession } from '../../../store/selectors/session';
import Page from '../../page';

import './authenticatedPage.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectSession,
			selectApp,
			(session, app) => ({
				...session,
				...app
			})
		),
		dispatchSession
	),
	mapProps((props) => ({
		...props,
		loginReturnTo: buildLoginReturnTo(props)
	})),
	lifecycle({
		componentDidMount() {
			const { sessionData, loginReturnTo } = this.props;

			if (!sessionData.hasSession) {
				window.location.href = loginReturnTo;
			}
		}
	})
);

export function AuthenticatedPage(props) {
	const {
		sessionData
	} = props;
	return (
		<Page
			{...props}
			className={classnames(props.className, 'authenticated-page')}
		>
			{props.children}
		</Page>
	);
}

AuthenticatedPage.propTypes = {
	children: propTypes.node,
	headerContent: propTypes.node,
	requiresSession: propTypes.bool
};

AuthenticatedPage.defaultProps = {
	requiresSession: false
};

export default enhance(AuthenticatedPage);
