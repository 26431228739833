import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { renderInput } from '../../../lib/formHelpers';
import { Button } from '../../lib/button';
import Form from '../../lib/form';
import { ButtonRow, FormItem, FormRow } from '../../lib/formRow';

import './updatePasswordForm.scss';

const FORM_NAME = 'update-password-form';

export const enhance = compose(
	reduxForm({ form: FORM_NAME }),
	connect(
		createSelector(
			(state) => ({ formValues: getFormValues(FORM_NAME)(state) }),
			(formValues) => ({
				...formValues
			})
		)
	),
	withHandlers({
		interceptSubmit: (props) => (fields) => {
			return props.onSubmit(fields)
			.then((data) => {
				if (!data.error) {
					props.reset();
				}
				return data;
			});
		}
	})
);

const hasNewPassword = (value) => {
	if (!value || !value.length || value.length < 10) {
		return 'Please provide a valid password';
	}
}

export function UpdatePasswordForm(props) {
	const {
		onSubmit,
		handleSubmit,
		valid,
		anyTouched,
		loading,
		formError,
		interceptSubmit
	} = props;
	const buttonProps = {
		size: 'regular',
		display: 'primary',
		loading
	};
	if (!valid || !anyTouched) {
		buttonProps.disabled = true;
	}
	return (
		<div className={classnames('password-reset-form')}>
			<Form onSubmit={handleSubmit(interceptSubmit)} className={classnames(`${props.color}-text`)}>
				<FormRow>
					<Field
						id="existingPassword"
						name="existingPassword"
						type="password"
						label="Current password"
						withLabel={true}
						component={renderInput}
						isRequired
					/>
				</FormRow>
				<FormRow>
					<Field
						id="newPassword"
						name="newPassword"
						type="password"
						label="New password"
						withLabel={true}
						component={renderInput}
						validate={[hasNewPassword]}
						helpText="Password must be 10 or more characters"
						isRequired
					/>
				</FormRow>
				<ButtonRow>
					<Button {...buttonProps} size="small">
						Update password
					</Button>
				</ButtonRow>
				{!!(formError && formError.message) && (
					<FormRow>
						<FormItem>
							<div className="form-formError-message">
								{formError.message}
							</div>
						</FormItem>
					</FormRow>
				)}
			</Form>
		</div>
	);
}

UpdatePasswordForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
	loading: propTypes.bool,
	formError: propTypes.any,
};

export default enhance(UpdatePasswordForm);
