import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { buildCommunityUrl, formatCommunitySubscribers } from '../../lib/helpers';
import { mapDispatchCommunitySubscriptionToProps, selectCommunitySubscription } from '../../store/selectors/session';
import CommunityModeratorList from '../communityModerators/communityModeratorList';
import CommunityRuleList from '../communityRules/communityRuleList';
import { Button } from '../lib/button';
import Headline from '../lib/headline';
import Link from '../lib/link';
import Paragraph from '../lib/paragraph';
import LoginSignupModal from '../loginSignupModal';
import RenderedMarkdown from '../renderedMarkdown';

import './communitySidebar.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectCommunitySubscription,
			(subscription) => ({
				...subscription
			})
		), {
			...mapDispatchCommunitySubscriptionToProps
		}
	),
	withState('signInSubscribe', 'setSignInSubscribe', null),
	withState('showLoginSignupModal', 'setShowLoginSignupModal', false),
	withHandlers({
		handleJoinCommunity: (props) => (communityId) => {
			const {
				sessionData,
				createSubscription
			} = props;

			return createSubscription(sessionData.id, { communityId });
		},
		handleLeaveCommunity: (props) => (subscriptionId) => {
			const {
				sessionData,
				deleteSubscription
			} = props;

			return deleteSubscription(sessionData.id, subscriptionId);
		}
	}),
	withHandlers({
		interceptOnJoin: (props) => () => {
			const {
				sessionData,
				handleJoinCommunity,
				setSignInSubscribe,
				community,
				setShowLoginSignupModal
			} = props;

			if (sessionData.hasSession) {
				return handleJoinCommunity(community.id);
			}

			setSignInSubscribe(community.id);
			setShowLoginSignupModal(true);
		},
		interceptOnLeave: (props) => () => {
			const {
				sessionData,
				handleLeaveCommunity,
				community
			} = props;

			const subscription = _.find(sessionData.subscriptions, (s) => s.communityId === community.id);
			if (!subscription) {
				return;
			}
			return handleLeaveCommunity(subscription.id);
		},
		handleCompletedSignupLogin: (props) => () => {
			const {
				setShowLoginSignupModal,
				signInSubscribe,
				handleJoinCommunity
			} = props;
			setShowLoginSignupModal(false);

			// defer to the end of the event loop to allow redux to
			// process and store the new session
			setTimeout(() => {
				handleJoinCommunity(signInSubscribe);
			}, 500);
		}
	})
);

export function CommunitySidebar(props) {
	const {
		interceptOnJoin,
		showLoginSignupModal,
		setShowLoginSignupModal,
		handleCompletedSignupLogin,
		community,
		sessionData,
		interceptOnLeave,
	} = props;

	const isUserSubscribed = sessionData.hasSession ? !!_.find(sessionData.subscriptions, (s) => s.communityId === community.id) : false;
	const isUserMod = sessionData.hasSession ? !!_.find(sessionData.moderators, (m) => m.communityId === community.id) : false;

	const createPostUrl = `${buildCommunityUrl(_.get(props, 'community.displaySlug'))}/create-post`;
	return (
		<div className={classnames('community-sidebar', props.className)}>
			<Button
				className="new-post"
				isLink
				href={sessionData.hasSession ? createPostUrl : `/login?returnTo=${createPostUrl}`}
			>
				Create new post
			</Button>
			<div className="community-metadata">
				<Headline tag="h1" size={FONT_SIZES.lg} weight={FONT_WEIGHTS.bold} className="community-name">
					{props.community.displaySlug}
				</Headline>
				{!!(community.about && community.about.length) && (
					<Paragraph className="community-about">
						{community.about}
					</Paragraph>
				)}
				<div className="subscribers">
					{!isUserSubscribed ? (
						<Button
							size="xs"
							onClick={interceptOnJoin}
							display="secondary"
						>
							Join
						</Button>
					) : (
						<Button
							size="xs"
							onClick={interceptOnLeave}
							display="danger-outline"
						>
							Leave
						</Button>
					)}
					<div className="subscriber-count">
						{formatCommunitySubscribers(community.subscriberCount)}
					</div>
				</div>
				<RenderedMarkdown
					content={_.get(props.community, 'description.compiledDescription') || ''}
					className="description"
				/>
				{!!(community.rules && community.rules.length) && (
					<div className="community-rules">
						<Headline tag="h3" size={FONT_SIZES.lg} weight={FONT_WEIGHTS.bold} className="rules-headline">
							Rules
						</Headline>
						<CommunityRuleList rules={community.rules} />
					</div>
				)}
				<div className="community-moderators">
					<Headline tag="h3" size={FONT_SIZES.lg} weight={FONT_WEIGHTS.bold} className="moderators-headline">
						Moderators {isUserMod ? <Link to={`${buildCommunityUrl(community.displaySlug)}/mod`} className="manage-community-link">(Manage Community)</Link> : null}
					</Headline>
					<CommunityModeratorList moderators={community.moderators} sessionData={sessionData} />
				</div>
				{showLoginSignupModal === true && (
					<LoginSignupModal
						onClose={() => setShowLoginSignupModal(false)}
						onComplete={() => handleCompletedSignupLogin()}
					/>
				)}
			</div>
		</div>
	);
}

CommunitySidebar.propTypes = {
	community: propTypes.shape({
		id: propTypes.string,
		name: propTypes.string,
		about: propTypes.about,
		description: propTypes.shape({
			compiledDescription: propTypes.string
		}),
		slug: propTypes.string,
		displaySlug: propTypes.string,
		subscriberCount: propTypes.oneOfType([propTypes.string, propTypes.number]),
		rules: propTypes.arrayOf(propTypes.shape({
			compiledRule: propTypes.string,
			position: propTypes.number,
			title: propTypes.string
		})),
		moderators: propTypes.arrayOf(propTypes.shape({
			username: propTypes.string
		}))
	}),
	sessionData: propTypes.shape({
		hasSession: propTypes.bool,
		moderators: propTypes.arrayOf(propTypes.shape({
			communityId: propTypes.string
		}))
	}),
}

export default enhance(CommunitySidebar);
