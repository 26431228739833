import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import ContentWrapper from '../contentWrapper';
import Text from '../lib/text';
import './footer.scss';

function FooterSection(props) {
	return (
		<div className={classnames('footer-section', props.footer)}>
			<div className="footer-section-label">
				<Text size="sm" color="light-gray">
					{props.label}
				</Text>
			</div>
			<div className="footer-section-content">
				{props.children}
			</div>
		</div>
	);
}

FooterSection.propTypes = {
	label: propTypes.any.isRequired
};

export function Footer({ className }) {
	return (
		<div className={classnames('footer', className)}>
			{/*<ContentWrapper className="footer-content">
				<div className="left-side">
					<FooterSection label="Where to find us:">
						<div className="social-icons">
							<Link to="https://facebook.com/klique" target="_blank" rel="nofollow">
								<FacebookLogo />
							</Link>
							<Link to="https://www.instagram.com/klique/" target="_blank" rel="nofollow">
								<InstagramLogo />
							</Link>
							<Link to="https://twitter.com/kliqueio" target="_blank" rel="nofollow">
								<TwitterLogo />
							</Link>
						</div>
					</FooterSection>
					<FooterSection label="Helpful Links">
						<div className="link-list">
							<Link to="/" color="light">
								Home
							</Link>
							<Link to="/pricing" color="light">
								Pricing
							</Link>
							Link to="/articles" color="light">
								Articles
							</Link>
							<Link to="/learn/guides" color="light">
								Guides
							</Link>
						</div>
					</FooterSection>
					<FooterSection label="Get in touch">
						<div className="link-list">
							<Link to="mailto:hello@klique.io" target="_blank" color="light">
								<MIcon icon="email" />hello@klique.io
							</Link>
						</div>
					</FooterSection>
				</div>
			</ContentWrapper>*/}
			<ContentWrapper className="copyright-wrapper">
				<div className="copyright">
					© {(new Date()).getFullYear()} klique.io
				</div>
			</ContentWrapper>

		</div>
	);
}

export default Footer;
