import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import './banner.scss';

export function Banner(props) {
	return (
		<div className={classnames('banner', props.className)}>
			{props.children}
		</div>
	);
}

export default Banner;
