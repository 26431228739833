import { generateCRUDActions, spreadCRUDActions } from '../helpers';
import { CALL_API } from '../middleware/api';
import { POSTS } from '../posts/actions';

const namespace = 'SESSION';

export const SESSION = {
	CREATE_SESSION_REQUEST: 'SESSION/CREATE_SESSION_REQUEST',
	CREATE_SESSION_SUCCESS: 'SESSION/CREATE_SESSION_SUCCESS',
	CREATE_SESSION_FAILURE: 'SESSION/CREATE_SESSION_FAILURE',
	GET_ACTIVE_SESSION_REQUEST: 'SESSION/GET_ACTIVE_SESSION_REQUEST',
	GET_ACTIVE_SESSION_SUCCESS: 'SESSION/GET_ACTIVE_SESSION_SUCCESS',
	GET_ACTIVE_SESSION_FAILURE: 'SESSION/GET_ACTIVE_SESSION_FAILURE',
	REQUEST_VERIFICATION_LINK_REQUEST: 'SESSION/REQUEST_VERIFICATION_LINK_REQUEST',
	REQUEST_VERIFICATION_LINK_SUCCESS: 'SESSION/REQUEST_VERIFICATION_LINK_SUCCESS',
	REQUEST_VERIFICATION_LINK_FAILURE: 'SESSION/REQUEST_VERIFICATION_LINK_FAILURE',
	CREATE_SUBSCRIPTION: generateCRUDActions(namespace, 'CREATE_SUBSCRIPTION'),
	DELETE_SUBSCRIPTION: generateCRUDActions(namespace, 'DELETE_SUBSCRIPTION'),
};

export function createUserSession(data, options = {}) {
	return {
		[CALL_API]: {
			types: [SESSION.CREATE_SESSION_REQUEST, SESSION.CREATE_SESSION_SUCCESS, SESSION.CREATE_SESSION_FAILURE],
			endpoint: '/sessions',
			method: 'post',
			data,
			options
		}
	};
}

export function getActiveSession(options = {}) {
	return {
		[CALL_API]: {
			types: [SESSION.GET_ACTIVE_SESSION_REQUEST, SESSION.GET_ACTIVE_SESSION_SUCCESS, SESSION.GET_ACTIVE_SESSION_FAILURE],
			endpoint: '/active-session',
			method: 'get',
			options
		}
	};
}


export function requestVerificationLink(email, options = {}) {
	return {
		[CALL_API]: {
			types: [SESSION.REQUEST_VERIFICATION_LINK_REQUEST, SESSION.REQUEST_VERIFICATION_LINK_SUCCESS, SESSION.REQUEST_VERIFICATION_LINK_FAILURE],
			endpoint: '/verification-codes',
			method: 'post',
			options,
			data: { email }
		}
	};
}

export function createSubscription(userId, data, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(SESSION.CREATE_SUBSCRIPTION),
			endpoint: `/users/${userId}/subscriptions`,
			method: 'post',
			data,
			options: {
				...options,
				...data,
				userId
			}
		}
	};
}

export function deleteSubscription(userId, subscriptionId, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(SESSION.DELETE_SUBSCRIPTION),
			endpoint: `/users/${userId}/subscriptions/${subscriptionId}`,
			method: 'delete',
			data: {},
			options: {
				...options,
				userId,
				subscriptionId
			}
		}
	};
}
