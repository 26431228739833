import Rollbar from 'rollbar';

let rollbarInst;

export function getRollbar(rollbarConfig) {
	if (!rollbarInst) {
		rollbarInst = new Rollbar({
			captureUncaught: true,
			captureUnhandledRejections: true,
			wrapGlobalEventHandlers: false,
			autoInstrument: {
				network: true,
				log: false,
				dom: true,
				navigation: true,
				connectivity: true
			},
			...rollbarConfig,
		});
	}
	return rollbarInst;
}

export function hasRollbar(fn) {
	if (rollbarInst) {
		return fn(rollbarInst);
	}
}
