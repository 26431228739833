import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TweetEmbed from 'react-tweet-embed';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import { isClientSide } from '../../../lib/helpers';
import { selectApp } from '../../../store/selectors/app';
import { ClientSideOnly } from '../../lib/clientSideOnly';
import RenderedMarkdown from '../../renderedMarkdown';
import ReactPlayer from 'react-player';
import urlParse from 'url-parse';

import './postContent.scss';

function isEmbeddedVideo(url) {
	if (!url || !url.length) {
		return false;
	}

	if (!!url.match(/youtube\.com\/watch/)) {
		return true;
	}

	return false;
}

function getTweetIdFromUrl(url) {
	const parsed = urlParse(url);

	return _.last(parsed.pathname.split('/'));
}

export const enhance = compose(
	connect(
		createSelector(
			selectApp,
			(app) => ({
				...app
			})
		)
	)
);

function WrappedTweet(props) {
	return (
		<TwitterTweetEmbed tweetId={props.tweetId} options={{ theme: props.theme }} />
	)
}

export function PostContent(props) {
	const {
		post,
		sessionData,
		onEditPost,
		theme,
		showModActions,
		onRemovePost
	} = props;

	const isOwnPost = _.get(post, 'user.id') === sessionData.id;

	const fullLink = _.get(post, 'fullLink') || '';
	const isTweet = !!fullLink.match(/https?:\/\/twitter.com\/(.+)/);

	let tweetId = null;
	if (isTweet) {
		tweetId = getTweetIdFromUrl(fullLink);
	}

	return (
		<div className={classnames('post-content', props.className)}>
			{post.isImage === true && (
				<a className="image-wrapper" href={post.fullLink} target="_blank">
					<img className="post-image" src={post.fullLink} />
				</a>
			)}
			{!!(post.isVideo || isEmbeddedVideo(post.fullLink)) && (
				<div className="video-wrapper">
					<ReactPlayer
						url={post.fullLink}
						config={{
							file: {
								forceVideo: true,
								attributes: {
									autoPlay: true,
									controls: true
								}
							}
						}}
						height="100%"
						width="100%"
					/>
				</div>
			)}
			{isTweet && (
				<ClientSideOnly>
					<div className="tweet-wrapper">
						{/*
							TODO: Currently has issues with re-rendering after a change in theme...
						*/}
						{theme === 'dark' ? (
							<WrappedTweet tweetId={tweetId} theme={theme} />
						) : (
							<WrappedTweet tweetId={tweetId} theme={theme} />
						)}
					</div>
				</ClientSideOnly>
			)}
			{!!(post.compiledBody && post.compiledBody.length) && (
				<div className="content">
					<RenderedMarkdown content={post.compiledBody} />
				</div>
			)}
			<div className="post-content-actions">
				<div className="post-content-action">
					{post.commentCount} comment{post.commentCount === 0 || post.commentCount > 1 ? 's' : ''}
				</div>
				{isOwnPost && (
					<div className="post-content-action edit" onClick={onEditPost}>
						edit
					</div>
				)}
				{!!(showModActions && !post.isRemoved) && (
					<div className="post-content-action moderate" onClick={() => onRemovePost(post.id)}>
						remove
					</div>
				)}
				{/*<div className="post-content-action">
					share
				</div>
				<div className="post-content-action">
					save
				</div>
				<div className="post-content-action">
					report
				</div>*/}
			</div>
		</div>
	);
}

PostContent.propTypes = {
	post: propTypes.shape({
		title: propTypes.string.isRequired,
		createdAt: propTypes.string.isRequired,
		postType: propTypes.oneOf(['text', 'link']),
		slug: propTypes.string,
		shortId: propTypes.string,
		fullLink: propTypes.string,
		link: propTypes.shape({
			hostname: propTypes.string,
			path: propTypes.string,
			isImage: propTypes.bool,
			isVideo: propTypes.bool,
		}),
		rawBody: propTypes.string,
		compiledBody: propTypes.string,
		community: propTypes.shape({
			slug: propTypes.string,
		}),
		user: propTypes.shape({
			username: propTypes.string.isRequired
		})
	}),
	sessionData: propTypes.shape({
		username: propTypes.string,
	}),
	onEditPost: propTypes.func.isRequired,
	showModActions: propTypes.bool,
	onRemovePost: propTypes.func
};

export default enhance(PostContent);
