import _ from 'lodash';
import { uploadFile } from '../files/actions';

export const selectFileUpload = (state, ownProps) => {
	return {
		uploadingFile: _.get(state.files, `uploadingFile.uploads[${ownProps.id}]`) || {}
	};
};

export const mapDispatchFileUploadToProps = {
	uploadFile,
};
