export const selectApp = (state) => ({
	hostname: _.get(state, 'app.hostname') || null,
	environment: _.get(state, 'app.environment') || 'staging',
	isMobile: _.get(state, 'app.isMobile'),
	isTablet: _.get(state, 'app.isTablet'),
	assetUrlBase: _.get(state, 'app.assetUrlBase') || null,
	stripe: _.get(state, 'app.stripe') || {},
	theme: _.get(state, 'app.theme') || 'light'
});

export const selectAssetUrl = (state) => ({
	assetUrlBase: _.get(state, 'app.assetUrlBase') || null
});
