import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import Text from '../lib/text';
import PostDetails from '../posts/post';
import Link from '../lib/link';
import { buildPostUrl } from '../../lib/helpers';

import './postListItem.scss';

const enhance = compose()

export function PostListItem(props) {
	const {
		index,
		post,
		onVote,
		sessionData,
	} = props;

	const commentCount = post.commentCount || 0;

	return (
		<div className={classnames('post-list-item', props.className)}>
			<div className="index">
				<Text
					size={FONT_SIZES.regular}
					weight={FONT_WEIGHTS.medium}
				>
					{index}
				</Text>
			</div>
			<PostDetails
				post={post}
				sessionData={sessionData}
				postContent={(postDetailsProps) => (
					<div className="post-actions">
						<Link className="post-action" to={buildPostUrl(post.community.displaySlug, post.slug, post.shortId)}>
							{commentCount} comment{commentCount !== 1 ? 's' : ''}
						</Link>
						{/*<div className="post-action">
							save
						</div>
						<div className="post-action">
							hide
						</div>
						<div className="post-action">
							report
						</div>*/}
					</div>
				)}
				onVote={onVote}
			/>
		</div>
	);
}

PostListItem.propTypes = {
	post: propTypes.shape({
		title: propTypes.string.isRequired,
		createdAt: propTypes.string.isRequired,
		postType: propTypes.oneOf(['text', 'link']),
		slug: propTypes.string,
		shortId: propTypes.string,
		fullLink: propTypes.string,
		link: propTypes.shape({
			hostname: propTypes.string,
			path: propTypes.string,
		}),
		community: propTypes.shape({
			slug: propTypes.string,
		}),
		user: propTypes.shape({
			username: propTypes.string.isRequired
		}),
		commentCount: propTypes.number,
	}),
	sessionData: propTypes.object,
	index: propTypes.number.isRequired,
	onVote: propTypes.func.isRequired,
};

export default enhance(PostListItem);
