import {
	createSubscription,
	createUserSession,
	deleteSubscription,
	getActiveSession,
	requestVerificationLink
} from '../session/actions';

export const selectCreateSession = (state) => ({
	createSession: _.get(state, 'session.createSession') || {},
	requestingVerificationLink: _.get(state, 'session.requestingVerificationLink') || {}
});

export const mapDispatchCreateSessionToProps = {
	createUserSession,
	requestVerificationLink
};

export const selectSession = (state) => ({
	sessionData: _.get(state, 'session.sessionData') || {},
	requestingVerificationLink: _.get(state, 'session.requestingVerificationLink') || {}
});

export const dispatchSession = {
	getActiveSession,
};

export const mapDispatchVerificationLinkToProps = {
	requestVerificationLink
};

export const selectSessionData = (state) => ({
	sessionData: _.get(state, 'session.sessionData') || {},
});

export const selectCommunitySubscription = (state) => ({
	creatingSubscription: _.get(state.session, 'creatingSubscription') || {},
	deletingSubscription: _.get(state.session, 'deletingSubscription') || {},
});

export const mapDispatchCommunitySubscriptionToProps = {
	createSubscription,
	deleteSubscription,
};
