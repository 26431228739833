import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
// import 'rsuite/lib/styles/index.less';
import 'animate.css/animate.min.css';

import './app.scss';

export default function App(props) {
	return (
		<ToastProvider
			autoDismissTimeout={3000}
		>
			{props.children}
		</ToastProvider>
	);
}
