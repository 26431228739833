import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { renderInput } from '../../lib/formHelpers';
import { Button } from '../lib/button';
import Form from '../lib/form';
import { FormItem, FormRow } from '../lib/formRow';
import Headline from '../lib/headline';

import './passwordResetForm.scss';

const FORM_NAME = 'password-reset-form';

export const enhance = compose(
	reduxForm({ form: FORM_NAME }),
	connect(
		createSelector(
			(state) => ({ formValues: getFormValues(FORM_NAME)(state) }),
			(formValues) => ({
				...formValues
			})
		)
	),
);

const hasConfirmPassword = (value, allValues, props) => {
	if (!value || !value.length || value !== allValues.password) {
		return 'Password does not match';
	}
}

const hasNewPassword = (value, allValues, props) => {
	console.log(allValues);
	if (!value || !value.length || value !== allValues.confirmPassword) {
		return 'Password does not match';
	}
}

export function PasswordResetForm(props) {
	const {
		onSubmit,
		handleSubmit,
		valid,
		anyTouched,
		loading,
		formError
	} = props;
	const buttonProps = {
		size: 'regular',
		display: 'primary',
		loading
	};
	if (!valid || !anyTouched) {
		buttonProps.disabled = true;
	}
	return (
		<div className={classnames('password-reset-form')}>
			<Form onSubmit={handleSubmit(onSubmit)} className={classnames(`${props.color}-text`)}>
				<Headline tag="h1" as="h5" centered>
					Enter a new password
				</Headline>
				<FormRow>
					<Field
						id="password"
						name="password"
						type="password"
						label="New password"
						withLabel={true}
						component={renderInput}
						validate={[hasNewPassword]}
						helpText="Password must be 10 or more characters"
						isRequired
					/>
				</FormRow>
				<FormRow>
					<Field
						id="confirmPassword"
						name="confirmPassword"
						type="password"
						label="Confirm new password"
						withLabel={true}
						component={renderInput}
						validate={[hasConfirmPassword]}
						isRequired
					/>
				</FormRow>
				<FormRow>
					<FormItem>
						<div className="button-wrapper">
							<Button {...buttonProps}>
								Reset Password
							</Button>
						</div>
					</FormItem>
				</FormRow>
				{!!(formError && formError.message) && (
					<FormRow>
						<FormItem>
							<div className="form-formError-message">
								{formError.message === 'PasswordReset not found' ? 'Password reset token has been used or is invalid' : formError.message}
							</div>
						</FormItem>
					</FormRow>
				)}
			</Form>
		</div>
	);
}

PasswordResetForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
	loading: propTypes.bool,
	formError: propTypes.any,
};

export default enhance(PasswordResetForm);
