import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import SafeHTMLContent from '../lib/safeHtmlContent';

import './renderedMarkdown.scss';

export function RenderedMarkdown(props) {
	return (
		<div className={classnames('rendered-markdown', props.className)}>
			<SafeHTMLContent content={props.content} />
		</div>
	);
}

RenderedMarkdown.propTypes = {
	content: propTypes.any.isRequired
};

export default RenderedMarkdown;
