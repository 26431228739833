import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import * as HeroiconsReactOutline from '@heroicons/react/24/outline';
import * as HeroiconsReactSolid from '@heroicons/react/24/outline';
import './heroicon.scss';

export function Heroicon(props) {
	let icon = props.icon;
	if (!props.icon || !props.icon.length) {
		return null;
	}
	if (!/Icon$/.test(icon)) {
		icon = `${icon}Icon`;
	}
	if (!HeroiconsReactOutline[icon]) {
		console.log(`Icon "${icon}" not found`);
		return null;
	}
	const Icon = HeroiconsReactOutline[icon];

	return (<Icon {...props} className={classnames('heroicon', props.className, _.kebabCase(props.icon))} />);
}

Heroicon.propTypes = {
	className: propTypes.string,
	icon: propTypes.string.isRequired
};

export default Heroicon;
