import React from 'react';
import { handleActions } from 'redux-actions';
import { APP } from './actions';

export const initialState = {
	hostname: null,
	environment: null,
	googleAnalytics: {
		trackingId: null,
		debug: false
	},
	assetUrlBase: '/',
	theme: null
};

export default handleActions({
	[APP.SET]: (state, action) => action.payload.app,
	[APP.SET_THEME]: (state, action) => {
		return {
			...state,
			theme: action.payload.theme
		};
	}
}, initialState);
