import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { normalizeCommunitySlug } from '../../lib/helpers';
import { COMMUNITY } from '../community/actions';
import { getFailureAction, getRequestAction, getSuccessAction } from '../helpers';
import { MODERATION } from '../moderation/actions';
import { POSTS } from './actions';

export const initialState = {
	gettingAllPosts: {
		loading: false,
		error: null
	},
	posts: {
		cursor: null,
		postIds: []
	},
	postsById: {},
	// shortId: id
	postIdsByShortId: {},
	creatingPostComment: {
		loading: false,
		error: null
	},
	gettingPostComments: {
		loading: false,
		error: null
	},
	postCommentsByPostShortId: {},
	upsertingPostVote: {
		loading: false,
		error: null
	},
	upsertingCommentVote: {
		loading: false,
		error: null
	},
	// [commentId]: <int>
	userCommentVotesByCommentId: {},
	updatingPostContent: {
		loading: false,
		error: null
	},
	gettingUserComment: {
		loading: false,
		error: null
	},
	updatingPostComment: {
		loading: false,
		error: null
	}
};

export default handleActions({
	// Get all posts
	[getRequestAction(POSTS.GET_ALL_POSTS)]: (state, { payload, options }) => {
		return {
			...state,
			gettingAllPosts: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(POSTS.GET_ALL_POSTS)]: (state, { payload, options }) => {
		const normalizedSlug = normalizeCommunitySlug(options.displaySlug);
		return {
			...state,
			gettingAllPosts: {
				loading: false,
				error: null
			},
			posts: {
				cursor: payload.cursor,
				postIds: [
					...(_.get(state, `communityPosts[${normalizedSlug}].postIds`)) || [],
					..._.map(payload.posts, (p) => p.id)
				],
				sort: {
					...(_.get(state, `communityPosts[${normalizedSlug}].sort`)) || {},
					[options.sort]: [
						..._.map(payload.posts, (p) => p.id)
					]
				}
			},
			postsById: {
				...(_.get(state, `postsById`) || {}),
				..._.reduce(payload.posts, (mapped, post) => {
					mapped[post.id] = post;
					return mapped;
				}, {})
			},
			postIdsByShortId: {
				...(_.get(state, `postIdsByShortId`) || {}),
				..._.reduce(payload.posts, (mapped, post) => {
					mapped[post.shortId] = post.id;
					return mapped;
				}, {})
			},
		};
	},
	[getFailureAction(POSTS.GET_ALL_POSTS)]: (state, { error, options }) => {
		return {
			...state,
			gettingAllPosts: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Intercept and store posts from community posts by slug
	[getSuccessAction(COMMUNITY.GET_COMMUNITY_POSTS_BY_SLUG)]: (state, { payload, options }) => {
		const normalizedSlug = normalizeCommunitySlug(options.displaySlug);
		return {
			...state,
			postsById: {
				...(_.get(state, `postsById`) || {}),
				..._.reduce(payload.posts, (mapped, post) => {
					mapped[post.id] = post;
					return mapped;
				}, {})
			},
			postIdsByShortId: {
				...(_.get(state, `postIdsByShortId`) || {}),
				..._.reduce(payload.posts, (mapped, post) => {
					mapped[post.shortId] = post.id;
					return mapped;
				}, {})
			},
		};
	},
	// Intercept and store post from getting community post
	[getSuccessAction(COMMUNITY.GET_COMMUNITY_POST)]: (state, { payload, options }) => {
		return {
			...state,
			postsById: {
				...(_.get(state, `postsById`) || {}),
				[payload.post.id]: payload.post
			},
			postIdsByShortId: {
				...(_.get(state, `postIdsByShortId`) || {}),
				[payload.post.shortId]: payload.post.id
			},
		};
	},
	// Create post comment
	[getRequestAction(POSTS.CREATE_POST_COMMENT)]: (state, { payload, options }) => {
		return {
			...state,
			creatingPostComment: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(POSTS.CREATE_POST_COMMENT)]: (state, { payload, options }) => {
		const { communityId, postId, data } = options;
		const { comment, ancestor } = payload;

		const post = _.get(state, `postsById[${postId}]`);
		const postComments = _.get(state, `postCommentsByPostShortId[${post.shortId}]`);

		const commentTree = postComments.commentTree;

		_.set(postComments, `commentsById[${comment.id}]`, payload);

		const createCommentTreeComment = (c) => {
			return {
				id: c.id,
				numericId: c.numericId,
				children: []
			}
		}

		const insert = (currentComment, parentId, commentToInsert) => {
			if (currentComment.id === parentId) {
				currentComment.children = currentComment.children || [];
				currentComment.children.push({
					...createCommentTreeComment(commentToInsert),
					depth: currentComment.depth + 1,
				});
				return;
			}

			if (!currentComment.children) {
				return;
			}
			_.each(currentComment.children, (child) => {
				return insert(child, parentId, commentToInsert);
			});
		};

		if (comment.parentCommentId === null) {
			commentTree.push({
				...createCommentTreeComment(comment),
				depth: 0
			});
		} else {
			// Find the ancestor to start at in the tree to make insertion faster
			const commentAncestor = _.find(commentTree, (c) => c.id === ancestor.ancestorId);

			insert(commentAncestor, comment.parentCommentId, comment);
		}


		return {
			...state,
			creatingPostComment: {
				loading: false,
				error: null
			},
			postCommentsByPostShortId: {
				...(state.postCommentsByPostShortId || {}),
				[post.shortId]: {
					...(_.get(state, `postCommentsByPostShortId[${post.shortId}]`) || {}),
					users: {
						...(_.get(state, `postCommentsByPostShortId[${post.shortId}].users`) || {}),
						[comment.user.id]: comment.user,
					},
					commentsById: {
						...(_.get(state, `postCommentsByPostShortId[${post.shortId}].commentsById`) || {}),
						[comment.id]: comment
					},
					commentTree
				}
			}
		};
	},
	[getFailureAction(POSTS.CREATE_POST_COMMENT)]: (state, { error, options }) => {

		return {
			...state,
			creatingPostComment: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Get post comment tree
	[getRequestAction(POSTS.GET_POST_COMMENTS)]: (state, { payload, options }) => {
		return {
			...state,
			gettingPostComments: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(POSTS.GET_POST_COMMENTS)]: (state, { payload, options }) => {
		const { commentTree, mappedComments, users, userCommentVotes } = payload;
		const { postShortId } = options;
		return {
			...state,
			gettingPostComments: {
				loading: false,
				error: null
			},
			postCommentsByPostShortId: {
				...(state.postCommentsByPostShortId || {}),
				[postShortId]: {
					users: {
						...(_.get(state, `postCommentsByPostShortId[${postShortId}].users`) || {}),
						...users
					},
					commentsById: {
						...(_.get(state, `postCommentsByPostShortId[${postShortId}].commentsById`) || {}),
						...mappedComments
					},
					commentTree
				}
			},
			userCommentVotesByCommentId: {
				...state.userCommentVotesByCommentId,
				...userCommentVotes
			}
		};
	},
	[getFailureAction(POSTS.GET_POST_COMMENTS)]: (state, { error, options }) => {
		return {
			...state,
			gettingPostComments: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Upsert post vote
	[getRequestAction(POSTS.UPSERT_POST_VOTE)]: (state, { payload, options }) => {
		return {
			...state,
			upsertingPostVote: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(POSTS.UPSERT_POST_VOTE)]: (state, { payload, options }) => {
		const { postId } = options;
		const { postVote, post } = payload;

		const currentPost = _.get(state, `postsById[${postId}]`);
		if (!currentPost.postVotes) {
			currentPost.postVotes = {};
		}
		_.set(currentPost, `postVotes[${postVote.userId}]`, postVote.vote);
		_.set(currentPost, 'upvotes', post.upvotes);
		_.set(currentPost, 'downvotes', post.downvotes);

		return {
			...state,
			upsertingPostVote: {
				loading: false,
				error: null
			},
			postsById: {
				...(_.get(state, `postsById`) || {}),
				[postId]: currentPost,
			},
		};
	},
	[getFailureAction(POSTS.UPSERT_POST_VOTE)]: (state, { error, options }) => {
		return {
			...state,
			upsertingPostVote: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Upsert comment vote
	[getRequestAction(POSTS.UPSERT_COMMENT_VOTE)]: (state, { payload, options }) => {
		return {
			...state,
			upsertingCommentVote: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(POSTS.UPSERT_COMMENT_VOTE)]: (state, { payload, options }) => {
		const { postId, commentId } = options;
		const { commentVote, comment } = payload;

		const currentPost = _.get(state, `postsById[${postId}]`);
		const currentComment = _.get(state, `postCommentsByPostShortId[${currentPost.shortId}].commentsById[${commentId}]`);

		_.set(currentComment, 'upvotes', comment.upvotes);
		_.set(currentComment, 'downvotes', comment.downvotes);

		return {
			...state,
			upsertingCommentVote: {
				loading: false,
				error: null
			},
			postCommentsByPostShortId: {
				...state.postCommentsByPostShortId,
				[currentPost.shortId]: {
					...(_.get(state, `postCommentsByPostShortId[${currentPost.shortId}]`) || {}),
					commentsById: {
						...(_.get(state, `postCommentsByPostShortId[${currentPost.shortId}].commentsById`) || {}),
						[commentId]: currentComment
					}
				}
			},
			userCommentVotesByCommentId: {
				...state.userCommentVotesByCommentId,
				[commentId]: commentVote.vote
			}
		};
	},
	[getFailureAction(POSTS.UPSERT_COMMENT_VOTE)]: (state, { error, options }) => {
		return {
			...state,
			upsertingCommentVote: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Update post content
	[getRequestAction(POSTS.UPDATE_POST_CONTENT)]: (state, { payload, options }) => {
		return {
			...state,
			updatingPostContent: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(POSTS.UPDATE_POST_CONTENT)]: (state, { payload, options }) => {
		const { postId } = options;

		return {
			...state,
			updatingPostContent: {
				loading: false,
				error: null
			},
			postsById: {
				...(state.postsById || {}),
				[postId]: {
					...(_.get(state, `postsById[${postId}]`) || {}),
					...payload
				}
			}
		};
	},
	[getFailureAction(POSTS.UPDATE_POST_CONTENT)]: (state, { error, options }) => {
		return {
			...state,
			updatingPostContent: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Get user comment
	[getRequestAction(POSTS.GET_USER_COMMENT)]: (state, { payload, options }) => {
		return {
			...state,
			gettingUserComment: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(POSTS.GET_USER_COMMENT)]: (state, { payload, options }) => {
		const { postId } = options;

		return {
			...state,
			gettingUserComment: {
				loading: false,
				error: null
			},

		};
	},
	[getFailureAction(POSTS.GET_USER_COMMENT)]: (state, { error, options }) => {
		return {
			...state,
			gettingUserComment: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Get user comment
	[getRequestAction(POSTS.UPDATE_POST_COMMENT)]: (state, { payload, options }) => {
		return {
			...state,
			updatingPostComment: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(POSTS.UPDATE_POST_COMMENT)]: (state, { payload, options }) => {
		const { commentId, postShortId } = options;

		return {
			...state,
			updatingPostComment: {
				loading: false,
				error: null
			},
			postCommentsByPostShortId: {
				...state.postCommentsByPostShortId,
				[postShortId]: {
					...(_.get(state, `postCommentsByPostShortId[${postShortId}]`) || {}),
					commentsById: {
						...(_.get(state, `postCommentsByPostShortId[${postShortId}].commentsById`) || {}),
						[commentId]: {
							...(_.get(state, `postCommentsByPostShortId[${postShortId}].commentsById[${commentId}]`) || {}),
							...payload
						}
					}
				}
			},
		};
	},
	[getFailureAction(POSTS.UPDATE_POST_COMMENT)]: (state, { error, options }) => {
		return {
			...state,
			updatingPostComment: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Moderate: remove comment
	[getSuccessAction(MODERATION.REMOVE_COMMENT)]: (state, { payload, options }) => {
		const { postId, commentId, communityId } = options;
		const post = _.get(state, `postsById[${postId}]`);
		if (!post) {
			throw new Error('Post not found by id');
		}
		const postShortId = post.shortId;
		return {
			...state,
			postCommentsByPostShortId: {
				...state.postCommentsByPostShortId,
				[postShortId]: {
					...(_.get(state, `postCommentsByPostShortId[${postShortId}]`) || {}),
					commentsById: {
						...(_.get(state, `postCommentsByPostShortId[${postShortId}].commentsById`) || {}),
						[commentId]: {
							...(_.get(state, `postCommentsByPostShortId[${postShortId}].commentsById[${commentId}]`) || {}),
							...payload
						}
					}
				}
			},
		};
	},
	// Moderate: remove post
	[getSuccessAction(MODERATION.REMOVE_POST)]: (state, { payload, options }) => {
		const { postId, communityId } = options;

		return {
			...state,
			postsById: {
				...state.postsById,
				[postId]: {
					...(_.get(state, `postsById[${postId}]`) || {}),
					...payload
				}
			},
		};
	},
}, initialState);
