import * as React from 'react';
import * as _ from 'lodash';
import { createStore, applyMiddleware, compose, Store } from 'redux';

import reducers from './reducers';
import middlewares from './middleware';

const composeEnhancers =
	typeof window === 'object' && _.get(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__') ?
		_.get(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__')({

		}) : compose;

export default function initStore(initialState = {}) {
	return createStore(
		reducers,
		initialState,
		composeEnhancers(
			applyMiddleware(...[...middlewares])
		)
	);
}
