import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';

import './inputDecoration.scss';

export function InputDecoration(props) {
	const { children, decoration } = props;
	if (!decoration) {
		return children;
	}

	return (
		<div className={classnames('core-input-decoration', props.className)}>
			<div className="decoration">
				{decoration}
			</div>
			{props.children}
		</div>
	);
}

InputDecoration.propTypes = {
	children: propTypes.any.isRequired,
	decoration: propTypes.any
};

export default InputDecoration;
