import classnames from 'classnames';
import React from 'react';
import { FONT_SIZES } from '../../../lib/constants';
import FormFieldLabel from '../formFieldLabel';

import './checkbox.scss';
import Text from '../text';

export function Checkbox(props) {
	const { helpText } = props;
	return (
		<>
			<label className={classnames('checkbox-wrapper', props.className)} htmlFor={props.id || props.name}>
				<input
					{..._.omit(props, ['error', 'errorMessage', 'withLabel', 'helpText', 'label'])}
					className={classnames(props.className, 'checkbox', props.size, { error: !!props.error })}
					type="checkbox"
					id={props.id || props.name}
					checked={props.value}
				/>
				<FormFieldLabel name={props.name} asSpan>
					{props.label}
				</FormFieldLabel>
			</label>
			{!!helpText && (
				<Text
					className="help-text"
					transform="italic"
					size={FONT_SIZES.sm}
				>
					{helpText}
				</Text>
			)}
		</>
	);
}

export default Checkbox;
