import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import ProgressBar from '../../progressBar';
import ErrorMessage from '../errorMessage';
import FormFieldLabel from '../formFieldLabel';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import prettyBytes from 'pretty-bytes';
import _ from 'lodash';
import { connect } from 'react-redux';
import { selectFileUpload } from '../../../store/selectors/files';

import './fileInput.scss';
import Text from '../text';

export const AUDIO_FILE_TYPES = [
	'audio/mpeg',
	'audio/wav',
	'audio/flac',
	'audio/ogg'
];

export const COVER_PHOTO_FILE_TYPES = [
	'image/jpeg',
	'image/png'
];

const enhance = compose(
	connect(selectFileUpload),
	withState('inputRef', 'setInputRef', null),
	withState('fileError', 'setFileError', null),
	withHandlers({
		handleClickEvent: (props) => (evt) => {
			const { inputRef } = props;
			if (inputRef.current.contains(evt.target) && !props.disabled) {
				inputRef.current.querySelector('input[type="file"]').click();
			}
		},
		handleFileInputChange: (props) => (evt) => {
			const { setFileError, uploadOnChange, handleUploadFile,  maxSizeBytes, fileType, imageOptions }  = props;
			const { minHeight, maxHeight, aspectRatio, minWidth, maxWidth } = (imageOptions || {});
			if (!evt.target.files || !evt.target.files.length) {
				return;
			}
			const file = evt.target.files[0];

			props.setFileError(null);
			return Promise.resolve()
			.then(() => {
				return new Promise((resolve, reject) => {
					if (maxSizeBytes && file && file.size > maxSizeBytes) {
						return reject(`File is too large (must be smaller than ${prettyBytes(maxSizeBytes)})`);
					}

					if (fileType !== 'image') {
						return resolve();
					}
					const image = new Image();

					image.addEventListener('load', function() {
						const { naturalWidth, naturalHeight } = this;
						if (minHeight && naturalHeight < minHeight) {
							return reject(`Image must be a minimum height of ${minHeight}px`);
						}
						if (minWidth && naturalWidth < minWidth) {
							return reject(`Image must be a minimum width of ${minWidth}px`);
						}
						if (maxHeight && naturalHeight > maxHeight) {
							return reject(`Image height cannot exceed ${maxHeight}px`);
						}
						if (maxWidth && naturalWidth > maxWidth) {
							return reject(`Image width cannot exceed ${width}px`);
						}
						if (aspectRatio && (naturalWidth / naturalHeight) !== aspectRatio) {
							return reject(`Image aspect ratio must be ${aspectRatio}`);
						}
						return resolve();
					});
					image.src = URL.createObjectURL(file);
				});
			})
			.then(() => {
				if (uploadOnChange && handleUploadFile) {
					const formData = new FormData();
					formData.append('file', file);

					handleUploadFile(props.id, formData, file);
				}
				if (props.onChange) {
					props.onChange(file);
				}
			})
			.catch((err) => {
				props.setFileError(err);
			})
		}
	}),
	lifecycle({
		componentDidMount() {
			const { setInputRef } = this.props;

			setInputRef(React.createRef());
		},
		componentWillUnmount() {
			document.removeEventListener('click', this.props.handleClickEvent);
		},
		componentDidUpdate(prevProps) {
			const { inputRef, handleClickEvent, uploadingFile } = this.props;

			if (prevProps.uploadingFile.loading && !uploadingFile.loading && !uploadingFile.error && this.props.onUpload) {
				this.props.onUpload(this.props.id, uploadingFile.uploadedFile);
			}

			if ((!prevProps.inputRef || !prevProps.inputRef.current) && inputRef.current) {
				// inputRef.current.addEventListener('click', handleClickEvent);
			}

		}
	})
);

export function FileInput(props) {
	const {
		inputRef,
		fileError,
		handleFileInputChange,
		acceptedTypes,
		maxSizeBytes,
		uploading,
		uploadingFile
	} = props;
	return (
		<>
			<label className={classnames('file-input-wrapper')} htmlFor={props.id} ref={inputRef}>
				<FormFieldLabel name={props.name} className={classnames({ 'is-required': props.isRequired === true })}>
					{props.label} {props.isRequired === true}
				</FormFieldLabel>
				<input
					type="file"
					className="file-input"
					multiple={false}
					name={props.id}
					id={props.id}
					onChange={handleFileInputChange}
					{...(_.isArray(acceptedTypes) && acceptedTypes.length ? {
						accept: acceptedTypes.join(', ')
					} : {})}
				/>
				{!!(props.helpText) && (
					<Text className="help-text" size="sm" color="gray">{props.helpText}</Text>
				)}
				{!!fileError && (
					<ErrorMessage size="small">
						{fileError}
					</ErrorMessage>
				)}
				{!!(uploading || uploadingFile.loading || uploadingFile.progressPercent) && (
					<ProgressBar percent={uploadingFile.progressPercent} />
				)}
			</label>

		</>
	);
}

FileInput.propTypes = {
	id: propTypes.string.isRequired,
	onUpload: propTypes.func,
	size: propTypes.string,
	type: propTypes.string,
	error: propTypes.bool,
	errorMessage: propTypes.string,
	label: propTypes.string,
	helpText: propTypes.string,
	maxSizeBytes: propTypes.number,
	handleUploadFile: propTypes.func,
	uploadOnChange: propTypes.bool,
	acceptedTypes: propTypes.arrayOf(propTypes.string),
	uploading: propTypes.bool,
	imageOptions: propTypes.shape({
		minWidth: propTypes.number,
		minHeight: propTypes.number,
		aspectRatio: propTypes.number,
		maxWidth: propTypes.number,
		maxHeight: propTypes.number
	}),
	fileType: propTypes.string
};

FileInput.defaultProps = {
	size: 'regular',
	type: 'text',
	error: false,
	acceptedTypes: []
};

export default enhance(FileInput);
