import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, mapProps, withHandlers } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { renderInput } from '../../../lib/formHelpers';
import { Button } from '../../lib/button';
import Form from '../../lib/form';
import { ButtonRow, FormItem, FormRow } from '../../lib/formRow';

import './addCommentForm.scss';

const FORM_NAME = 'comment-form';

export const enhance = compose(
	mapProps((props) => ({
		...props,
		formName: props.formName || FORM_NAME,
		form: props.formName || FORM_NAME
	})),
	reduxForm({ enableReinitialize: true }),
	connect(
		createSelector(
			(state, ownProps) => ({
				formValues: getFormValues(ownProps.formName)(state)
			}),
			(formValues) => ({
				...formValues,
			})
		)
	),
	withHandlers({
		interceptSubmit: (props) => (fields) => {
			if (props.formAction === 'create') {
				fields.parentCommentId = props.parentCommentId || null;
			}
			props.onSubmit(fields);
		}
	})
);

const hasValidComment = (content) => {
	if (!content || !_.trim(content.length)) {
		return 'Please provide content for your comment';
	}
}

export function CommentForm(props) {
	const {
		interceptSubmit,
		handleSubmit,
		valid,
		loading,
		formError,
	} = props;
	const buttonProps = {
		loading: loading,
		size: 'regular'
	};
	if (!valid) {
		buttonProps.disabled = true;
	}
	return (
		<Form
			onSubmit={handleSubmit(interceptSubmit)}
			className={classnames('comment-form', props.className)}
		>
			<FormRow>
				<FormItem>
					<Field
						id="rawBody"
						name="rawBody"
						type="text"
						label="Add a comment"
						isRequired
						inputType="textarea"
						component={renderInput}
						validate={[hasValidComment]}
					/>
				</FormItem>
			</FormRow>
			<ButtonRow>
				<Button {...buttonProps} size="small">
					Save
				</Button>
			</ButtonRow>
			{!!(formError && formError.message) && (
				<FormRow>
					<FormItem>
						<div className="form-formError-message">
							{formError.message}
						</div>
					</FormItem>
				</FormRow>
			)}
		</Form>
	);
}

CommentForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
	loading: propTypes.bool.isRequired,
	formError: propTypes.any,
	parentCommentId: propTypes.string,
	formAction: propTypes.oneOf(['create', 'edit']).isRequired
};

export default enhance(CommentForm);
