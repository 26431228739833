import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import './voteButton.scss';
import { buildModifierClassFromProps } from '../../../lib/helpers';
import Heroicon from '../heroicon';

const UPVOTE = 1;
const DOWNVOTE = -1;

const modifierProps = ['size'];

export function VoteButton(props) {
	const {
		vote,
		onClick
	} = props;

	const isUpvote = props.direction === 'up';
	const isDownvote = props.direction === 'down';

	const voted = !!(isUpvote && props.vote === UPVOTE) || !!(isDownvote && props.vote === DOWNVOTE);
	return (
		<div
			className={classnames('vote-button', props.className, {
				voted,
				'up-vote': isUpvote,
				'down-vote': isDownvote,
			}, ...buildModifierClassFromProps(modifierProps, props))}
			onClick={() => {
				if (isUpvote) {
					return onClick(vote === 1 ? 0 : 1);
				} else {
					return onClick(vote === -1 ? 0 : -1);
				}
			}}
		>
			<Heroicon icon={isUpvote ? 'ChevronUp' : 'ChevronDown'} />
		</div>
	)
}

VoteButton.propTypes = {
	direction: propTypes.oneOf(['up', 'down']).isRequired,
	onClick: propTypes.func.isRequired,
	vote: propTypes.oneOf([-1, 0, 1]),
	size: propTypes.oneOf(['regular', 'small'])
};

VoteButton.defaultProps = {
	size: 'regular'
}
