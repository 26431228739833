/**
 *
 * @param namespace - e.g. PROPERTY
 * @param action - e.g. LIST_PROPERTIES
 */
export function generateCRUDActions(namespace, action) {
	const actions = ['REQUEST', 'SUCCESS', 'FAILURE'];

	const keys = actions.map((a) => `${action}_${a}`);
	const actionStrings = keys.map((k) => `${namespace}/${k}`);

	return actionStrings;
}

export function spreadCRUDActions(actionsList) {
	return [actionsList[ACTION_ENUM.REQUEST], actionsList[ACTION_ENUM.SUCCESS], actionsList[ACTION_ENUM.FAILURE]];
}

export function getRequestAction(actions) {
	return actions[ACTION_ENUM.REQUEST];
}

export function getSuccessAction(actions) {
	return actions[ACTION_ENUM.SUCCESS];
}

export function getFailureAction(actions) {
	return actions[ACTION_ENUM.FAILURE];
}

export const ACTION_ENUM = {
	REQUEST: 0,
	SUCCESS: 1,
	FAILURE: 2
};
