import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import _ from 'lodash';
import './select.scss';
import ReactSelect from 'react-select';

export function Select(props) {
	return (
		<label className={classnames(`select-box`, props.className)}>
			<span className={classnames('label-text', props.size)}>
				{props.placeholder}
			</span>
			<ReactSelect
				value={props.value}
				className={classnames('select', props.className)}
				classNamePrefix="select"
				options={props.options}
				onChange={props.onChange}
				onBlur={() => props.onBlur(props.value)}
			/>
		</label>
	);
}

Select.propTypes = {
	size: propTypes.string,
	options: propTypes.arrayOf(propTypes.shape({
		label: propTypes.string,
		value: propTypes.any
	}))
};

Select.defaultProps = {
	size: 'regular',
	options: []
};
