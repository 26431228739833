import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import { FONT_COLORS, FONT_SIZES, FONT_STYLES, FONT_WEIGHTS } from '../../../lib/constants';
import { buildModifierClassFromProps } from '../../../lib/helpers';

import './paragraph.scss';

const modifierProps = ['size', 'weight', 'color'];

export function Paragraph(props) {
	const { className } = props;

	return (
		<p
			{..._.omit(props, [...modifierProps])}
			className={classnames('paragraph', className, ...buildModifierClassFromProps(modifierProps, props))}
		>
			{props.children}
		</p>
	);
}

Paragraph.propTypes = {
	size: propTypes.oneOf(FONT_STYLES.sizes),
	weight: propTypes.oneOf(FONT_STYLES.weights),
	color: propTypes.oneOf(FONT_STYLES.colors),
};

Paragraph.defaultProps = {
	size: FONT_SIZES.regular,
	weight: FONT_WEIGHTS.normal,
	color: FONT_COLORS.regular,
};

export default Paragraph;
