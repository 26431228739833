import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import Headline from '../lib/headline';
import Heroicon from '../lib/heroicon';
import Text from '../lib/text';

import PostListItem from '../postListItem';

import './postList.scss';

export function PostList(props) {
	const { onVote, sessionData, posts } = props;

	return (
		<div className={classnames('post-list', props.className)}>
			{_.map(props.posts, (p, index) => (
				<PostListItem
					key={index}
					index={index + 1}
					onVote={onVote}
					post={p}
					sessionData={sessionData}
				/>
			))}
		</div>
	);
}

PostList.propTypes = {
	posts: propTypes.arrayOf(propTypes.shape({

	})),
	onVote: propTypes.func.isRequired,
	sessionData: propTypes.object,
};

export default PostList;
