import _ from 'lodash';
import React from 'react';
import { handleActions } from 'redux-actions';
import { getFailureAction, getRequestAction, getSuccessAction } from '../helpers';
import { DATA } from './actions';

export const initialState = {
	crawlingUrl: {
		loading: false,
		error: null
	}
};

export default handleActions({
	// Crawl url
	[getRequestAction(DATA.CRAWL_URL)]: (state, { payload, options }) => {
		return {
			...state,
			crawlingUrl: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(DATA.CRAWL_URL)]: (state, { payload, options }) => {
		return {
			...state,
			crawlingUrl: {
				loading: false,
				error: null
			}
		};
	},
	[getFailureAction(DATA.CRAWL_URL)]: (state, { error, options }) => {
		return {
			...state,
			crawlingUrl: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
}, initialState);
