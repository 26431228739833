import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, mapProps, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import { CommunityNavigation } from '../../components/communityNavigation';
import CommunitySidebar from '../../components/communitySidebar';
import { Button } from '../../components/lib/button';
import Headline from '../../components/lib/headline';
import Heroicon from '../../components/lib/heroicon';
import Link from '../../components/lib/link';
import Spinner from '../../components/lib/spinner';
import Text from '../../components/lib/text';
import LoginSignupModal from '../../components/loginSignupModal';
import Page from '../../components/page';
import PostList from '../../components/postList';
import RestrictedCommunity from '../../components/restrictedCommunity';
import { withAttributedSegmentTracking } from '../../lib/analytics';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { handleLoginSignupVote } from '../../lib/handlerHelpers';
import { buildCommunityUrl } from '../../lib/helpers';
import { selectApp, selectAssetUrl } from '../../store/selectors/app';
import {
	mapDispatchCommunityPostsToProps,
	selectCommunity,
	selectCommunityPosts
} from '../../store/selectors/community';
import { mapDispatchUpsertPostVoteToProps, selectUpsertingPostVote } from '../../store/selectors/posts';
import { selectSession } from '../../store/selectors/session';

import './community.scss';

export const enhance = compose(
	mapProps((props) => ({
		...props,
		displaySlug: _.get(props, 'match.params.displaySlug'),
		sort: _.get(props, 'match.params.sort') || 'hot'
	})),
	connect(
		createSelector(
			selectApp,
			selectAssetUrl,
			selectSession,
			selectCommunityPosts,
			selectCommunity,
			selectUpsertingPostVote,
			(app, asset, session, communityPosts, community, vote) => ({
				...app,
				...asset,
				...session,
				...communityPosts,
				...community,
				...vote,
			})
		),
		{
			...mapDispatchCommunityPostsToProps,
			...mapDispatchUpsertPostVoteToProps,
		}
	),
	withAttributedSegmentTracking,
	withState('loadedCommunity', 'setLoadedCommunity', (props) => {
		return !!props.community;
	}),
	withState('loadedPosts', 'setLoadedPosts', (props) => {
		return !!(props.posts && props.posts.length);
	}),
	withState('showLoginSignupModal', 'setShowLoginSignupModal', false),
	withHandlers({
		handlePostVote: (props) => (post, vote) => {
			const { sessionData, upsertPostVote, setShowLoginSignupModal } = props;

			if (sessionData.hasSession) {
				return upsertPostVote(post.communityId, post.id, vote);
			}
			setShowLoginSignupModal({ action: 'vote', post, vote });
		},
	}),
	withHandlers({
		handleLoginSignup: handleLoginSignupVote,
	}),
	lifecycle({
		componentDidMount() {
			const {
				displaySlug,
				setLoadedCommunity,
				getCommunityPostsBySlug,
				loadedCommunity,
				loadedPosts,
				setLoadedPosts,
				handleVerifyAge,
				sort
			} = this.props;

			if (!loadedCommunity || !loadedPosts) {
				getCommunityPostsBySlug(displaySlug, {
					includeUser: true,
					includeLink: true,
					includeDescription: true,
					onlyCompiledDescription: true,
					includeRules: true,
					includeModerators: true,
					sort
				}, { sort })
				.then((data) => {
					setLoadedCommunity(true);
					setLoadedPosts(true);
					if (data.payload.adultOnly) {
						handleVerifyAge();
					}
					return data;
				});
			}
		}
	})
);

export function CommunityPage(props) {
	const {
		loadedCommunity,
		community,
		gettingCommunityPosts,
		posts,
		loadedPosts,
		handlePostVote,
		sessionData,
		showLoginSignupModal,
		setShowLoginSignupModal,
		handleLoginSignup,
		displaySlug,
		sort
	} = props;

	const adultOnly = _.get(community, 'adultOnly');

	const restricted = !!community && adultOnly && !sessionData.isAdult;

	const createPostUrl = `${buildCommunityUrl(_.get(props, 'community.displaySlug'))}/create-post`;

	return (
		<Page
			{...props}
			className={classnames('community-page', { restricted })}
			withContent
			pageName="community"
		>
			<div className="community-posts">
				<CommunityNavigation basePath={buildCommunityUrl(displaySlug)} />
				{!!gettingCommunityPosts.loading && (
					<Spinner />
				)}
				{!!(loadedPosts && !restricted) && (
					<>
						{_.isArray(posts) && !posts.length && (
							<div className="empty-posts">
								<Headline tag="h3" size={FONT_SIZES.xl} weight={FONT_WEIGHTS.bold}>
									No posts found
								</Headline>
								<Text
									className="be-the-first"
									size={FONT_SIZES.lg}
								>
									Be the first to contribute!
								</Text>
								<Button
									className="new-post"
									isLink
									href={sessionData.hasSession ? createPostUrl : `/login?returnTo=${createPostUrl}`}
								>
									Create a new post
								</Button>
							</div>
						)}
						<PostList
							sessionData={sessionData}
							posts={_.map(posts, (p) => ({
								...p,
								community: { ...community }
							}))}
							onVote={handlePostVote}
						/>
					</>
				)}
				{restricted && (
					<RestrictedCommunity
						sessionData={sessionData}
						community={community}
					/>
				)}
			</div>
			<div className="community-sidebar-container">
				{!!(loadedCommunity && community) && (
					<CommunitySidebar
						community={community}
						sessionData={sessionData}
					/>
				)}
			</div>
			{!!showLoginSignupModal && (
				<LoginSignupModal
					onClose={() => setShowLoginSignupModal(false)}
					onComplete={handleLoginSignup}
				/>
			)}
		</Page>
	);
}

export default enhance(CommunityPage);
