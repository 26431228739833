import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';

import CommunityModeratorListItem from '../communityModeratorListItem';

import './communityModeratorList.scss';

export function CommunityModeratorList(props) {
	const { sessionData } = props;
	const sortedMods = _.sortBy(props.moderators, (r) => r.username);
	return (
		<div className={classnames('community-moderator-list', props.className)}>
			{_.map(sortedMods, (m, index) => (
				<CommunityModeratorListItem
					key={index}
					moderator={m}
					isCurrentUser={sessionData.id === m.id}
				/>
			))}
		</div>
	);
}

CommunityModeratorList.propTypes = {
	moderators: propTypes.arrayOf(propTypes.shape({
		username: propTypes.string,
	})),
	sessionData: propTypes.shape({
		username: propTypes.string
	})
};

export default CommunityModeratorList;
