import React from 'react';
import Checkbox from '../../components/lib/checkbox';
import FileInput from '../../components/lib/fileInput';
import TextInput from '../../components/lib/textInput';
import { Select } from '../../components/lib/select';
import _ from 'lodash';


export function renderSelect(props) {
	const { input, type, placeholder, size, meta: { error, pristine }, selectedValue, hidden, disabled, options } = props;
	const hasError = !pristine && error !== undefined;
	return (
		<Select
			type={type}
			placeholder={placeholder}
			defaultValue={selectedValue ? { value: selectedValue.value, label: selectedValue.name } : undefined}
			error={hasError ? true : undefined}
			size={size}
			errorMessage={error}
			hidden={hidden}
			disabled={disabled}
			options={options}

		>
			{props.children}
		</Select>
	);
}

export function renderInput(props) {
	const {
		input,
		type,
		placeholder,
		label,
		size,
		meta: { error, pristine, touched },
		withLabel,
		min,
		max,
		autocompleteResults,
		showAutocompleteResults,
		readOnly,
		isRequired,
		isOptional,
		helpText,
		inputType,
		onResultSelect
	} = props;
	const hasError = touched && error !== undefined;
	return (
		<TextInput
			{...input}
			type={type}
			placeholder={placeholder}
			hasError={touched && hasError ? true : undefined}
			size={size}
			errorMessage={error}
			withLabel={withLabel}
			readOnly={readOnly}
			label={label}
			mask={props.mask || null}
			isRequired={isRequired}
			isOptional={isOptional}
			helpText={helpText}
			min={min}
			max={max}
			inputType={inputType}
			autocompleteResults={autocompleteResults}
			showAutocompleteResults={showAutocompleteResults}
			onResultSelect={onResultSelect}
		/>
	);
}


export function renderFileInput(props) {
	const { id, input, type, placeholder, label, size, meta: { error, pristine, touched }, withLabel, acceptedTypes, uploadOnChange, handleUploadFile } = props;
	const { isRequired, helpText, uploading, onUpload, imageOptions, fileType } = props;
	const hasError = touched && error !== undefined;
	return (
		<FileInput
			{...input}
			type={type}
			placeholder={placeholder}
			error={hasError ? true : undefined}
			size={size}
			errorMessage={error}
			withLabel={withLabel}
			label={label}
			isRequired={isRequired}
			helpText={helpText}
			acceptedTypes={acceptedTypes}
			uploadOnChange={uploadOnChange}
			handleUploadFile={handleUploadFile}
			uploading={uploading}
			id={id}
			onUpload={onUpload}
			imageOptions={imageOptions}
			fileType={fileType}
		/>
	);
}

export function renderCheckbox(props) {
	const {
		input,
		type,
		placeholder,
		label,
		size,
		meta: { error, pristine },
		withLabel,
		readOnly,
		helpText
	} = props;
	const hasError = !pristine && error !== undefined;
	return (
		<Checkbox
			{...input}
			type={type}
			placeholder={placeholder}
			error={hasError ? true : undefined}
			size={size}
			errorMessage={error}
			withLabel={withLabel}
			readOnly={readOnly}
			label={label}
			helpText={helpText}
		/>
	);
}

export function hasValidEmail(val) {
	if (val && val.split('@').length !== 2) {
		return 'Please enter your email address';
	}
}

export function hasPassword(val) {
	if (!val || !val.length) {
		return 'Please enter your password';
	}
	if (val.length < 8) {
		return 'Passwords must be at least 8 characters long'
	}
}

export function hasDateValue(message) {
	return (val) => {
		if (typeof val === 'undefined' || _.isNaN(val)) {
			return message;
		}
	};
}

export function hasCountryCode(code) {
	if (!code || !code.length) {
		return 'Please select a country';
	}
}

export function hasPostalCode(postalCode) {
	if (!postalCode || !postalCode.length) {
		return 'Please provide a postal code';
	}
}

export function isValidAddress(val) {
	if (!(val || '').match(/0x[a-fA-F0-9]{40}/)) {
		return 'Please enter a valid address';
	}
}

export function isValidInput(fieldName) {
	return (val) => {
		if (!(val || '').length) {
			return `Please provide a valid ${fieldName}`;
		}
	}
}

export const hasFirstName = (val) => {
	if (!val || !val.length) {
		return 'First name is required';
	}
}

export const hasLastName = (val) => {
	if (!val || !val.length) {
		return 'Last name is required';
	}
}

export function isValidPhoneNumber(val) {
	if (!(val || '').replace(/[^0-9]/g, '').match(/[0-9]{10,11}/)) {
		return 'Please provide a valid phone number';
	}
}

export function normalize2FAInput(val) {
	return (val || '').replace(/[^0-9a-zA-Z]/, '');
}

export function isTOTPCode(val = '') {
	return val.match(/^[0-9]{6}$/);
}

export function isBackupCode(val = '') {
	return val.match(/^[0-9a-zA-Z]{10}$/);
}

export function hasValid2FAInput(val) {
	val = normalize2FAInput(val);
	if (!isTOTPCode(val) && !isBackupCode(val)) {
		return 'Please enter a valid TOTP code or a backup code';
	}
}

export function formatFieldName(name, prefix) {
	if (!prefix || !prefix.length) {
		return name;
	}
	return `${prefix}.${name}`;
}
