import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import Banner from '../lib/banner';

import './notificationBanner.scss';

export function NotificationBanner(props) {
	return (
		<Banner className={classnames('notification-banner', props.kind, props.className, `display-${props.display}`, `size-${props.size}`)}>
			{props.children}
		</Banner>
	);
}

NotificationBanner.propTypes = {
	kind: propTypes.oneOf(['info', 'warning', 'error', 'success']).isRequired,
	display: propTypes.oneOf(['square', 'rounded']),
	size: propTypes.oneOf(['regular', 'small'])
};

NotificationBanner.defaultProps = {
	display: 'square',
	size: 'regular'
};

export default NotificationBanner;
