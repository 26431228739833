import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import './headlineWrapper.scss';

export function HeadlineWrapper(props) {
	return (
		<div className={classnames('headline-wrapper', props.className, { underline: props.underline })}>
			{props.children}
		</div>
	);
}

HeadlineWrapper.propTypes = {
	underline: propTypes.bool
};

HeadlineWrapper.defaultProps = {
	underline: false
}

export default HeadlineWrapper;
