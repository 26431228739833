import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { withHandlers } from 'recompose';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import { FONT_COLORS, FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { mapDispatchCreateSessionToProps, selectCreateSession } from '../../store/selectors/session';
import { Button } from '../lib/button';
import Card from '../lib/card';
import CardBody from '../lib/cardBody';
import Headline from '../lib/headline';
import Paragraph from '../lib/paragraph';
import LoginForm from '../loginForm';

import './sidebarLoggedOut.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectCreateSession,
			(createSession) => ({
				...createSession
			})
		),
		{
			...mapDispatchCreateSessionToProps
		}
	),
	withHandlers({
		handleLogin: (props) => (fields) => {
			return props.createUserSession(fields)
			.then((data) => {
				if (!data.error) {
					props.onComplete();
				}
				return data;
			});
		},
	})
);

export function SidebarLoggedOut(props) {
	const {
		handleLogin
	} = props;
	return (
		<div className={classnames('sidebar-logged-out', props.className)}>
			<Card>
				<CardBody>
					<LoginForm onSubmit={handleLogin} />
				</CardBody>
			</Card>
			<Card className="signup-card">
				<CardBody>
					<Headline tag="h4" size={FONT_SIZES.lg} weight={FONT_WEIGHTS.bold}>
						Join Klique
					</Headline>
					<Paragraph color={FONT_COLORS.white}>
						Klique is a community of communities. Find yours or start one today.
					</Paragraph>
					<Button display="tertiary" size="small" isLink href="/signup">
						Create an account
					</Button>
				</CardBody>
			</Card>
		</div>
	);
}

SidebarLoggedOut.propTypes = {

};

export default enhance(SidebarLoggedOut);
