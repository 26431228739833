import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';

import CommunityListItem from '../communityListItem';

import './communityList.scss';

export function CommunityList(props) {
	const { sessionData } = props;

	return (
		<div className={classnames('community-list', props.className)}>
			{_.map(props.communities, (c, index) => (
				<CommunityListItem
					key={index}
					community={c}
					sessionData={sessionData}
				/>
			))}
		</div>
	);
}

CommunityList.propTypes = {
	communities: propTypes.arrayOf(propTypes.shape({
		id: propTypes.string.isRequired
	})),
	sessionData: propTypes.object,
};

export default CommunityList;
