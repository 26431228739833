import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import { FONT_STYLES } from '../../../lib/constants';
import { buildModifierClassFromProps } from '../../../lib/helpers';

import './text.scss';

const modifierProps = ['size', 'weight', 'color', 'transform'];

export function Text(props) {
	const { className } = props;

	return (
		<span
			{..._.omit(props, [...modifierProps])}
			className={classnames('text', className, ...buildModifierClassFromProps(modifierProps, props))}
		>
			{props.children}
		</span>
	);
}

Text.propTypes = {
	size: propTypes.oneOf(FONT_STYLES.sizes),
	weight: propTypes.oneOf(FONT_STYLES.weights),
	color: propTypes.oneOf(FONT_STYLES.colors),
	transform: propTypes.oneOf(['uppercase', 'italic'])
};

Text.defaultProps = {
	size: 'inherit',
	weight: 'inherit',
	color: 'inherit',
};

export default Text;
