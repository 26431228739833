import _ from 'lodash';
import { normalizeCommunitySlug } from '../../lib/helpers';
import {
	createCommunity,
	createCommunityPost,
	getCommunityBySlug,
	getCommunityPostByShortId,
	getCommunityPostsBySlug,
	listAllCommunities,
	listCommunityRulesByCommunitySlug,
	updateCommunity
} from '../community/actions';

export const selectCreateCommunity = (state) => ({
	creatingCommunity: _.get(state, 'community.creatingCommunity') || {}
});

export const mapDispatchCreateCommunityToProps = {
	createCommunity
};


export const selectCommunityPosts = (state, props) => ({
	gettingCommunityPosts: _.get(state, 'community.gettingCommunityPosts') || {},
	postsCursor: _.get(state, `community.communityPosts[${normalizeCommunitySlug(props.displaySlug)}].cursor`) || {},
	posts: _.map(_.get(state, `community.communityPosts[${normalizeCommunitySlug(props.displaySlug)}].sort[${props.sort}]`), (postId) => {
		return _.get(state, `posts.postsById[${postId}]`);
	})
});

export const mapDispatchCommunityPostsToProps = {
	getCommunityPostsBySlug
};

export const selectCommunity = (state, props) => ({
	community: _.get(state, `community.communities[${normalizeCommunitySlug(props.displaySlug)}]`) || null,
	gettingCommunityPosts: _.get(state, 'community.gettingCommunity') || {},
});

export const mapDispatchCommunityToProps = {
	getCommunityBySlug
};

export const selectCreateCommunityPost = (state, props) => ({
	creatingCommunityPost: _.get(state, 'community.creatingCommunityPost') || {}
});

export const mapDispatchCreateCommunityPostToProps = {
	createCommunityPost
};

export const selectCommunityPost = (state, props) => {
	const { displaySlug, postShortId } = props;
	const normalizedSlug = normalizeCommunitySlug(displaySlug);

	const community = _.get(state.community, `communities[${normalizedSlug}]`);
	const postId = _.get(state.posts, `postIdsByShortId[${postShortId}]`);
	const post = _.get(state.posts, `postsById[${postId}]`);

	return {
		community,
		post,
		gettingCommunityPost: _.get(state.community, 'gettingCommunityPost') || {}
	};
}

export const mapDispatchCommunityPostToProps = {
	getCommunityPostByShortId
};

export const selectAllCommunities = (state, props) => ({
	listingAllCommunities: _.get(state.community, 'listingAllCommunities') || {},
	communities: _.get(state.community, `allCommunityList`),
});

export const mapDispatchAllCommunitiesToProps = {
	listAllCommunities
};

export const selectManageCommunity = (state) => ({
	listingCommunityRules: _.get(state.community, 'listingCommunityRules') || {},
	updatingCommunity: _.get(state.community, 'updatingCommunity') || {}
});

export const mapDispatchManageCommunityToProps = {
	listCommunityRulesByCommunitySlug,
	updateCommunity
};
