import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import { buildModifierClassFromProps } from '../../../lib/helpers';

import './formRow.scss';

export function FormRow(props) {
	return (
		<div className={classnames('form-row', props.className)}>
			{props.children}
		</div>
	);
}

FormRow.propTypes = {
	children: propTypes.any
};

const formItemModifierProps = ['width'];

export function FormItem(props) {
	return (
		<div className={classnames('form-item', props.className, ...buildModifierClassFromProps(formItemModifierProps, props))}>
			{props.children}
		</div>
	);
}

FormItem.propTypes = {
	children: propTypes.any,
	width: propTypes.oneOf(['30', '40', '50', '60', '70']),
};

export function ButtonRow(props) {
	return (
		<FormRow {...props} className={classnames('button-row', props.className)} />
	);
}

ButtonRow.propTypes = {
	children: propTypes.any
};
