import { generateCRUDActions, spreadCRUDActions } from '../helpers';
import { CALL_API } from '../middleware/api';

const namespace = 'COMMUNITY';

export const COMMUNITY = {
	CREATE_COMMUNITY: generateCRUDActions(namespace, 'CREATE_COMMUNITY'),
	GET_COMMUNITY_POSTS_BY_SLUG: generateCRUDActions(namespace, 'GET_COMMUNITY_POSTS_BY_SLUG'),
	GET_COMMUNITY_BY_SLUG: generateCRUDActions(namespace, 'GET_COMMUNITY_BY_SLUG'),
	CREATE_COMMUNITY_POST: generateCRUDActions(namespace, 'CREATE_COMMUNITY_POST'),
	GET_COMMUNITY_POST: generateCRUDActions(namespace, 'GET_COMMUNITY_POST'),
	LIST_ALL_COMMUNITIES: generateCRUDActions(namespace, 'LIST_ALL_COMMUNITIES'),
	LIST_COMMUNITY_RULES: generateCRUDActions(namespace,'LIST_COMMUNITY_RULES'),
	UPDATE_COMMUNITY: generateCRUDActions(namespace,'UPDATE_COMMUNITY'),
};

export function createCommunity(data, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(COMMUNITY.CREATE_COMMUNITY),
			endpoint: '/communities',
			method: 'post',
			data,
			options: {
				...options,
			}
		}
	};
}

export function getCommunityPostsBySlug(displaySlug, query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(COMMUNITY.GET_COMMUNITY_POSTS_BY_SLUG),
			endpoint: `/communities-by-slug/${displaySlug}/posts`,
			method: 'get',
			query: query,
			options: {
				...options,
				displaySlug
			}
		}
	};
}

export function getCommunityBySlug(displaySlug, query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(COMMUNITY.GET_COMMUNITY_BY_SLUG),
			endpoint: `/communities-by-slug/${displaySlug}`,
			method: 'get',
			query: query,
			options: {
				...options,
				displaySlug
			}
		}
	};
}

export function createCommunityPost(communityId, data, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(COMMUNITY.CREATE_COMMUNITY_POST),
			endpoint: `/communities/${communityId}/posts`,
			method: 'post',
			data,
			options: {
				...options,
				communityId,
			}
		}
	};
}

export function getCommunityPostByShortId(displaySlug, shortId, query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(COMMUNITY.GET_COMMUNITY_POST),
			endpoint: `/communities-by-slug/${displaySlug}/posts-by-short-id/${shortId}`,
			method: 'get',
			query,
			options: {
				...options,
				displaySlug,
				shortId
			}
		}
	};
}

export function listAllCommunities(query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(COMMUNITY.LIST_ALL_COMMUNITIES),
			endpoint: `/communities`,
			method: 'get',
			query: {
				orderFields: 'numeric_id',
				limit: 50,
				offset: 0,
				...query,
			},
			options: {
				...options,
			}
		}
	};
}

export function listCommunityRules(communityId, query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(COMMUNITY.LIST_COMMUNITY_RULES),
			endpoint: `/communities/${communityId}/rules`,
			method: 'get',
			query: {
				...query,
			},
			options: {
				...options,
				communityId
			}
		}
	};
}
export function listCommunityRulesByCommunitySlug(displaySlug, query = {}, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(COMMUNITY.LIST_COMMUNITY_RULES),
			endpoint: `/communities-by-slug/${displaySlug}/rules`,
			method: 'get',
			query: {
				...query,
			},
			options: {
				...options,
				displaySlug
			}
		}
	};
}
export function updateCommunity(communityId, data, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(COMMUNITY.UPDATE_COMMUNITY),
			endpoint: `/communities/${communityId}`,
			method: 'patch',
			data,
			options: {
				...options,
				communityId
			}
		}
	};
}
