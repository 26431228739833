import Promise from 'bluebird';
import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { hasValidEmail, hasPassword, renderInput } from '../../lib/formHelpers';
import { selectCreateAccount } from '../../store/selectors/account';
import { Button } from '../lib/button';
import ErrorMessage from '../lib/errorMessage';
import Form from '../lib/form';
import { FormItem, FormRow } from '../lib/formRow';
import Headline from '../lib/headline';
import Link from '../lib/link';
import './signupForm.scss';


export const asyncValidate = (values) => {
	return Promise.resolve()
	.then(() => {
		const errors = {};

		if (!_.isEmpty(errors)) {
			return Promise.reject(errors);
		}
	});
};

export const enhance = compose(
	connect(selectCreateAccount),
	mapProps((props) => ({
		...props,
		createAccountErrorMessage: _.get(props, 'createAccount.error') ? 'There was a problem creating your account' : null
	})),
	reduxForm({
		form: 'signup-form',
		fields: [
			'email',
			'password',
			'confirmPassword',
		],
		asyncValidate,
		asyncBlurFields: ['email', 'password']
	}),
);

export const hasUsername = (val) => {
	if (!val || !val.length) {
		return 'Username is required';
	}
}

export function SignupForm(props) {
	const {
		onSubmit,
		handleSubmit,
		valid,
		createAccountErrorMessage,
		createAccount,
		anyTouched
	} = props;
	const buttonProps = {
		size: 'regular',
		display: 'primary',
		loading: createAccount.loading
	};
	if (!valid || !anyTouched) {
		buttonProps.disabled = true;
	}
	return (
		<div className={classnames('signup-form')}>
			<Form onSubmit={handleSubmit(onSubmit)} className={classnames(`${props.color}-text`)}>
				<Headline tag="h1" size={FONT_SIZES.xl4} weight={FONT_WEIGHTS.black}>
					Sign up
				</Headline>
				<FormRow>
					<FormItem>
						<Field
							id="username"
							name="username"
							type="text"
							label="Username"
							withLabel={true}
							component={renderInput}
							validate={[hasUsername]}
							isRequired
						/>
					</FormItem>
				</FormRow>
				<FormRow>
					<FormItem>
						<Field
							id="email"
							name="email"
							type="text"
							label="Email Address"
							withLabel={true}
							component={renderInput}
							validate={[hasValidEmail]}
							isOptional
						/>
					</FormItem>
				</FormRow>
				<FormRow>
					<FormItem>
						<Field
							id="password"
							name="password"
							type="password"
							label="Password"
							withLabel={true}
							component={renderInput}
							validate={[hasPassword]}
							helpText="Password must be 10 or more characters"
							isRequired
						/>
					</FormItem>
				</FormRow>
				<FormRow>
					<FormItem>
						<div className="signup-button">
							<Button {...buttonProps}>
								Sign up
							</Button>
						</div>
					</FormItem>
				</FormRow>
				{createAccountErrorMessage && (
					<FormRow>
						<ErrorMessage>
							{createAccountErrorMessage}
						</ErrorMessage>
					</FormRow>
				)}
				<FormRow className="text-links existing-account">
					<FormItem>
						<Link to={'/login'} linkStyle="gray" size="sm">
							Already have an account?
						</Link>
					</FormItem>
				</FormRow>
			</Form>
		</div>
	);
}

SignupForm.propTypes = {
	color: propTypes.oneOf(['white', 'regular'])
};

SignupForm.defaultProps = {
	color: 'white'
};

export default enhance(SignupForm);
