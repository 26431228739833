import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { compose, mapProps, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import { SegmentContextProvider, withAttributedSegmentTracking } from '../../lib/analytics';
import { buildCommunityUrl, buildLoginReturnTo, buildSignupReturnTo } from '../../lib/helpers';
import { selectApp } from '../../store/selectors/app';
import { mapDispatchVerificationLinkToProps, selectSession } from '../../store/selectors/session';
import AuthenticatedHeaderContent from '../authenticatedHeaderContent';
import { CommunityNavigation } from '../communityNavigation';
import Button from '../lib/button';
import Link from '../lib/link';

import './header.scss';

export const enhance = compose(
	withToastManager,
	connect(
		createSelector(
			selectApp,
			selectSession,
			(app, session) => ({
				...app,
				...session
			})
		),
		mapDispatchVerificationLinkToProps
	),
	withAttributedSegmentTracking,
	withState('showingBetaModal', 'setShowingBetaModal', false),
	mapProps((props) => ({
		...props,
		loginReturnTo: buildLoginReturnTo(props),
		signupReturnTo: buildSignupReturnTo(props)
	})),
	withHandlers({
		handleRequestVerificationLink: (props) => (fields) => {
			const { toastManager } = props;
			props.requestVerificationLink(props.sessionData.email)
			.then((data) => {
				if (!data.error) {
					toastManager.add('Verification email sent!', { appearance: 'success', autoDismiss: true });
				}
			});
		},
	})
);

const communityTypePages = [
	'community',
	'community-post',
	'community-create-post',
	'moderate-community'
];

const showPostSortOnPages = [
	'community',
	'home'
]

export function Header(props) {
	const {
		className,
		sessionData,
		headerContent,
		handleRequestVerificationLink,
		requestingVerificationLink,
		showingBetaModal,
		setShowingBetaModal
	} = props;

	const displaySlug = _.get(props, 'match.params.displaySlug') || '';

	return (
		<SegmentContextProvider segmentContext={{ containerName: 'header' }}>
			<div className={classnames('header', className)}>
				{/*
				{!!(sessionData && sessionData.hasSession && !sessionData.verified) && (
					<NotificationBanner kind="info" className="alert-banner verification-banner">
						<div className="banner-message">
							Please verify your email by clicking the link we sent you
						</div>
						<Button
							size="small"
							onClick={handleRequestVerificationLink}
							loading={requestingVerificationLink.loading}
						>
							Resend verification email
						</Button>
					</NotificationBanner>
				)}
				*/}
				<div className="header-content-container">
					<div className="header-top">
						<div className="left-side">
							<Link className="logo" to="/" isNavLink>
								Klique <span className="alpha">(alpha)</span>
							</Link>
							{communityTypePages.includes(props.pageName) ? (
								<Link className="community-name" to={buildCommunityUrl(displaySlug)}>
									{_.get(props, 'match.params.displaySlug')}
								</Link>
							) : null}
							{showPostSortOnPages.includes(props.pageName) ? (
								<CommunityNavigation basePath={displaySlug.length ? buildCommunityUrl(displaySlug) : ''} />
							) : null}
						</div>
						<div className="right-side">
							{sessionData.hasSession ? (
								<AuthenticatedHeaderContent sessionData={sessionData} />
							) : (
								<>
									<div className="right-side-item">
										<Button
											size="small"
											display="primary-outline"
											segmentConfig={{ label: 'header-login' }}
											isLink
											href="/login"
										>
											Log In
										</Button>
									</div>
									<div className="right-side-item">
										<Button
											size="small"
											segmentConfig={{ label: 'header-signup' }}
											isLink
											href="/signup"
										>
											Sign Up
										</Button>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</SegmentContextProvider>
	);
}

Header.propTypes = {
	hostname: propTypes.string,
	location: propTypes.object,
	headerContent: propTypes.any,
	pageName: propTypes.string,
	match: propTypes.object
};

export default enhance(Header);
