import classnames from 'classnames';
import numeral from 'numeral';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withHandlers, withState } from 'recompose';

import { compose } from 'redux';
import { createSelector } from 'reselect';
import { FONT_WEIGHTS } from '../../../lib/constants';
import { selectCreatePostComment } from '../../../store/selectors/posts';
import CommentForm from '../../forms/commentForm';
import SelectBox from '../../lib/selectBox';
import Text from '../../lib/text';
import PostComment from '../postComment';

import './postComments.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectCreatePostComment,
			(createPostComment) => ({
				...createPostComment,
			})
		),
	),
	withState('commentSort', 'setCommentSort', (props) => {
		return props.sort || 'best';
	}),
	withHandlers({
		handleSort: (props) => (selected) => {
			props.setCommentSort(selected);
		},
	})
);

export function PostComments(props) {
	const {
		handleSort,
		commentSort,
		handleAddComment,
		comments,
		commentTree,
		users,
		sessionData,
		creatingPostComment,
		userCommentVotes,
		handleUpdateComment,
		onVote,
		showModActions,
		onRemove
	} = props;

	const commentCount = comments && _.values(comments).length ? numeral(_.values(comments).length).format('0,0') : '--';

	return (
		<div className={classnames('post-comments', props.className)}>
			<div className="post-comments-header">
				<Text weight={FONT_WEIGHTS.bold} className="showing-comments">
					Showing {commentCount} comments
				</Text>
				<div className="comment-sort-container">
					<Text>sort by:</Text>
					<SelectBox
						options={[
							{ value: 'best', label: 'Best' },
							{ value: 'new', label: 'New' },
						]}
						input={{
							value: commentSort,
							onChange: handleSort
						}}
						meta={{}}
						name="comment-sort"
					/>
				</div>
			</div>
			<div className="add-comment-wrapper">
				<CommentForm
					onSubmit={handleAddComment}
					loading={creatingPostComment.loading}
					formError={creatingPostComment.error}
					parentCommentId={null}
					formAction="create"
				/>
			</div>
			<div className="comments-wrapper">
				{_.map(commentTree, (ct) => (
					<PostComment
						key={ct.id}
						sessionData={sessionData}
						comment={ct}
						comments={comments}
						users={users}
						onVote={onVote}
						onAddComment={handleAddComment}
						onUpdateComment={handleUpdateComment}
						userCommentVotes={userCommentVotes}
						showModActions={showModActions}
						onRemove={onRemove}
					/>
				))}
			</div>
		</div>
	);
}

PostComments.propTypes = {
	sort: propTypes.oneOf(['best', 'new']),
	handleAddComment: propTypes.func.isRequired,
	handleUpdateComment: propTypes.func.isRequired,
	sessionData: propTypes.shape({
		hasSession: propTypes.bool
	}).isRequired,
	comments: propTypes.objectOf(propTypes.shape({
		id: propTypes.string,
	})),
	commentTree: propTypes.arrayOf(propTypes.shape({
		id: propTypes.string,
		numericId: propTypes.number,
		depth: propTypes.number,
		children: propTypes.arrayOf(propTypes.object)
	})),
	users: propTypes.objectOf(propTypes.shape({
		username: propTypes.string
	})),
	userCommentVotes: propTypes.objectOf(propTypes.number),
	onVote: propTypes.func.isRequired,
	showModActions: propTypes.bool,
	onRemove: propTypes.func
};


export default enhance(PostComments);
