import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import './cardHeader.scss';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../lib/constants';
import Headline from '../headline';

export function CardHeader(props) {
	const {
		className,
		children
	} = props;

	return (
		<div className={classnames('card-header', className)}>
			{props.headerText ? (
				<Headline size={FONT_SIZES.xl3} weight={FONT_WEIGHTS.black}>
					{props.headerText}
				</Headline>
			) : props.children}
		</div>
	);
}

CardHeader.propTypes = {
	className: propTypes.string,
	children: propTypes.any,
	headerText: propTypes.string,
};

export default CardHeader;
