import './lib/dayjs';

import * as _ from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';

import App from './components/app';
import { isClientSide } from './lib/helpers';

import { getRollbar } from './lib/rollbar/rollbar';
import { staticRoutes } from './routes';
import createStore from './store';
import { setTheme } from './store/app/actions';
import history from './store/history';

const store = createStore(_.get(window, '__PRELOADED_STATE__'));
const state = store.getState();

const rollbarClientToken = _.get(state, 'app.rollbar.clientToken');
const hostname = (_.get(state, 'app.hostname') || '').split('://').slice(1).join('://');

if (rollbarClientToken) {
	getRollbar({
		accessToken: rollbarClientToken,
		hostAllowList: [hostname]
	});
}

if (isClientSide()) {
	const themeMatch = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
	if (themeMatch) {
		themeMatch.addEventListener('change', (event) => {
			if (event.matches) {
				store.dispatch(setTheme('dark'));
			} else {
				store.dispatch(setTheme('light'));
			}
		});
		const currentTheme = themeMatch.matches ? 'dark' : 'light';
		store.dispatch(setTheme(currentTheme));
	}
}


function Application() {
	return (
		<Provider store={store}>
			<App>
				<Router history={history}>
					{renderRoutes(staticRoutes)}
				</Router>
			</App>
		</Provider>
	);
}

ReactDOM.hydrate(<Application />, document.getElementById('application-container'));
