import { generateCRUDActions, spreadCRUDActions } from '../helpers';
import { CALL_API } from '../middleware/api';

const namespace = 'DATA';

export const DATA = {
	CRAWL_URL: generateCRUDActions(namespace, 'CRAWL_URL'),
};

export function crawlUrl(url, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(DATA.CRAWL_URL),
			endpoint: '/crawl-url',
			method: 'post',
			data: { url },
			options: {
				...options,
			}
		}
	};
}
