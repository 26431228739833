import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { compose, mapProps, withHandlers } from 'recompose';
import { selectApp } from '../../../store/selectors/app';
import ErrorMessage from '../errorMessage';
import FormFieldLabel from '../formFieldLabel';

import './selectBox.scss';

export const enhance = compose(
	connect(selectApp),
	withHandlers({
		handleSelect: (props) => (option) => {
			if (props.input.onChange) {
				props.input.onChange(option.value);
			}
		}
	}),
	mapProps((props) => ({
		...props,
		selectedValue: _.find(props.options, (o) => props.input.value === o.value)
	}))
);

export function SelectBox(props) {
	const { input, options, placeholder, label, selectedValue, handleSelect, handleBlur, selectOptions = {}, disabled } = props;
	const { readOnly } = props;
	const { error, touched } = props.meta;
	let defaultValue = selectedValue ? { value: selectedValue.value, label: selectedValue.label } : undefined;
	if (selectOptions.isMulti) {
		defaultValue = _.map(input.value, (v) => {
			return  _.find(options, (so) => so.value === v);
		});
	}

	return (
		<div className={classnames('select-box', props.className)}>
			<FormFieldLabel name={input.name}>
				{label}
			</FormFieldLabel>
			<Select
				className="react-select-container"
				classNamePrefix="react-select"
				placeholder={placeholder}
				value={defaultValue}
				options={options}
				name={input.name}
				isDisabled={disabled === true || readOnly === true}
				onChange={(val) => {
					if (selectOptions.isMulti) {
						return input.onChange(_.map(val, (v) => v.value));
					}
					return handleSelect(val);
				}}
				{...(selectOptions || {})}
			/>
			{!!(touched && error && error.length) && (
				<ErrorMessage>
					{error}
				</ErrorMessage>
			)}
		</div>
	);
}

export default enhance(SelectBox);

