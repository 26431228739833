import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps, withState } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { renderCheckbox, renderInput } from '../../../lib/formHelpers';
import { Button } from '../../lib/button';
import Form from '../../lib/form';
import { ButtonRow, FormItem, FormRow } from '../../lib/formRow';

import './createCommunityForm.scss';

const FORM_NAME = 'create-community-form';

export const enhance = compose(
	withProps((props) => ({
		...props,
		initialValues: {
			rules: [{}],
			...(props.initialValues || {}),
		}
	})),
	reduxForm({ form: FORM_NAME, enableReinitialize: true }),
	connect(
		createSelector(
			(state) => ({ formValues: getFormValues(FORM_NAME)(state) }),
			(formValues) => ({
				...formValues
			})
		)
	),
	withState('communityRules', 'setCommunityRules', (props) => {
		return _.sortBy(_.get(props, 'initialValues.rules'), ((r) => r.position * -1));
	}),
	withState('communityRuleCount', 'setCommunityRuleCount', (props) => {
		const initial = _.get(props, 'initialValues.rules');
		if (_.isArray(initial)) {
			return initial.length;
		}
		return 1;
	}),
	withState('rulesToDelete', 'setRulesToDelete', []),
	withHandlers({
		handleDeleteCreateRule: (props) => () => {
			const { communityRuleCount, setCommunityRuleCount } = props;
			setCommunityRuleCount(communityRuleCount - 1);
		},
		interceptSubmit: (props) => (fields) => {
			if (props.action === 'create') {
				return props.onSubmit(fields);
			}
			return props.onSubmit(_.omit(fields, ['rules']));
		}
	})
);

const hasValidName = (name) => {
	if (!name || !name.length) {
		return 'Please provide a name';
	}
}

const hasValidAbout = (about) => {
	if (!about || !about.length) {
		return 'Please describe your community';
	}
}

function slugifyName(str) {
	return _.trim(str)
	.replace(/\s+/gi, '_')
	.replace(/[^A-Za-z0-9_-]+/gi, '')
}

export function CreateCommunityForm(props) {
	const {
		interceptSubmit,
		handleSubmit,
		valid,
		errors,
		loading,
		formError,
		communityRules,
		communityRuleCount,
		setCommunityRuleCount,
		action,
		handleDeleteCreateRule
	} = props;
	const buttonProps = {
		loading: loading,
		size: 'regular'
	};
	if (!valid) {
		buttonProps.disabled = true;
	}
	const name = slugifyName(props.formValues.name || '');
	return (
		<Form
			onSubmit={handleSubmit(interceptSubmit)}
			className={classnames('create-community-form', props.className)}
		>
			<FormRow>
				<FormItem>
					<Field
						id="name"
						name="name"
						type="text"
						label="Name"
						isRequired
						component={renderInput}
						readOnly={action === 'update'}
						validate={action === 'created' ? [hasValidName] : []}
						helpText={`/c/${name}`}
					/>
				</FormItem>
			</FormRow>
			<FormRow>
				<FormItem>
					<Field
						id="about"
						name="about"
						type="text"
						inputType="textarea"
						label="About"
						isRequired
						component={renderInput}
						helpText="Appears in search results and social media links (500 characters max)"
						validate={[hasValidAbout]}
					/>
				</FormItem>
			</FormRow>
			<FormRow>
				<FormItem>
					<Field
						id="adultOnly"
						name="adultOnly"
						type="text"
						label="Require viewers to be 18 years old or older?"
						component={renderCheckbox}
						helpText="This is required for communities containing NSFW content"
					/>
				</FormItem>
			</FormRow>
			<FormRow>
				<FormItem>
					<Field
						id="rawRescription"
						name="rawDescription"
						type="text"
						inputType="textarea"
						label="Sidebar Content"
						component={renderInput}
						helpText="Custom content to show in your community's sidebar (markdown supported)"
					/>
				</FormItem>
			</FormRow>
			{action === 'create' && (
				<div className="community-rules-list-wrapper">
					{_.map(_.range(0, communityRuleCount), (ruleIndex) => {
						const cr = communityRules[ruleIndex];
						return (
							<div className="community-rule" key={ruleIndex}>
								<FormRow>
									<FormItem>
										<Field
											id={`rules[${ruleIndex}].title`}
											name={`rules[${ruleIndex}].title`}
											type="text"
											label="Rule"
											isRequired
											component={renderInput}
											helpText="Name of the rule"
										/>
									</FormItem>
								</FormRow>
								<FormRow>
									<FormItem>
										<Field
											id={`rules[${ruleIndex}].rawRule`}
											name={`rules[${ruleIndex}].rawRule`}
											type="text"
											label="Rule details"
											inputType="textarea"
											isRequired
											component={renderInput}
											helpText="Details about the rule (markdown supported)"
										/>
									</FormItem>
								</FormRow>
								{(ruleIndex === communityRuleCount - 1 && communityRuleCount > 1) && (
									<FormRow>
										<Button
											size="small"
											display="danger-outline"
											type="button"
											onClick={handleDeleteCreateRule}
										>
											Remove
										</Button>
									</FormRow>
								)}
							</div>
						);
					})}
					<FormRow className="add-rule-button-row">
						<Button
							size="small"
							onClick={() => setCommunityRuleCount(communityRuleCount + 1)}
							type="button"
							display="primary-outline"
						>
							Add another rule
						</Button>
					</FormRow>
				</div>
			)}
			<ButtonRow>
				<Button {...buttonProps}>
					{action === 'create' ? 'Create' : 'Save'}
				</Button>
			</ButtonRow>
			{!!(formError && formError.message) && (
				<FormRow>
					<FormItem>
						<div className="form-formError-message">
							{formError.message}
						</div>
					</FormItem>
				</FormRow>
			)}
		</Form>
	);
}

CreateCommunityForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
	loading: propTypes.bool.isRequired,
	formError: propTypes.any,
	action: propTypes.oneOf(['create', 'update'])
};

CreateCommunityForm.defaultProps = {
	action: 'create'
};

export default enhance(CreateCommunityForm);
