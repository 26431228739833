import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { compose, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import CreateCommunityForm from '../../components/forms/createCommunityForm';
import HeadlineWrapper from '../../components/headlineWrapper';
import AuthenticatedPage from '../../components/layouts/authenticatedPage';
import Card from '../../components/lib/card';
import CardBody from '../../components/lib/cardBody';
import CardHeader from '../../components/lib/cardHeader';
import Headline from '../../components/lib/headline';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { mapDispatchCreateCommunityToProps, selectCreateCommunity } from '../../store/selectors/community';
import { selectSession } from '../../store/selectors/session';

import './createCommunity.scss';

export const enhance = compose(
	withToastManager,
	connect(
		createSelector(
			selectSession,
			selectCreateCommunity,
			(session, community) => ({
				...session,
				...community
			})
		),
		mapDispatchCreateCommunityToProps
	),
	withState('communityCreated', 'setCommunityCreated', false),
	withHandlers({
		handleCreateCommunity: (props) => (fields) => {
			const { createCommunity, toastManager, history, setCommunityCreated } = props;
			
			return createCommunity(fields)
			.then((data) => {
				if (data.error) {
					toastManager.add(`Failed to create community`, { appearance: 'error', autoDismiss: true });
					return data;
				}

				toastManager.add(`Community created!`, { appearance: 'success', autoDismiss: true });

				// setTimeout(() => {
				// 	history.push(`/c/${_.get(data, 'payload.displaySlug')}`);
				// }, 1000);

				setCommunityCreated(true);

				return data;
			});
		}
	})
);

export function CreateCommunityPage(props) {
	const {
		handleCreateCommunity,
		creatingCommunity,
		communityCreated
	} = props;
	return (
		<AuthenticatedPage
			{...props}
			className={classnames('create-community-page')}
			withContent
			pageName="createCommunity"
			requiresSession
			title="Create a Community"
		>
			<HeadlineWrapper underline>
				<Headline tag="h1" size={FONT_SIZES.xl} weight={FONT_WEIGHTS.bold}>
					Create a Community
				</Headline>
			</HeadlineWrapper>
			{communityCreated !== true && (
				<CreateCommunityForm
					onSubmit={handleCreateCommunity}
					loading={creatingCommunity.loading}
					formError={creatingCommunity.error}
					action="create"
				/>
			)}
			{communityCreated === true && (
				<Card className="community-created-card">
					<CardBody>
						<Headline tag="h3" size={FONT_SIZES.xl3} weight={FONT_WEIGHTS.bold}>
							Your community has been created!
						</Headline>
						Your community has been created and is pending review.
					</CardBody>
				</Card>
			)}
		</AuthenticatedPage>
	);
}

export default enhance(CreateCommunityPage);
