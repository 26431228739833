import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { hasValidEmail, renderInput } from '../../lib/formHelpers';
import { Button } from '../lib/button';
import Form from '../lib/form';
import { ButtonRow, FormItem, FormRow } from '../lib/formRow';
import Headline from '../lib/headline';

import './requestPasswordResetForm.scss';

const FORM_NAME = 'request-password-reset-form';

export const enhance = compose(
	reduxForm({ form: FORM_NAME }),
	connect(
		createSelector(
			(state) => ({ formValues: getFormValues(FORM_NAME)(state) }),
			(formValues) => ({
				...formValues
			})
		)
	),
);


export function RequestPasswordResetForm(props) {
	const {
		onSubmit,
		handleSubmit,
		valid,
		anyTouched,
		loading,
		formError
	} = props;
	const buttonProps = {
		size: 'regular',
		display: 'primary',
		loading
	};
	if (!valid || !anyTouched) {
		buttonProps.disabled = true;
	}
	return (
		<div className={classnames('request-password-reset-form')}>
			<Form onSubmit={handleSubmit(onSubmit)} className={classnames(`${props.color}-text`)}>
				<Headline tag="h1" as="h5" size={FONT_SIZES.xl} weight={FONT_WEIGHTS.bold}>
					Reset your password
				</Headline>
				<FormRow>
					<Field
						name="email"
						type="text"
						placeholder="Email"
						component={renderInput}
						validate={[hasValidEmail]}
						isRequired
					/>
				</FormRow>
				<ButtonRow>
					<Button {...buttonProps}>
						Request Password Reset
					</Button>
				</ButtonRow>
				{!!(formError && formError.message) && (
					<FormRow>
						<FormItem>
							<div className="form-formError-message">
								{formError.message}
							</div>
						</FormItem>
					</FormRow>
				)}
			</Form>
		</div>
	);
}

RequestPasswordResetForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
	loading: propTypes.bool,
	formError: propTypes.any,
};

export default enhance(RequestPasswordResetForm);
