import { CALL_API } from '../middleware/api';

export const FILES = {
	UPLOAD_FILE_REQUEST: 'FILES/UPLOAD_FILE_REQUEST',
	UPLOAD_FILE_SUCCESS: 'FILES/UPLOAD_FILE_SUCCESS',
	UPLOAD_FILE_FAILURE: 'FILES/UPLOAD_FILE_FAILURE',
	UPLOAD_FILE_PROGRESS: 'FILES/UPLOAD_FILE_PROGRESS',
};

export function uploadFile(id, formData, options = {}) {
	return {
		[CALL_API]: {
			types: [FILES.UPLOAD_FILE_REQUEST, FILES.UPLOAD_FILE_SUCCESS, FILES.UPLOAD_FILE_FAILURE, FILES.UPLOAD_FILE_PROGRESS],
			endpoint: '/uploads',
			method: 'post',
			formData,
			options: {
				...options,
				id
			}
		}
	};
}

export function uploadImage(id, formData, options = {}) {
	return {
		[CALL_API]: {
			types: [FILES.UPLOAD_FILE_REQUEST, FILES.UPLOAD_FILE_SUCCESS, FILES.UPLOAD_FILE_FAILURE, FILES.UPLOAD_FILE_PROGRESS],
			endpoint: '/uploads/images',
			method: 'post',
			formData,
			options: {
				...options,
				id
			}
		}
	};
}
