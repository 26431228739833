import React from 'react';
import { handleActions } from 'redux-actions';
import { FILES } from './actions';
import _ from 'lodash';

export const initialState = {
	uploadingFile: {
		uploads: {}
	}
};

export default handleActions({
	[FILES.UPLOAD_FILE_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			uploadingFile: {
				...state.uploadingFile,
				uploads: {
					...state.uploadingFile.uploads,
					[options.id]: {
						loading: true,
						error: null,
						uploadedFile: null,
						progressPercent: 0
					}
				}
			}
		};
	},
	[FILES.UPLOAD_FILE_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			uploadingFile: {
				...state.uploadingFile,
				uploads: {
					...state.uploadingFile.uploads,
					[options.id]: {
						..._.get(state, `uploadingFile.uploads[${options.id}]`) || {},
						loading: false,
						error: null,
						uploadedFile: _.first(payload.files),
						progressPercent: 100
					}
				}
			}
		};
	},
	[FILES.UPLOAD_FILE_FAILURE]: (state, { error, options }) => {
		return {
			...state,
			uploadingFile: {
				...state.uploadingFile,
				uploads: {
					...state.uploadingFile.uploads,
					[options.id]: {
						loading: false,
						error: _.get(error, 'data'),
					}
				}
			}
		};
	},
	[FILES.UPLOAD_FILE_PROGRESS]: (state, { payload, options }) => {
		console.log('PROGRESS: ', payload);
		return {
			...state,
			uploadingFile: {
				...state.uploadingFile,
				uploads: {
					...state.uploadingFile.uploads,
					[options.id]: {
						..._.get(state, `uploadingFile.uploads[${options.id}]`) || {},
						progressPercent: _.min([payload.progressPercent, 85])
					}
				}
			}
		};
	},
}, initialState);
