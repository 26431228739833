import _ from 'lodash';
import {
	createBetaSignup,
	createUserAccount,
	requestPasswordReset,
	resetPassword,
	updateUser,
	updateUserPassword
} from '../account/actions';

export const selectCreateBetaSignup = (state) => ({
	creatingBetaSignup: _.get(state.account, 'creatingBetaSignup') || {}
});

export const mapDispatchCreateBetaSignupToProps = {
	createBetaSignup
};

export const selectCreateAccount = (state) => ({
	createAccount: _.get(state, 'account.createAccount') || {}
});

export const mapDispatchCreateAccountToProps = {
	createUserAccount
};


export const selectUpdateAccount = (state) => ({
	updatingUserPassword: _.get(state.account, 'updatingUserPassword') || {},
	updatingUser: _.get(state.account, 'updatingUser') || {},
});

export const mapDispatchUpdateAccountToProps = {
	updateUser,
	updateUserPassword
};

export const selectResetPassword = (state) => ({
	requestingPasswordReset: _.get(state.account, 'requestingPasswordReset') || {},
	resettingPassword: _.get(state.account, 'resettingPassword') || {}
});

export const mapDispatchResetPasswordToProps = {
	requestPasswordReset,
	resetPassword
};
