import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { mapDispatchCreateAccountToProps, selectCreateAccount } from '../../store/selectors/account';
import { mapDispatchCreateSessionToProps, selectCreateSession, selectSession } from '../../store/selectors/session';
import Headline from '../lib/headline';
import Link from '../lib/link';
import Modal from '../lib/modal';
import LoginForm from '../loginForm';
import SignupForm from '../signupForm';

import './loginSignupModal.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectSession,
			selectCreateSession,
			selectCreateAccount,
			(session, createSession, createAccount) => ({
				...session,
				...createSession,
				...createAccount
			}),
		),
		{
			...mapDispatchCreateSessionToProps,
			...mapDispatchCreateAccountToProps,
		}
	),
	withState('screen', 'setScreen', (props) => {
		if (props.initialScreen) {
			return props.initialScreen;
		}
		return 'login';
	}),
	withHandlers({
		handleLogin: (props) => (fields) => {
			return props.createUserSession(fields)
			.then((data) => {
				if (!data.error) {
					props.onComplete();
				}
				return data;
			});
		},
		handleSignup: (props) => (fields) => {
			return props.createUserAccount(fields)
			.then((data) => {
				if (!data.error) {
					props.onComplete();
				}
				return data;
			});
		}
	})
);

export function LoginSignupModal(props) {
	const {
		screen,
		onClose,
		onComplete,
		handleLogin,
		handleSignup,
		setScreen
	} = props;

	return (
		<Modal
			showing={true}
			onClose={onClose}
			className={classnames('login-signup-modal', props.className)}
		>
			<div className="login-signup-wrapper">
				{screen === 'login' ? (
					<>
						<Headline tag="h1" size={FONT_SIZES.xl4} weight={FONT_WEIGHTS.black}>
							Welcome back!
						</Headline>
						<LoginForm
							onSubmit={handleLogin}
						/>
						<div className="login-signup-switch-action">
							<Link to="#" onClick={() => setScreen('signup')} size={FONT_SIZES.sm}>
								Create an account
							</Link>
						</div>
					</>

				) : (
					<>
						<SignupForm
							onSubmit={handleSignup}
						/>
						<div className="login-signup-switch-action">
							<Link to="#" onClick={() => setScreen('login')} size={FONT_SIZES.sm}>
								Have an account? Log in
							</Link>
						</div>
					</>

				)}
			</div>
		</Modal>
	)
}

LoginSignupModal.propTypes = {
	initialScreen: propTypes.oneOf(['login', 'signup']),
	onClose: propTypes.func.isRequired,
	onComplete: propTypes.func.isRequired,
};

export default enhance(LoginSignupModal);
