import * as React from 'react';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import app from './app/reducers';
import session from './session/reducers';
import account from './account/reducers';
import files from './files/reducers';
import data from './data/reducers';
import community from './community/reducers';
import posts from './posts/reducers';
import moderation from './moderation/reducers';

export default combineReducers({
	app,
	session,
	account,
	files,
	data,
	community,
	posts,
	moderation,

	form: formReducer,
});
