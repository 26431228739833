import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { compose, lifecycle, mapProps, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import CreateCommunityPostForm from '../../components/forms/createCommunityPostForm';
import Headline from '../../components/lib/headline';
import Spinner from '../../components/lib/spinner';
import Page from '../../components/page';
import { withAttributedSegmentTracking } from '../../lib/analytics';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { buildPostUrl } from '../../lib/helpers';
import { selectApp, selectAssetUrl } from '../../store/selectors/app';
import {
	mapDispatchCommunityToProps,
	mapDispatchCreateCommunityPostToProps,
	selectCommunity,
	selectCreateCommunityPost
} from '../../store/selectors/community';

import './communityCreatePost.scss';

export const enhance = compose(
	withToastManager,
	mapProps((props) => ({
		...props,
		displaySlug: _.get(props, 'match.params.displaySlug')
	})),
	connect(
		createSelector(
			selectApp,
			selectAssetUrl,
			selectCommunity,
			selectCreateCommunityPost,
			(app, asset, community, createPost) => ({
				...app,
				...asset,
				...community,
				...createPost,
			})
		),
		{
			...mapDispatchCommunityToProps,
			...mapDispatchCreateCommunityPostToProps
		}
	),
	withAttributedSegmentTracking,
	withState('loadedCommunity', 'setLoadedCommunity', (props) => {
		return !!props.community;
	}),
	withHandlers({
		handleSubmitPost: (props) => (fields) => {
			const {
				community,
				toastManager,
				createCommunityPost,
				history,
			} = props;

			return createCommunityPost(community.id, fields)
			.then((data) => {
				const post = data.payload;
				if (data.error) {
					toastManager.add(`Failed to create post`, { appearance: 'error', autoDismiss: true });
					return data;
				}

				toastManager.add(`Post created!`, { appearance: 'success', autoDismiss: true });

				setTimeout(() => {
					history.push(buildPostUrl(community.displaySlug, post.slug, post.shortId));
				}, 1000);
			})
		}
	}),
	lifecycle({
		componentDidMount() {
			const {
				displaySlug,
				setLoadedCommunity,
				getCommunityBySlug,
				loadedCommunity,
				postsCursor
			} = this.props;

			if (!loadedCommunity) {
				getCommunityBySlug(displaySlug)
				.then((data) => {
					setLoadedCommunity(true);
					return data;
				});
			}
		}
	})
);

export function CommunityCreatePostPage(props) {
	const {
		loadedCommunity,
		community,
		creatingCommunityPost,
		handleSubmitPost
	} = props;
	return (
		<Page
			{...props}
			className={classnames('community-create-post-page')}
			withContent
			pageName="community"
		>
			{!!(!loadedCommunity || !community) ? (
				<Spinner />
			) : (
				<div className="create-post-wrapper">
					<Headline tag="h1" size={FONT_SIZES.xl} weight={FONT_WEIGHTS.bold}>
						Create post in /c/{community.displaySlug}
					</Headline>
					<CreateCommunityPostForm
						onSubmit={handleSubmitPost}
						loading={creatingCommunityPost.loading}
						formError={creatingCommunityPost.error}
					/>
				</div>
			)}
		</Page>
	);
}

export default enhance(CommunityCreatePostPage);
