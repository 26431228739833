import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import { createSelector } from 'reselect';
import CommunityList from '../../components/communityList';
import { CommunityNavigation } from '../../components/communityNavigation';
import HeadlineWrapper from '../../components/headlineWrapper';
import { Button } from '../../components/lib/button';
import Headline from '../../components/lib/headline';
import Link from '../../components/lib/link';
import Spinner from '../../components/lib/spinner';
import LoginSignupModal from '../../components/loginSignupModal';
import Page from '../../components/page';
import PostList from '../../components/postList';
import SidebarLoggedOut from '../../components/sidebarLoggedOut';
import { withAttributedSegmentTracking } from '../../lib/analytics';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { handleLoginSignupVote } from '../../lib/handlerHelpers';
import { selectApp, selectAssetUrl } from '../../store/selectors/app';
import { mapDispatchAllCommunitiesToProps, selectAllCommunities } from '../../store/selectors/community';
import {
	mapDispatchAllPostsToProps,
	mapDispatchUpsertPostVoteToProps,
	selectAllPosts,
	selectUpsertingPostVote
} from '../../store/selectors/posts';
import { selectSessionData } from '../../store/selectors/session';

import './home.scss';

const COMMUNITY_LIMIT = 25;

export const enhance = compose(
	withProps((props) => {
		return {
			...props,
			sort: _.get(props, 'route.sort') || 'hot'
		};
	}),
	connect(
		createSelector(
			selectApp,
			selectAssetUrl,
			selectSessionData,
			selectAllPosts,
			selectUpsertingPostVote,
			selectAllCommunities,
			(app, asset, session, allPosts, vote, communities) => ({
				...app,
				...asset,
				...session,
				...allPosts,
				...vote,
				...communities
			})
		),
		{
			...mapDispatchAllPostsToProps,
			...mapDispatchUpsertPostVoteToProps,
			...mapDispatchAllCommunitiesToProps
		}
	),
	withAttributedSegmentTracking,
	withState('loadedPosts', 'setLoadedPosts', false),
	withState('loadedCommunities', 'setLoadedCommunities', false),
	withState('showLoginSignupModal', 'setShowLoginSignupModal', false),
	withHandlers({
		handlePostVote: (props) => (post, vote) => {
			const { sessionData, upsertPostVote, setShowLoginSignupModal } = props;

			if (sessionData.hasSession) {
				return upsertPostVote(post.communityId, post.id, vote);
			}

			setShowLoginSignupModal({ action: 'vote', vote, post });
		},
	}),
	withHandlers({
		handleLoginSignup: handleLoginSignupVote
	}),
	lifecycle({
		componentDidMount() {
			const {
				setLoadedPosts,
				getAllPosts,
				listAllCommunities,
				setLoadedCommunities,
				sort
			} = this.props;

			getAllPosts({
				includeUser: true,
				includeCommunity: true,
				includeLink: true,
				sort
			}, { sort })
			.then((data) => {
				setLoadedPosts(true);
				return data;
			});

			listAllCommunities({ limit: COMMUNITY_LIMIT })
			.then((data) => {
				setLoadedCommunities(true);
				return data;
			});
		}
	})
);

export function Home(props) {
	const {
		loadedPosts,
		gettingAllPosts,
		posts,
		handlePostVote,
		sessionData,
		communities,
		loadedCommunities,
		listingAllCommunities,
		showLoginSignupModal,
		setShowLoginSignupModal,
		handleLoginSignup
	} = props;
	const createCommunityPath = '/communities/create';

	return (
		<Page
			{...props}
			className={classnames('home-page')}
			withContent
			pageName="home"
		>
			<div className="home-posts">
				<CommunityNavigation basePath="" />
				{!!(!loadedPosts || gettingAllPosts.loading) ? (
					<Spinner />
				) : (
					<PostList
						posts={posts}
						onVote={handlePostVote}
						sessionData={sessionData}
					/>
				)}
			</div>
			<div className="home-sidebar-container">
				{!sessionData.hasSession && (
					<SidebarLoggedOut />
				)}
				<HeadlineWrapper underline>
					<Headline tag="h3" size={FONT_SIZES.xl} weight={FONT_WEIGHTS.medium}>
						Explore Communities
					</Headline>
				</HeadlineWrapper>
				{listingAllCommunities.loading && (
					<Spinner />
				)}
				{loadedCommunities === true && (
					<>
						<CommunityList
							communities={communities}
						/>
						{(communities || []).length >= COMMUNITY_LIMIT && (
							<Link isNavLink to="/communities" className="view-all-communities">
								View all communities
							</Link>
						)}
					</>

				)}
				<div className="create-community-wrapper">
					<Button
						size="small"
						isLink
						href={sessionData.hasSession ? createCommunityPath : `/login?returnTo=${createCommunityPath}`}
					>
						Create your own Community
					</Button>
				</div>
			</div>
			{!!showLoginSignupModal && (
				<LoginSignupModal
					onClose={() => setShowLoginSignupModal(false)}
					onComplete={handleLoginSignup}
				/>
			)}
		</Page>
	);
}

export default enhance(Home);
