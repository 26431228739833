import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { normalizeCommunitySlug } from '../../lib/helpers';
import { getFailureAction, getRequestAction, getSuccessAction } from '../helpers';
import { MODERATION } from '../moderation/actions';
import { SESSION } from '../session/actions';
import { COMMUNITY } from './actions';

export const initialState = {
	creatingCommunity: {
		loading: false,
		error: null
	},
	communities: {},
	communityIdToSlugMap: {},
	communityPosts: {},
	gettingCommunityPosts: {
		loading: false,
		error: null
	},
	gettingCommunity: {
		loading: false,
		error: null
	},
	creatingCommunityPost: {
		loading: false,
		error: null
	},
	gettingCommunityPost: {
		loading: false,
		error: null
	},
	listingAllCommunities: {
		loading: false,
		error: null
	},
	allCommunityList: [],
	listingCommunityRules: {
		loading: false,
		error: null
	},
	updatingCommunity: {
		loading: false,
		error: null
	}
};

export default handleActions({
	// Create community
	[getRequestAction(COMMUNITY.CREATE_COMMUNITY)]: (state, { payload, options }) => {
		return {
			...state,
			creatingCommunity: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(COMMUNITY.CREATE_COMMUNITY)]: (state, { payload, options }) => {
		return {
			...state,
			creatingCommunity: {
				loading: false,
				error: null
			}
		};
	},
	[getFailureAction(COMMUNITY.CREATE_COMMUNITY)]: (state, { error, options }) => {
		return {
			...state,
			creatingCommunity: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Get community and posts
	[getRequestAction(COMMUNITY.GET_COMMUNITY_POSTS_BY_SLUG)]: (state, { payload, options }) => {
		return {
			...state,
			gettingCommunityPosts: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(COMMUNITY.GET_COMMUNITY_POSTS_BY_SLUG)]: (state, { payload, options }) => {
		const normalizedSlug = normalizeCommunitySlug(options.displaySlug);
		return {
			...state,
			gettingCommunityPosts: {
				loading: false,
				error: null
			},
			communities: {
				...(state.communities || {}),
				[normalizedSlug]: payload.community
			},
			communityIdToSlugMap: {
				...state.communityIdToSlugMap,
				[payload.community.id]: normalizedSlug
			},
			communityPosts: {
				...(state.communityPosts || {}),
				[normalizedSlug]: {
					cursor: payload.cursor,
					sort: {
						..._.get(state, `communityPosts[${normalizedSlug}].sort`) || {},
						[options.sort]: [
							...(_.get(state, `communityPosts[${normalizedSlug}].sort[${options.sort}]`)) || [],
							..._.map(payload.posts, (p) => p.id)
						]
					}
				}
			}
		};
	},
	[getFailureAction(COMMUNITY.GET_COMMUNITY_POSTS_BY_SLUG)]: (state, { error, options }) => {
		return {
			...state,
			gettingCommunityPosts: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Get community
	[getRequestAction(COMMUNITY.GET_COMMUNITY_BY_SLUG)]: (state, { payload, options }) => {
		return {
			...state,
			gettingCommunity: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(COMMUNITY.GET_COMMUNITY_BY_SLUG)]: (state, { payload, options }) => {
		const normalizedSlug = normalizeCommunitySlug(options.displaySlug);
		return {
			...state,
			gettingCommunity: {
				loading: false,
				error: null
			},
			communities: {
				...(state.communities || {}),
				[normalizedSlug]: payload
			}
		};
	},
	[getFailureAction(COMMUNITY.GET_COMMUNITY_BY_SLUG)]: (state, { error, options }) => {
		return {
			...state,
			gettingCommunity: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Create community post
	[getRequestAction(COMMUNITY.CREATE_COMMUNITY_POST)]: (state, { payload, options }) => {
		return {
			...state,
			creatingCommunityPost: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(COMMUNITY.CREATE_COMMUNITY_POST)]: (state, { payload, options }) => {
		return {
			...state,
			creatingCommunityPost: {
				loading: false,
				error: null
			},
		};
	},
	[getFailureAction(COMMUNITY.CREATE_COMMUNITY_POST)]: (state, { error, options }) => {
		return {
			...state,
			creatingCommunityPost: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Get community post
	[getRequestAction(COMMUNITY.GET_COMMUNITY_POST)]: (state, { payload, options }) => {
		return {
			...state,
			gettingCommunityPost: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(COMMUNITY.GET_COMMUNITY_POST)]: (state, { payload, options }) => {
		const normalizedSlug = normalizeCommunitySlug(options.displaySlug);
		return {
			...state,
			gettingCommunityPost: {
				loading: false,
				error: null
			},
			communities: {
				...(state.communities || {}),
				[normalizedSlug]: payload.community
			},
			communityIdToSlugMap: {
				...state.communityIdToSlugMap,
				[payload.community.id]: normalizedSlug
			},
		};
	},
	[getFailureAction(COMMUNITY.GET_COMMUNITY_POST)]: (state, { error, options }) => {
		return {
			...state,
			gettingCommunityPost: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Increment subscriptionCount when joining a community
	[getSuccessAction(SESSION.CREATE_SUBSCRIPTION)]: (state, { payload, options }) => {
		const { communityId } = payload;
		const normalizedSlug = _.get(state, `communityIdToSlugMap[${communityId}]`);

		if (!normalizedSlug) {
			return { ...state };
		}

		const currentCommunity = _.get(state, `communities[${normalizedSlug}]`);

		if (!currentCommunity) {
			return { ...state };
		}

		const subscriberCount = parseInt(currentCommunity.subscriberCount || '0');
		currentCommunity.subscriberCount = subscriberCount + 1;

		return {
			...state,
			communities: {
				...state.communities,
				[normalizedSlug]: {
					...currentCommunity
				}
			}
		};
	},
	// Decrement subscriptionCount when leaving a community
	[getSuccessAction(SESSION.DELETE_SUBSCRIPTION)]: (state, { payload, options }) => {
		const { communityId } = payload;
		const normalizedSlug = _.get(state, `communityIdToSlugMap[${communityId}]`);

		if (!normalizedSlug) {
			return { ...state };
		}

		const currentCommunity = _.get(state, `communities[${normalizedSlug}]`);

		if (!currentCommunity) {
			return { ...state };
		}

		const subscriberCount = parseInt(currentCommunity.subscriberCount || '0');
		currentCommunity.subscriberCount = subscriberCount - 1;

		return {
			...state,
			communities: {
				...state.communities,
				[normalizedSlug]: {
					...currentCommunity
				}
			}
		};
	},
	// Create community
	[getRequestAction(COMMUNITY.LIST_ALL_COMMUNITIES)]: (state, { payload, options }) => {
		return {
			...state,
			listingAllCommunities: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(COMMUNITY.LIST_ALL_COMMUNITIES)]: (state, { payload, options }) => {
		return {
			...state,
			listingAllCommunities: {
				loading: false,
				error: null
			},
			allCommunityList: _.uniqBy([
				...(_.get(state, 'allCommunityList') || []),
				...payload.communities
			], (c) => c.id)
		};
	},
	[getFailureAction(COMMUNITY.LIST_ALL_COMMUNITIES)]: (state, { error, options }) => {
		return {
			...state,
			listingAllCommunities: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// List community rules
	[getRequestAction(COMMUNITY.LIST_COMMUNITY_RULES)]: (state, { payload, options }) => {
		return {
			...state,
			listingCommunityRules: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(COMMUNITY.LIST_COMMUNITY_RULES)]: (state, { payload, options }) => {
		const { communityId, displaySlug } = options;

		const normalizedSlug = communityId ? _.get(state, `communityIdToSlugMap[${communityId}]`) : normalizeCommunitySlug(displaySlug);

		if (!normalizedSlug) {
			return { ...state };
		}

		const currentCommunity = _.get(state, `communities[${normalizedSlug}]`);
		if (!currentCommunity) {
			return { ...state };
		}

		return {
			...state,
			listingCommunityRules: {
				loading: false,
				error: null,
			},
			communities: {
				...state.communities,
				[normalizedSlug]: {
					...currentCommunity,
					rules: payload.rules
				}
			}
		};
	},
	[getFailureAction(COMMUNITY.LIST_COMMUNITY_RULES)]: (state, { error, options }) => {
		return {
			...state,
			listingCommunityRules: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// List community rules
	[getRequestAction(COMMUNITY.UPDATE_COMMUNITY)]: (state, { payload, options }) => {
		return {
			...state,
			updatingCommunity: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(COMMUNITY.UPDATE_COMMUNITY)]: (state, { payload, options }) => {
		const { displaySlug } = payload;

		const normalizedSlug = normalizeCommunitySlug(displaySlug);

		if (!normalizedSlug) {
			return { ...state };
		}

		const currentCommunity = _.get(state, `communities[${normalizedSlug}]`);
		if (!currentCommunity) {
			console.log('community not found');
			return { ...state };
		}

		return {
			...state,
			updatingCommunity: {
				loading: false,
				error: null,
			},
			communities: {
				...state.communities,
				[normalizedSlug]: {
					...currentCommunity,
					...payload
				}
			}
		};
	},
	[getFailureAction(COMMUNITY.UPDATE_COMMUNITY)]: (state, { error, options }) => {
		return {
			...state,
			updatingCommunity: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Moderate: remove post
	[getSuccessAction(MODERATION.REMOVE_POST)]: (state, { payload, options }) => {
		const { postId, communityId } = options;
		const slug = normalizeCommunitySlug(_.get(state, `communityIdToSlugMap[${communityId}]`));

		return {
			...state,
			communityPosts: {
				...(state.communityPosts || {}),
				[slug]: {
					cursor: payload.cursor,
					postIds: _.filter(_.get(state, `communityPosts[${slug}].postIds`), (id) => {
						return id !== postId;
					}),
					sort: {
						..._.transform(_.get(state, `communityPosts[${normalizedSlug}].sort`), (result, value, key) => {
							result[key] = _.filter(_.get(state, `communityPosts[${slug}].sort[${key}]`), (id) => {
								return id !== postId;
							})
							return result;
						}),
					}
				}
			}
		};
	},
}, initialState);
