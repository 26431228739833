import React from 'react';
import classnames from 'classnames';

import './form.scss';

export function Form(props) {
	const { className } = props;

	return (
		<form {...props} className={classnames('form', className)} />
	);
}

export default Form;