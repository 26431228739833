import _ from 'lodash';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, getContext, withContext, withHandlers, withState } from 'recompose';
import { selectSessionAttribution } from '../../store/selectors/session';
import { hasSegment } from '../helpers';

export const ANALYTICS_OMIT_PROPS = [
	'segmentContext',
	'segmentConfig',
	'pageContext',
	'setOnClick',
	'setOnSubmit'
];

export const SEGMENT_CONTEXT_TYPE = {
	pageName: propTypes.string,
	pagePath: propTypes.string,
	pageUrlParams: propTypes.arrayOf(propTypes.string),
	containerName: propTypes.string,
	metadata: propTypes.object
};

export const SEGMENT_CONFIG_TYPE = {
	label: propTypes.string,
	action: propTypes.oneOf(['click', 'impression', 'submit']),
	targetType: propTypes.oneOf(['button', 'link', 'page', 'component', 'form', 'choice picker', propTypes.any]),
	metadata: propTypes.object
};

export const SEGMENT_CONTEXT_PROPS = {
	segmentContext: propTypes.shape(SEGMENT_CONTEXT_TYPE)
};

const defaultSegmentContextValues = {
	action: null,
	targetType: null,
	label: null,
	pageName: null,
	containerName: null,
	metadata: null
};

export const provideSegmentContext = withContext(SEGMENT_CONTEXT_PROPS, (props) => {
	if (!props.segmentContext) {
		console.warn('Expected to receive segmentContext as prop but did not');
	}
	return {
		segmentContext: props.segmentContext || { ...defaultSegmentContextValues }
	};
});

export const SegmentContextProvider = provideSegmentContext(({ children }) => children);

SegmentContextProvider.propTypes = { ...SEGMENT_CONTEXT_PROPS };

// retrieve the segment context
export const withSegmentContext = () => compose(
	getContext(SEGMENT_CONTEXT_PROPS),
	getContext(PAGE_CONTEXT_PROPS)
);


function mergeSegmentContextConfig(props) {
	return {
		...(props.pageContext || {}),
		...(props.segmentContext || {}),
		...(props.segmentConfig || {}),
		metadata: {
			...(_.get(props, 'segmentContext.metadata') || {}),
			...(_.get(props, 'segmentConfig.metadata') || {})
		}
	};
}

export const withSegmentClickInterceptor = () => compose(
	withAttributedSegmentTracking,
	withState('onClick', 'setOnClick', (props) => {
		const oldOnClick = props.onClick;
		return (evt) => {
			const mergedSegmentContext = mergeSegmentContextConfig(props);
			props.segmentTrack(mergedSegmentContext.action, {
				..._.omit(mergedSegmentContext, ['action'])
			});
			// do stuff
			if (oldOnClick) {
				oldOnClick(evt);
			}
		};
	})
);

export const withSegmentSubmitInterceptor = () => compose(
	withAttributedSegmentTracking,
	withState('onSubmit', 'setOnSubmit', (props) => {
		const oldOnSubmit = props.onSubmit;
		const mergedSegmentContext = mergeSegmentContextConfig(props);
		return (evt) => {
			props.segmentTrack('submit', { ...mergedSegmentContext });

			const onSubmitResult = oldOnSubmit(evt);
			if (onSubmitResult && onSubmitResult.then) {
				return onSubmitResult
				.then((data) => {
					props.segmentTrack('submit-response', {
						...mergedSegmentContext,
						metadata: {
							...mergedSegmentContext.metadata,
							success: !_.get(data, 'error')
						}
					});
					return data;
				});
			}
			return onSubmitResult;
		};
	})
);

// Page context
export const PAGE_CONTEXT_TYPE = {
	pageName: propTypes.string,
	pagePath: propTypes.string,
	pageUrlParams: propTypes.object,
};
export const PAGE_CONTEXT_PROPS = {
	pageContext: propTypes.shape(PAGE_CONTEXT_TYPE)
};

const defaultPageContextValues = {
	pageName: null,
	pagePath: null,
	pageUrlParams: {}
};

export const providePageContext = withContext(PAGE_CONTEXT_PROPS, (props) => {
	if (!props.pageContext) {
		console.warn('Expected to receive pageContext as prop but did not');
	}
	return {
		pageContext: props.pageContext || { ...defaultPageContextValues }
	};
});


export const ATTRIBUTED_SEGMENT_OMIT_PROPS = [
	'segmentTrack',
	'segmentPage',
	'visitorId',
	'visitId',
	'dispatch'
];

export const withAttributedSegmentTracking = compose(
// 	connect(selectSessionAttribution),
	withHandlers({
		segmentTrack: (props) => (eventName, attributes = {}, ...args) => {
			hasSegment(() => {
				window.analytics.track(eventName, {
					...attributes,
					// visitId: props.visitId,
					// visitorId: props.visitorId
				}, ...args);
			});
		},
		segmentPage: (props) => (attributes = {}, ...args) => {
			hasSegment(() => {
				window.analytics.page({
					...attributes,
					// visitId: props.visitId,
					// visitorId: props.visitorId
				}, ...args);
			});
		},
	})
);
