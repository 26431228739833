import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withState } from 'recompose';
import { createSelector } from 'reselect';
import CommunityList from '../../components/communityList';
import HeadlineWrapper from '../../components/headlineWrapper';
import Headline from '../../components/lib/headline';
import Page from '../../components/page';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { selectApp, selectAssetUrl } from '../../store/selectors/app';
import { mapDispatchAllCommunitiesToProps, selectAllCommunities } from '../../store/selectors/community';
import { selectSession } from '../../store/selectors/session';

import './communities.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectApp,
			selectAssetUrl,
			selectSession,
			selectAllCommunities,
			(app, asset, session, communities) => ({
				...app,
				...asset,
				...session,
				...communities
			})
		),
		{
			...mapDispatchAllCommunitiesToProps,
		}
	),
	withState('loadedCommunities', 'setLoadedCommunities', (props) => {
		return !!props.community;
	}),
	lifecycle({
		componentDidMount() {
			const {
				listAllCommunities,
				setLoadedCommunities,
			} = this.props;

			listAllCommunities({ limit: 'all' })
			.then((data) => {
				setLoadedCommunities(true);
				return data;
			});
		}
	})
);

export function CommunitiesPage(props) {
	const {
		communities,
		loadedCommunities,
		sessionData,
	} = props;

	const sortedCommunities = _.sortBy(communities, (c) => c.displaySlug);

	return (
		<Page
			{...props}
			className={classnames('communities-page')}
			withContent
			pageName="communities"
		>
			<HeadlineWrapper underline>
				<Headline tag="h1" size={FONT_SIZES.xl3} weight={FONT_WEIGHTS.bold}>
					Klique Communities
				</Headline>
			</HeadlineWrapper>
			{loadedCommunities === true && (
				<CommunityList communities={sortedCommunities} />
			)}
		</Page>
	);
}

export default enhance(CommunitiesPage);
