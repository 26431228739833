import _ from 'lodash';
import { crawlUrl } from '../data/actions';

export const selectCrawlUrl = (state, props) => ({
	crawlingUrl: _.get(state.data, 'crawlingUrl') || {}
});

export const mapDispatchCrawlUrlToProps = {
	crawlUrl
};

