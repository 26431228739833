import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { getFailureAction, getRequestAction, getSuccessAction } from '../helpers';
import { MODERATION } from './actions';

export const initialState = {
	removingComment: {
		loading: false,
		error: null
	},
	removingPost: {
		loading: false,
		error: null
	}
};

export default handleActions({
	// Remove comment
	[getRequestAction(MODERATION.REMOVE_COMMENT)]: (state, { payload, options }) => {
		return {
			...state,
			removingComment: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(MODERATION.REMOVE_COMMENT)]: (state, { payload, options }) => {
		return {
			...state,
			removingComment: {
				loading: false,
				error: null,
			},
		};
	},
	[getFailureAction(MODERATION.REMOVE_COMMENT)]: (state, { error, options }) => {
		return {
			...state,
			removingComment: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Remove post
	[getRequestAction(MODERATION.REMOVE_POST)]: (state, { payload, options }) => {
		return {
			...state,
			removingPost: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(MODERATION.REMOVE_POST)]: (state, { payload, options }) => {
		return {
			...state,
			removingPost: {
				loading: false,
				error: null,
			},
		};
	},
	[getFailureAction(MODERATION.REMOVE_POST)]: (state, { error, options }) => {
		return {
			...state,
			removingPost: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
}, initialState);
