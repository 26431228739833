import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import Link from '../lib/link';

import './communityNavigation.scss';

export function CommunityNavigation(props) {
	const {
		basePath
	} = props;
	return (
		<div className={classnames('community-navigation', props.className)}>
			<Link
				className="community-navigation-item"
				to={basePath}
				isNavLink
			>
				Hot
			</Link>
			<Link
				className="community-navigation-item"
				to={`${basePath}/new`}
				isNavLink
			>
				New
			</Link>
		</div>
	);
}

CommunityNavigation.propTypes = {
	basePath: propTypes.string.isRequired
}
