import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose, mapProps, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import Card from '../../components/lib/card';
import Link from '../../components/lib/link';
import Paragraph from '../../components/lib/paragraph';
import Page from '../../components/page';
import PasswordResetForm from '../../components/passwordResetForm';

import { parseQueryParams, } from '../../lib/helpers';
import { mapDispatchResetPasswordToProps, selectResetPassword } from '../../store/selectors/account';

import './resetPasswordPage.scss';

export const enhance = compose(
	mapProps((props) => ({
		...props,
		queryParams: parseQueryParams(props.location, { base64Fields: ['email'] })
	})),
	mapProps((props) => ({
		...props,
		email: props.queryParams.email
	})),
	connect(
		createSelector(
			selectResetPassword,
			(reset) => ({
				...reset,
			})
		),
		mapDispatchResetPasswordToProps
	),
	withState('passwordReset', 'setPasswordReset', false),
	withHandlers({
		handleResetPassword: (props) => (fields) => {
			return props.resetPassword(props.match.params.token, {
				...fields,
				email: props.email
			})
			.then((data) => {
				if (!data.error) {
					props.setPasswordReset(true);
				}
				return data;
			});
		},
	}),
);

export function ResetPasswordPage(props) {
	const { handleResetPassword, resettingPassword, passwordReset } = props;
	return (
		<Page
			{...props}
			className={classnames('reset-password-page')}
			withContent
			pageName="resetPassword"
			title="Reset Password"
		>
			<Card>
				{passwordReset ? (
					<>
						<Paragraph>
							Your password has been reset.
						</Paragraph>
						<Link to="/login">
							Please login to continue
						</Link>
					</>
				) : (
					<PasswordResetForm
						onSubmit={handleResetPassword}
						loading={resettingPassword.loading}
						formError={resettingPassword.error}
					/>
				)}
			</Card>
		</Page>
	);
}

export default enhance(ResetPasswordPage);
