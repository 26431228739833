import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { hasValidEmail, renderInput } from '../../lib/formHelpers';
import { Button } from '../lib/button';
import Form from '../lib/form';
import { ButtonRow, FormItem, FormRow } from '../lib/formRow';

import './changeEmailForm.scss';

const FORM_NAME = 'change-email-form';

export const enhance = compose(
	reduxForm({ form: FORM_NAME, enableReinitialize: true }),
	connect(
		createSelector(
			(state) => ({ formValues: getFormValues(FORM_NAME)(state) }),
			(formValues) => ({
				...formValues
			})
		)
	),
	withHandlers({
		interceptSubmit: (props) => (fields) => {
			return props.onSubmit(fields);
		},
	})
);

export function ChangeEmailForm(props) {
	const {
		interceptSubmit,
		handleSubmit,
		valid,
		loading,
		formError,
		hasEmail
	} = props;
	const buttonProps = {
		loading: loading,
		size: 'regular'
	};
	if (!valid) {
		buttonProps.disabled = true;
	}
	return (
		<Form onSubmit={handleSubmit(interceptSubmit)} className={classnames('change-email-form', props.className)}>
			{hasEmail && (
				<FormRow>
					<FormItem>
						<Field
							name="currentEmail"
							type="text"
							label="Current Email"
							component={renderInput}
							readOnly={true}
						/>
					</FormItem>
				</FormRow>
			)}
			<FormRow>
				<FormItem>
					<Field
						name="email"
						type="text"
						label="New Email"
						component={renderInput}
						validate={[hasValidEmail]}
						isRequired={true}
					/>
				</FormItem>
			</FormRow>
			<ButtonRow>
				<Button {...buttonProps} size="small">
					Save
				</Button>
			</ButtonRow>
			{!!(formError && formError.message) && (
				<FormRow>
					<FormItem>
						<div className="form-formError-message">
							{formError.message}
						</div>
					</FormItem>
				</FormRow>
			)}
		</Form>
	);
}

ChangeEmailForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
	loading: propTypes.bool,
	formError: propTypes.any,
	hasEmail: propTypes.bool,
};

export default enhance(ChangeEmailForm);
