import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { hasPassword, renderInput } from '../../lib/formHelpers';
import { selectCreateSession } from '../../store/selectors/session';
import { Button } from '../lib/button';
import ErrorMessage from '../lib/errorMessage';
import Form from '../lib/form';
import { ButtonRow, FormItem, FormRow } from '../lib/formRow';

import Link from '../lib/link';

import './loginForm.scss';

export const enhance = compose(
	reduxForm({
		form: 'login-form',
		fields: ['email', 'password']
	}),
	connect(selectCreateSession),
);

const hasValidEmailOrUsername = (val) => {
	const trimmedVal = _.trim(val);
	if (trimmedVal.indexOf('@') >= 0){
		if (!val || val.split('@').length !== 2) {
			return 'Please enter a valid email address or username';
		}
	}

	if (!trimmedVal.length) {
		return 'Please enter a valid email address or username';
	}
}

export function LoginForm(props) {
	const {
		handleSubmit,
		valid,
		anyTouched,
		onSubmit,
		createSession,
	} = props;
	const buttonProps = {
		loading: createSession.loading,
		size: 'regular'
	};
	if (!valid || !anyTouched) {
		buttonProps.disabled = true;
	}
	return (
		<div className={classnames('login-form')}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<FormRow>
					<FormItem>
						<Field
							name="emailOrUsername"
							type="text"
							label="Email or Username"
							component={renderInput}
							validate={[hasValidEmailOrUsername]}
							isRequired
						/>
					</FormItem>
				</FormRow>
				<FormRow>
					<FormItem>
						<Field
							name="password"
							type="password"
							label="Password"
							component={renderInput}
							validate={[hasPassword]}
							isRequired
						/>
					</FormItem>
				</FormRow>
				<ButtonRow>
					<Button {...buttonProps} size="small">
						Login
					</Button>
				</ButtonRow>
				{createSession.error && createSession.error.message ? (
					<FormRow>
						<ErrorMessage>
							{createSession.error.message}
						</ErrorMessage>
					</FormRow>
				) : null}
				<FormRow className="text-links password-reset">
					<FormItem>
						<Link to={'/password-reset'} linkStyle="gray" size="sm">
							Forgot your password?
						</Link>
					</FormItem>
				</FormRow>
				<FormRow className="text-links create-account">
					<FormItem>
						<Link to={'/signup'} linkStyle="gray" size="sm">
							Don't have an account? Sign up
						</Link>
					</FormItem>
				</FormRow>
			</Form>
		</div>
	);
}

LoginForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
};

export default enhance(LoginForm);
