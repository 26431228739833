import classnames from 'classnames';
import React from 'react';
import Heroicon from '../lib/heroicon';
import Link from '../lib/link';
import propTypes from 'prop-types';

import './authenticatedHeaderContent.scss';

export function AuthenticatedHeaderContent(props) {
	const { sessionData } = props;

	return (
		<div className={classnames('authenticated-header-content', props.className)}>
			<div className="content-item user">
				<Link to={`/users/${sessionData.username}`} isNavLink={true}>
					{_.get(sessionData, 'username')}
				</Link>
			</div>
			<div className="content-item mail">
				<Link to={`/inbox`} isNavLink={true}>
					<Heroicon icon="Envelope" />
				</Link>
			</div>
			<div className="content-item">
				<Link to="/account/settings" isNavLink={true}>
					settings
				</Link>
			</div>
			<div className="content-item">
				<Link to="/logout" isNavLink={true}>
					log out
				</Link>
			</div>
		</div>
	);
}

AuthenticatedHeaderContent.propTypes = {
	sessionData: propTypes.object
}

export default AuthenticatedHeaderContent;
