import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import './errorMessage.scss';
import { buildModifierClassFromProps } from '../../../lib/helpers';

const modifierProps = ['size'];

function ErrorMessage(props) {
	return (
		<div className={classnames('error-message', props.className, ...buildModifierClassFromProps(modifierProps, props))}>
			{props.children}
		</div>
	);
}

ErrorMessage.propTypes = {
	size: propTypes.oneOf(['small', 'regular', 'medium', 'large']),
	children: propTypes.any.isRequired
};

ErrorMessage.defaultProps = {
	size: 'regular'
}

export default ErrorMessage;
