import React from 'react';
import { withAppWrapper } from '../components/appWrapper';
import { withTracker } from '../components/lib/gaTracker';
import Account from '../pages/account';
import ComingSoonPage from '../pages/comingSoon';
import CommunitiesPage from '../pages/communities';
import CommunityPage from '../pages/community';
import CommunityCreatePost from '../pages/communityCreatePost';
import CommunityPostPage from '../pages/communityPost';
import CreateCommunityPage from '../pages/createCommunity';
import Error from '../pages/error';
import Home from '../pages/home';
import Login from '../pages/login';
import LogoutPage from '../pages/logout';
import ModerateCommunityPage from '../pages/moderateCommunity';
import NotFound from '../pages/notFound';
import RequestResetPasswordPage from '../pages/requestResetPassword';
import ResetPasswordPage from '../pages/resetPassword';
import Signup from '../pages/signup';

export const staticRoutes = [
	{
		path: '/login',
		exact: true,
		component: withTracker(withAppWrapper(Login)),
		actions: { },
		loadData: (props) => { },
		canonicalLink: '/login'
	}, {
		path: '/signup',
		exact: true,
		component: withTracker(withAppWrapper(Signup)),
		actions: { },
		loadData: (props) => { },
		canonicalLink: '/signup'
	}, {
		path: '/password-reset',
		exact: true,
		component: withTracker(withAppWrapper(RequestResetPasswordPage)),
		actions: { },
		loadData: (props) => { },
	}, {
		path: '/password-reset/:token',
		exact: true,
		component: withTracker(withAppWrapper(ResetPasswordPage)),
		actions: { },
		loadData: (props) => { },
	},
	// {
	// 	path: '/account-verification',
	// 	exact: true,
	// 	component: withTracker(withAppWrapper(RequestVerificationLinkPage),
	// 	actions: { },
	// 	loadData: (props) => { },
	// 	canonicalLink: '/account-verification'
	// }
	/*{
		path: '/',
		component: AppWrapper,
		actions: {},
		loadData: () => {},
		routes: [

		]
	}*/
	{
		path: '/',
		exact: true,
		component: withTracker(withAppWrapper(Home)),
		actions: { },
		loadData: (props) => { },
		canonicalLink: '/',
		name: 'home',
		sort: 'hot'
	}, {
		path: '/new',
		exact: true,
		component: withTracker(withAppWrapper(Home)),
		actions: { },
		loadData: (props) => { },
		canonicalLink: '/',
		name: 'home',
		sort: 'new'
	},
	{
		path: '/communities',
		exact: true,
		component: withTracker(withAppWrapper(CommunitiesPage)),
		actions: { },
		loadData: (props) => { },
		rules: {}
	},
	// ------------------------------------------------------------------------
	// Community pages
	//------------------------------------------------------------------------
	{
		path: '/c/:displaySlug',
		exact: true,
		component: withTracker(withAppWrapper(CommunityPage)),
		actions: { },
		loadData: (props) => { },
		rules: {},
		name: 'community'
	}, {
		path: '/c/:displaySlug/create-post',
		exact: true,
		component: withTracker(withAppWrapper(CommunityCreatePost)),
		actions: { },
		loadData: (props) => { },
		rules: {},
		name: 'community-create-post'
	}, {
		path: '/c/:displaySlug/post/:postSlug/:postShortId',
		exact: true,
		component: withTracker(withAppWrapper(CommunityPostPage)),
		actions: { },
		loadData: (props) => { },
		rules: {},
		name: 'community-post'
	}, {
		path: '/c/:displaySlug/mod',
		exact: true,
		component: withTracker(withAppWrapper(ModerateCommunityPage)),
		actions: { },
		loadData: (props) => { },
		rules: {
			requiresAuthentication: true
		},
		name: 'moderate-community'
	}, {
		path: '/c/:displaySlug/:sort',
		exact: true,
		component: withTracker(withAppWrapper(CommunityPage)),
		actions: { },
		loadData: (props) => { },
		rules: {},
		name: 'community'
	},
	// ------------------------------------------------------------------------
	// User pages
	//------------------------------------------------------------------------
	{
		path: '/users/:username',
		exact: true,
		component: withTracker(withAppWrapper(ComingSoonPage)),
		actions: { },
		loadData: (props) => { },
		rules: {},
		name: 'user-profile'
	},
	// ------------------------------------------------------------------------
	// Authenticated pages
	//------------------------------------------------------------------------
	{
		path: '/communities/create',
		exact: true,
		component: withTracker(withAppWrapper(CreateCommunityPage)),
		actions: { },
		loadData: (props) => { },
		rules: {
			requiresAuthentication: true,
		}
	}, {
		path: '/account/settings',
		exact: true,
		component: withTracker(withAppWrapper(Account)),
		actions: { },
		loadData: (props) => { },
		rules: {
			requiresAuthentication: true,
		}
	}, {
		path: '/inbox',
		exact: true,
		component: withTracker(withAppWrapper(ComingSoonPage)),
		actions: { },
		loadData: (props) => { },
		rules: {
			requiresAuthentication: true,
		}
	}, {
		path: '/logout',
		exact: true,
		component: withTracker(withAppWrapper(LogoutPage)),
		actions: { },
		loadData: (props) => {},
	},
	// Error page (goes last)
	{
		path: '/error',
		exact: true,
		component: withTracker(withAppWrapper(Error)),
		actions: { },
		loadData: (props) => {}
	}, {
		path: '*',
		component: withTracker(withAppWrapper(NotFound)),
		actions: {},
		loadData: () => {}
	}
];

export default function Routes() {}
