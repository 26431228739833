import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { compose, lifecycle, mapProps, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import CommunitySidebar from '../../components/communitySidebar';
import UpdateCommunityPostForm from '../../components/forms/updateCommunityPostForm';
import Headline from '../../components/lib/headline';
import Heroicon from '../../components/lib/heroicon';
import Modal from '../../components/lib/modal';
import Spinner from '../../components/lib/spinner';
import LoginSignupModal from '../../components/loginSignupModal';
import NotificationBanner from '../../components/notificationBanner';
import Page from '../../components/page';
import Post from '../../components/posts/post';
import PostComments from '../../components/posts/postComments';
import PostContent from '../../components/posts/postContent';
import RestrictedCommunity from '../../components/restrictedCommunity';
import { withAttributedSegmentTracking } from '../../lib/analytics';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { handleLoginSignupVote } from '../../lib/handlerHelpers';
import { selectApp, selectAssetUrl } from '../../store/selectors/app';
import { mapDispatchCommunityPostToProps, selectCommunityPost } from '../../store/selectors/community';
import {
	mapDispatchModerateCommentToProps, mapDispatchModeratePostToProps,
	selectModerateComment,
	selectModeratePost
} from '../../store/selectors/moderation';
import {
	mapDispatchCreatePostCommentToProps,
	mapDispatchEditPostCommentToProps,
	mapDispatchGetPostCommentsToProps,
	mapDispatchUpdatePostContentToProps,
	mapDispatchUpsertCommentVoteToProps,
	mapDispatchUpsertPostVoteToProps,
	selectCreatePostComment,
	selectEditPostComment,
	selectPostComments,
	selectUpdatePostContent,
	selectUpsertingCommentVote
} from '../../store/selectors/posts';
import { selectSession } from '../../store/selectors/session';

import './communityPost.scss';

export const enhance = compose(
	withToastManager,
	mapProps((props) => ({
		...props,
		displaySlug: _.get(props, 'match.params.displaySlug'),
		postSlug: _.get(props, 'match.params.postSlug'),
		postShortId: _.get(props, 'match.params.postShortId'),
	})),
	connect(
		createSelector(
			selectApp,
			selectAssetUrl,
			selectSession,
			selectCommunityPost,
			selectCreatePostComment,
			selectPostComments,
			selectUpsertingCommentVote,
			selectUpdatePostContent,
			selectEditPostComment,
			selectModerateComment,
			selectModeratePost,
			(
				app, asset, session, community, comment, postComments, commentVote, updatePost, editComment,
				moderateComment, moderatePost,
			) => ({
				...app,
				...asset,
				...session,
				...community,
				...comment,
				...postComments,
				...commentVote,
				...updatePost,
				...editComment,
				...moderateComment,
				...moderatePost
			})
		),
		{
			...mapDispatchCommunityPostToProps,
			...mapDispatchCreatePostCommentToProps,
			...mapDispatchGetPostCommentsToProps,
			...mapDispatchUpsertPostVoteToProps,
			...mapDispatchUpsertCommentVoteToProps,
			...mapDispatchUpdatePostContentToProps,
			...mapDispatchEditPostCommentToProps,
			...mapDispatchModerateCommentToProps,
			...mapDispatchModeratePostToProps
		}
	),
	withAttributedSegmentTracking,
	withState('loadedCommunity', 'setLoadedCommunity', (props) => {
		return !!props.community && !!props.post;
	}),
	withState('loadedComments', 'setLoadedComments', false),
	withState('showLoginSignupModal', 'setShowLoginSignupModal', false),
	withState('showEditPost', 'setShowEditPost', false),
	withHandlers({
		handleAddComment: (props) => (fields) => {
			const {
				sessionData,
				createPostComment,
				community,
				post,
				toastManager
			} = props;

			if (!sessionData.hasSession) {
				toastManager.add(`You must be logged in to leave a comment`, { appearance: 'error', autoDismiss: true });
				return;
			}

			return createPostComment(community.id, post.id, fields)
			.then((data) => {
				if (data.error) {
					toastManager.add(`There was a problem creating your post`, { appearance: 'error', autoDismiss: true });
					return data;
				}

				toastManager.add(`Comment created!`, { appearance: 'success', autoDismiss: true });
				return data;
			});
		},
		handlePostVote: (props) => (post, vote) => {
			const { sessionData, upsertPostVote, setShowLoginSignupModal } = props;
			if (sessionData.hasSession) {
				return upsertPostVote(post.communityId, post.id, vote);
			}
			setShowLoginSignupModal({ action: 'vote', post, vote });
		},
		handleCommentVote: (props) => (commentId, vote) => {
			const { sessionData, upsertCommentVote, setShowLoginSignupModal, post } = props;
			if (sessionData.hasSession) {
				return upsertCommentVote(post.id, commentId, vote);
			}
			setShowLoginSignupModal({ action: 'commentVote', postId: post.id, commentId, vote });
		},
	}),
	withHandlers({
		handleLoginSignup: handleLoginSignupVote,
		handleUpdatePostContent: (props) => (fields) => {
			const {
				updatePostContent,
				post,
				sessionData,
				setShowEditPost,
				toastManager
			} = props;

			return updatePostContent(sessionData.id, post.id, fields)
			.then((data) => {
				if (data.error) {
					toastManager.add(`There was a problem updating your post`, { appearance: 'error', autoDismiss: true });
					return data;
				}
				setShowEditPost(false);
				toastManager.add(`Post updated`, { appearance: 'success', autoDismiss: true });
				return data;
			})
		},
		handleUpdateComment: (props) => (commentId, fields) => {
			const {
				post,
				sessionData,
				toastManager,
				updatePostComment
			} = props;

			return updatePostComment(sessionData.id, commentId, fields, {
				postShortId: post.shortId
			})
			.then((data) => {
				if (data.error) {
					console.log(data);
					toastManager.add(`There was a problem updating your comment`, { appearance: 'error', autoDismiss: true });
					return data;
				}
				toastManager.add(`Comment updated!`, { appearance: 'success', autoDismiss: true });
				return data;
			});
		},
		handleRemoveComment: (props) => (commentId) => {
			const {
				removeComment,
				community,
				post,
				toastManager
			} = props;

			return removeComment(community.id, post.id, commentId)
			.then((data) => {
				if (data.error) {
					toastManager.add(`There was a problem removing the comment`, { appearance: 'error', autoDismiss: true });
					return data;
				}
				toastManager.add(`Comment removed`, { appearance: 'success', autoDismiss: true });
				return data;
			});
		},
		handleRemovePost: (props) => (postId) => {
			const {
				removePost,
				community,
				post,
				toastManager
			} = props;

			return removePost(community.id, post.id)
			.then((data) => {
				if (data.error) {
					toastManager.add(`There was a problem removing the post`, { appearance: 'error', autoDismiss: true });
					return data;
				}
				toastManager.add(`Post removed`, { appearance: 'success', autoDismiss: true });
				return data;
			});
		}
	}),
	lifecycle({
		componentDidMount() {
			const {
				displaySlug,
				setLoadedCommunity,
				getCommunityPostByShortId,
				loadedCommunity,
				postShortId,
				getPostComments,
				setLoadedComments,
				loadedComments,
			} = this.props;

			if (!loadedCommunity) {
				getCommunityPostByShortId(displaySlug, postShortId, {
					includeDescription: true,
					onlyCompiledDescription: true,
					includeRules: true,
					includeModerators: true
				})
				.then((data) => {
					setLoadedCommunity(true);
					return data;
				});
			}

			if (!loadedComments) {
				getPostComments(displaySlug, postShortId)
				.then((data) => {
					setLoadedComments(true);
					return data;
				});
			}
		}
	})
);

export function CommunityPostPage(props) {
	const {
		loadedCommunity,
		community,
		post,
		sessionData,
		handleAddComment,
		commentUsers,
		postComments,
		commentTree,
		loadedComments,
		handlePostVote,
		userCommentVotes,
		showLoginSignupModal,
		setShowLoginSignupModal,
		handleLoginSignup,
		handleCommentVote,
		setShowEditPost,
		showEditPost,
		handleUpdatePostContent,
		updatingPostContent,
		handleUpdateComment,
		handleRemoveComment,
		handleRemovePost,
	} = props;

	const adultOnly = _.get(community, 'adultOnly');

	const restricted = !!community && adultOnly && !sessionData.isAdult;

	const isModerator = !!(sessionData.hasSession && !!_.find(_.get(community, 'moderators'), (m) => {
		return m.id === sessionData.id;
	}));

	return (
		<Page
			{...props}
			className={classnames('community-post-page', { restricted })}
			withContent
			pageName="community-post"
		>
			{!!(!loadedCommunity || !community) ? (
				<Spinner />
			) : (
				<>
					<div className="post-wrapper">
						{restricted ? (
							<RestrictedCommunity
								sessionData={sessionData}
								community={community}
							/>
						) : (
							<>
								{!!(post && post.isRemoved) && (
									<NotificationBanner kind="warning" display="rounded" className="post-removed-banner">
										<Heroicon icon="ExclamationTriangle" /> This post has been removed by the moderators
									</NotificationBanner>
								)}
								<Post
									post={{
										...post,
										community
									}}
									sessionData={sessionData}
									onVote={handlePostVote}
									postContent={() => (
										<PostContent
											post={post}
											onEditPost={() => setShowEditPost(true)}
											sessionData={sessionData}
											showModActions={isModerator === true}
											onRemovePost={handleRemovePost}
										/>
									)}
								/>
								<div className="comment-wrapper">
									{!!(loadedComments && loadedCommunity) ? (
										<PostComments
											post={post}
											handleAddComment={handleAddComment}
											handleUpdateComment={handleUpdateComment}
											sessionData={sessionData}
											comments={postComments}
											commentTree={commentTree}
											users={commentUsers}
											userCommentVotes={userCommentVotes}
											onVote={handleCommentVote}
											showModActions={isModerator === true}
											onRemove={handleRemoveComment}
										/>
									) : (
										<Spinner />
									)}
								</div>
							</>
						)}
					</div>
					<div className="community-sidebar-container">
						{!!(loadedCommunity && community) && (
							<CommunitySidebar
								community={community}
								sessionData={sessionData}
							/>
						)}
					</div>
				</>
			)}
			{!!showLoginSignupModal && (
				<LoginSignupModal
					onClose={() => setShowLoginSignupModal(false)}
					onComplete={handleLoginSignup}
				/>
			)}
			{!!showEditPost && (
				<Modal
					showing={showEditPost === true}
					onClose={() => setShowEditPost(false)}
					closeable
				>
					<Headline tag="h3" size={FONT_SIZES.xl} weight={FONT_WEIGHTS.bold}>
						Update post content
					</Headline>
					<UpdateCommunityPostForm
						onSubmit={handleUpdatePostContent}
						loading={updatingPostContent.loading}
						formError={updatingPostContent.error}
						initialValues={{
							rawBody: post.rawBody
						}}
						postType={post.postType}
					/>
				</Modal>
			)}
		</Page>
	);
}

export default enhance(CommunityPostPage);
