import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import './contentWrapper.scss';

export function ContentWrapper(props) {
	return (
		<div className={classnames('content-wrapper', props.className)}>
			{props.aboveContent ? props.aboveContent : null}
			<div className="content-wrapper-contents">
				{props.children}
			</div>
		</div>
	);
}

ContentWrapper.propTypes = {
	aboveContent: propTypes.any
};

export default ContentWrapper;
