import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, mapProps, withHandlers } from 'recompose';
import Card from '../../components/lib/card';
import Page from '../../components/page';
import SignupForm from '../../components/signupForm';
import { hasSegment, parseQueryParams } from '../../lib/helpers';
import { mapDispatchCreateAccountToProps, selectCreateAccount } from '../../store/selectors/account';
import './signup.scss';

export const enhance = compose(
	mapProps((props) => ({
		...props,
		queryParams: parseQueryParams(props.location, { base64Fields: ['email'] })
	})),
	connect(selectCreateAccount, mapDispatchCreateAccountToProps),
	withHandlers({
		handleSignupSubmit: (props) => (fields) => {
			props.createUserAccount(fields)
			.then(({ payload, error }) => {
				if (!error) {
					hasSegment(() => {
						analytics.track('signup');
					});
					props.history.push('/');
				}
			});
		}
	}),
	lifecycle({
		componentDidUpdate(prevProps) {
			const { loading, error } = this.props.createAccount;
			const { sessionData } = this.props;
			if (prevProps.createAccount.loading && !loading && !error) {

			}
		}
	})
);

export function Signup(props) {
	const { handleSignupSubmit, queryParams } = props;
	return (
		<Page
			{...props}
			className={classnames('signup-page')}
			withContent
			pageName="signup"
			title="Create Your Klique Account"
		>
			<Card>
				<SignupForm
					onSubmit={handleSignupSubmit}
					initialValues={{
						...(queryParams.email && queryParams.email.length ? { email: queryParams.email } : {})
					}}
				/>
			</Card>
		</Page>
	);
}

export default enhance(Signup);
