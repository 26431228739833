import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import { getBaseDescription, getBaseTitle } from '../../lib/tags';
import { selectApp } from '../../store/selectors/app';
import { dispatchSession, selectSession } from '../../store/selectors/session';
import AuthenticatedHeaderContent from '../authenticatedHeaderContent';
import BasePage from '../basePage';
import Footer from '../footer';
import Header from '../header';

import './page.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectSession,
			selectApp,
			(session, app) => ({
				...session,
				...app
			})
		),
		dispatchSession
	),
);

export function Page(props) {
	const {
		className,
		children,
		headerContent,
		hostname,
		location,
		title,
		description = getBaseDescription(props.description),
		assetUrlBase,
		canonicalLink,
		sessionData,
		og,
	} = props;
	let ogImage = _.get(og, 'image');
	if (_.isString(ogImage)) {
		ogImage = { image: ogImage };
	} else if (!ogImage || !ogImage.image) {
		ogImage = _.get(og, 'image') || {
			// image: `${assetUrlBase}/images/social/social-banner_2400x1260.png`,
			alt: 'Klique',
			width: 2400,
			height: 1260
		};
	}

	let canonical = canonicalLink || _.get(props, 'route.canonicalLink') || null;

	if (typeof canonical === 'function') {
		canonical = canonical(props);
	}

	if (canonical && canonical.indexOf('/') === 0) {
		canonical = `${hostname}${canonical}`;
	}

	const pageTitle = _.get(og, 'title') || title;
	const pageDescription = _.get(og, 'description') || description;
	return (
		<BasePage {...props} className={classnames('page', className)}>
			<Helmet>
				<title>{getBaseTitle(title)}</title>
				<meta property="og:type" content={_.get(og, 'type') || 'website'} />
				<meta property="og:title" content={pageTitle} />
				<meta name="twitter:title" content={pageTitle} />
				<meta name="description" content={pageDescription} />
				<meta property="og:description" content={pageDescription} />
				<meta name="twitter:description" content={pageDescription} />
				{_.get(ogImage, 'image') ? (<meta property="og:image" content={ogImage.image} />) : null}
				{_.get(ogImage, 'alt') ? (<meta property="og:image:alt" content={ogImage.alt} />) : null}
				{_.get(ogImage, 'width') ? (<meta property="og:image:width" content={ogImage.width} />) : null}
				{_.get(ogImage, 'height') ? (<meta property="og:image:height" content={ogImage.height} />) : null}
				<meta name="twitter:card" content="summary_large_image"/>
				<meta name="twitter:site" content="@kliqueio"/>
				{_.get(ogImage, 'image') ? (<meta property="twitter:image" content={ogImage.image} />) : null}
				<meta name="twitter:image:alt" content="Klique logo" />
				{!!(canonical && canonical.length) && (
					<link rel="canonical" href={canonical} />
				)}
				{!!(canonical && canonical.length) && (
					<meta property="og:url" content={canonical} />
				)}
			</Helmet>
			<Header
				hostname={hostname}
				location={location}
				pageName={props.pageName}
				match={props.match}
			/>
			<div className={classnames('page-content-container')}>
				<div className={classnames('page-content')}>
					{children}
				</div>
			</div>
			<Footer />
		</BasePage>
	);
}

Page.propTypes = {
	children: propTypes.node,
	headerContent: propTypes.node,
	requiresSession: propTypes.bool,
	title: propTypes.string,
	canonicalLink: propTypes.string,
	og: propTypes.object
};

Page.defaultProps = {
	requiresSession: false,
};

export default enhance(Page);
