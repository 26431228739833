import classnames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import numeral from 'numeral';
import propTypes from 'prop-types';
import React from 'react';
import { withHandlers, withProps } from 'recompose';
import { compose } from 'redux';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../lib/constants';
import { buildCommunityUrl, buildPostUrl } from '../../../lib/helpers';
import Heroicon from '../../lib/heroicon';
import Link from '../../lib/link';
import Text from '../../lib/text';
import { VoteButton } from '../../lib/voteButton';

import './post.scss';

function formatPostedDate(d, withoutSuffix = false) {
	const createdAt = new Date(d);

	return dayjs(createdAt).fromNow(withoutSuffix);
}

export const enhance = compose(
	withProps((props) => {
		return {
			...props,
			userVote: _.get(props.post, `postVotes[${_.get(props, 'sessionData.id')}]`) || null
		};
	}),
	withHandlers({
		handleVote: (props) => (vote) => {
			return props.onVote(props.post, vote);
		}
	})
)

function formatPostScore(post) {
	const rawScore = parseInt(post.upvotes || 0) - parseInt(post.downvotes || 0);

	if (rawScore < 10000) {
		return rawScore;
	}

	return numeral(rawScore).format('0.0a');
}

function PostedDate(props) {
	const { date } = props;
	return (
		<>
			<span className="long-date">
				{formatPostedDate(date)}
			</span>
			<span className="short-date">
				{formatPostedDate(date, true)}
			</span>
		</>
	)
}

function LinkPostPreview(props) {
	const { post } = props;
	if (post.isImage) {
		return (
			<div className="image-preview"
				 style={{
					 backgroundImage: `url(${post.fullLink})`
				 }}
			/>
		);
	}
	if (post.ogImageUrl && post.ogImageUrl.length) {
		return (
			<div className="image-preview"
				 style={{
					 backgroundImage: `url(${post.ogImageUrl})`
				 }}
			/>
		);
	}
	if (post.isVideo) {
		return (
			<div className="type-circle">
				<Heroicon icon={post.type === 'link' ? 'Link' : 'VideoCamera'} />
			</div>
		);
	}
	return (
		<div className="type-circle">
			<Heroicon icon={post.type === 'link' ? 'Link' : 'Link'} />
		</div>
	)
}

export function Post(props) {
	const {
		post,
		userVote,
		handleVote,
	} = props;

	const communitySlug = _.get(post, 'community.displaySlug');
	const postUsername = _.get(post, 'user.username');
	const postSlug = _.get(post, 'slug');
	const postShortId = _.get(post, 'shortId');

	let titleLocationLink = '';
	let titleLocationDisplay = '';
	let postUrl = '';

	if (post.postType === 'link') {
		titleLocationLink = post.fullLink;
		titleLocationDisplay = _.get(post, 'link.hostname');
		postUrl = post.fullLink;
	} else {
		titleLocationLink = `/c/${communitySlug}`;
		titleLocationDisplay = `self.${communitySlug}`;
		postUrl = buildPostUrl(communitySlug, postSlug, postShortId)
	}

	return (
		<div className={classnames('post', props.className)}>
			<div className="votes">
				<VoteButton
					direction="up"
					onClick={handleVote}
					vote={userVote}
				/>
				<div className="vote-count">
					<Text size={FONT_SIZES.regular} weight={FONT_WEIGHTS.bold}>
						{formatPostScore(post)}
					</Text>
				</div>
				<VoteButton
					direction="down"
					onClick={handleVote}
					vote={userVote}
				/>
			</div>
			<div className="preview-image">
				{post.postType === 'link' ? (
					<LinkPostPreview post={post} />
				) : (
					<div className="type-circle">
						<Heroicon icon={post.type === 'link' ? 'Link' : 'Bars3BottomLeft'} />
					</div>
				)}
			</div>
			<div className="post-details">
				<div className="post-title">
					<Link to={postUrl} className="post-title-link">
						{post.title}
					</Link>
					<Link to={titleLocationLink} className="post-location">
						({titleLocationDisplay})
					</Link>
				</div>
				<div className="post-metadata">
					<span className="submitted-text">submitted</span> <PostedDate date={post.createdAt} /> <span className="author">by <Link to={`/users/${postUsername}`} className="post-username">{postUsername}</Link> to </span> <Link to={buildCommunityUrl(communitySlug)}>/c/{communitySlug}</Link>
				</div>
				<div className="post-bottom-wrapper">
					{_.isFunction(props.postContent) ? props.postContent(props) : null}
				</div>
			</div>
		</div>
	);
}

Post.propTypes = {
	post: propTypes.shape({
		title: propTypes.string.isRequired,
		createdAt: propTypes.string.isRequired,
		postType: propTypes.oneOf(['text', 'link']),
		slug: propTypes.string,
		shortId: propTypes.string,
		fullLink: propTypes.string,
		link: propTypes.shape({
			hostname: propTypes.string,
			path: propTypes.string,
		}),
		community: propTypes.shape({
			slug: propTypes.string,
		}),
		user: propTypes.shape({
			username: propTypes.string.isRequired
		}),
		postVotes: propTypes.objectOf(propTypes.number),
		isLink: propTypes.bool,
		isVideo: propTypes.bool,
		ogImageUrl: propTypes.string
	}).isRequired,
	sessionData: propTypes.shape({
		id: propTypes.string,
	}),
	onVote: propTypes.func.isRequired,
	postContent: propTypes.func,
};

export default enhance(Post);
