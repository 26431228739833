import { handleActions } from 'redux-actions';
import jwt from 'jsonwebtoken';

import { ACCOUNT } from './actions';

export const initialState = {
	creatingBetaSignup: {
		loading: false,
		error: null
	},
	createAccount: {
		loading: false,
		error: null
	},
	creatingPaymentSetupIntent: {
		loading: false,
		error: null
	},
	creatingPaymentMethod: {
		loading: false,
		error: null
	},
	paymentMethodsByOrganizationId: {},
	listingOrganizationPaymentMethods: {
		loading: false,
		error: null
	},
	creatingPaymentMethodAndSubscription: {
		loading: false,
		error: null
	},
	gettingDetailedSubscription: {
		loading: false,
		error: null
	},
	detailedSubscriptionsByOrganizationId: {},
	updatingUser: {
		loading: false,
		error: null
	},
	updatingUserPassword: {
		loading: false,
		error: null
	},
	requestingPasswordReset: {
		loading: false,
		error: null
	},
	resettingPassword: {
		loading: false,
		error: null
	},
	creatingSubscription: {
		loading: false,
		error: null
	}

};

export default handleActions({
	[ACCOUNT.CREATE_BETA_SIGNUP_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			creatingBetaSignup: {
				...state.creatingBetaSignup,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.CREATE_BETA_SIGNUP_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			creatingBetaSignup: {
				...state.creatingBetaSignup,
				loading: false
			}
		};
	},
	[ACCOUNT.CREATE_BETA_SIGNUP_FAILURE]: (state, { error }) => {
		return {
			...state,
			creatingBetaSignup: {
				...state.creatingBetaSignup,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.CREATE_ACCOUNT_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			createAccount: {
				...state.createAccount,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.CREATE_ACCOUNT_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			createAccount: {
				...state.createAccount,
				loading: false
			}
		};
	},
	[ACCOUNT.CREATE_ACCOUNT_FAILURE]: (state, { error }) => {
		return {
			...state,
			createAccount: {
				...state.createAccount,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.CREATE_PAYMENT_SETUP_INTENT_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			creatingPaymentSetupIntent: {
				...state.creatingPaymentSetupIntent,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.CREATE_PAYMENT_SETUP_INTENT_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			creatingPaymentSetupIntent: {
				...state.creatingPaymentSetupIntent,
				loading: false
			}
		};
	},
	[ACCOUNT.CREATE_PAYMENT_SETUP_INTENT_FAILURE]: (state, { error }) => {
		return {
			...state,
			creatingPaymentSetupIntent: {
				...state.creatingPaymentSetupIntent,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.CREATE_PAYMENT_METHOD_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			creatingPaymentMethod: {
				...state.creatingPaymentMethod,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.CREATE_PAYMENT_METHOD_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			creatingPaymentMethod: {
				...state.creatingPaymentMethod,
				loading: false
			}
		};
	},
	[ACCOUNT.CREATE_PAYMENT_METHOD_FAILURE]: (state, { error }) => {
		return {
			...state,
			creatingPaymentMethod: {
				...state.creatingPaymentMethod,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.LIST_PAYMENT_METHODS_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			listingOrganizationPaymentMethods: {
				...state.listingOrganizationPaymentMethods,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.LIST_PAYMENT_METHODS_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			listingOrganizationPaymentMethods: {
				...state.listingOrganizationPaymentMethods,
				loading: false
			},
			paymentMethodsByOrganizationId: {
				...state.paymentMethodsByOrganizationId,
				[options.organizationId]: payload.paymentMethods
			}
		};
	},
	[ACCOUNT.LIST_PAYMENT_METHODS_FAILURE]: (state, { error }) => {
		return {
			...state,
			listingOrganizationPaymentMethods: {
				...state.listingOrganizationPaymentMethods,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			creatingPaymentMethodAndSubscription: {
				...state.creatingPaymentMethodAndSubscription,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			creatingPaymentMethodAndSubscription: {
				...state.creatingPaymentMethodAndSubscription,
				loading: false
			},
		};
	},
	[ACCOUNT.CREATE_PAYMENT_METHOD_AND_SUBSCRIPTION_FAILURE]: (state, { error }) => {
		return {
			...state,
			creatingPaymentMethodAndSubscription: {
				...state.creatingPaymentMethodAndSubscription,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.GET_DETAILED_SUBSCRIPTION_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			gettingDetailedSubscription: {
				...state.gettingDetailedSubscription,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.GET_DETAILED_SUBSCRIPTION_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			gettingDetailedSubscription: {
				...state.gettingDetailedSubscription,
				loading: false
			},
			detailedSubscriptionsByOrganizationId: {
				...state.detailedSubscriptionsByOrganizationId,
				[options.organizationId]: payload.subscriptions
			}
		};
	},
	[ACCOUNT.GET_DETAILED_SUBSCRIPTION_FAILURE]: (state, { error }) => {
		return {
			...state,
			gettingDetailedSubscription: {
				...state.gettingDetailedSubscription,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.UPDATE_USER_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			updatingUser: {
				...state.updatingUser,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.UPDATE_USER_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			updatingUser: {
				...state.updatingUser,
				loading: false
			},
		};
	},
	[ACCOUNT.UPDATE_USER_FAILURE]: (state, { error }) => {
		return {
			...state,
			updatingUser: {
				...state.updatingUser,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.UPDATE_USER_PASSWORD_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			updatingUserPassword: {
				...state.updatingUserPassword,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.UPDATE_USER_PASSWORD_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			updatingUserPassword: {
				...state.updatingUserPassword,
				loading: false
			},
		};
	},
	[ACCOUNT.UPDATE_USER_PASSWORD_FAILURE]: (state, { error }) => {
		return {
			...state,
			updatingUserPassword: {
				...state.updatingUserPassword,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.REQUEST_PASSWORD_RESET_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			requestingPasswordReset: {
				...state.requestingPasswordReset,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.REQUEST_PASSWORD_RESET_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			requestingPasswordReset: {
				...state.requestingPasswordReset,
				loading: false
			},
		};
	},
	[ACCOUNT.REQUEST_PASSWORD_RESET_FAILURE]: (state, { error }) => {
		return {
			...state,
			requestingPasswordReset: {
				...state.requestingPasswordReset,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.RESET_PASSWORD_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			resettingPassword: {
				...state.resettingPassword,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.RESET_PASSWORD_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			resettingPassword: {
				...state.resettingPassword,
				loading: false
			},
		};
	},
	[ACCOUNT.RESET_PASSWORD_FAILURE]: (state, { error }) => {
		return {
			...state,
			resettingPassword: {
				...state.resettingPassword,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},
	[ACCOUNT.CREATE_SUBSCRIPTION_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			creatingSubscription: {
				...state.creatingSubscription,
				loading: true,
				error: null
			}
		};
	},
	[ACCOUNT.CREATE_SUBSCRIPTION_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			creatingSubscription: {
				...state.creatingSubscription,
				loading: false
			},
		};
	},
	[ACCOUNT.CREATE_SUBSCRIPTION_FAILURE]: (state, { error }) => {
		return {
			...state,
			creatingSubscription: {
				...state.creatingSubscription,
				loading: false,
				error: _.get(error, 'response.data')
			}
		};
	},

}, initialState);



