import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import propTypes from 'prop-types';

function transformWithProps(props) {
	return function transform(node) {
		if (node.type === 'script') {
			return null;
		}
		if (node.type === 'style') {
			return null;
		}
		if (node.type === 'tag' && node.name === 'a' && props.externalLinkBehavior === 'newtab') {
			const href = _.get(node, 'attribs.href');
			if (href && href.match(/^https?:\/\//)) {
				_.set(node, 'attribs.target', '_blank');
			}
		}
	}
}

export function SafeHTMLContent(props) {
	return ReactHtmlParser(props.content, {
		transform: transformWithProps(_.pick(props, ['externalLinkBehavior']))
	});
}

SafeHTMLContent.propTypes = {
	content: propTypes.string.isRequired,
	externalLinkBehavior: propTypes.oneOf(['newtab', 'samewindow'])
};

SafeHTMLContent.defaultProps = {
	externalLinkBehavior: 'newtab'
};

export default SafeHTMLContent;
