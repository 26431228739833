import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, mapProps, withHandlers } from 'recompose';
import { createSelector } from 'reselect';
import Card from '../../components/lib/card';
import Headline from '../../components/lib/headline';

import LoginForm from '../../components/loginForm';
import Page from '../../components/page';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { parseQueryParams, } from '../../lib/helpers';
import { mapDispatchCreateSessionToProps, selectCreateSession, selectSession } from '../../store/selectors/session';

import './login.scss';

export const enhance = compose(
	mapProps((props) => ({
		...props,
		queryParams: parseQueryParams(props.location)
	})),
	connect(
		createSelector(
			selectSession,
			selectCreateSession,
			(session, create) => ({
				...session,
				...create
			})
		),
		mapDispatchCreateSessionToProps
	),
	withHandlers({
		handleLoginSubmit: (props) => (fields) => {
			props.createUserSession(fields);
		},
	}),
	lifecycle({
		componentDidMount() {
			const { sessionData, queryParams, history } = this.props;
			if (sessionData.hasSession && queryParams.returnTo) {
				if (queryParams.returnTo.indexOf('/') === 0) {
					history.push(queryParams.returnTo);
					return;
				}
			}
		},
		componentDidUpdate(prevProps) {
			const { loading, error } = this.props.createSession;
			const { queryParams } = this.props;
			if (prevProps.createSession.loading && !loading && !error) {
				if (queryParams.returnTo && queryParams.returnTo.indexOf('/') === 0) {
					this.props.history.push(queryParams.returnTo);
					return;
				}
				this.props.history.push('/');
			}
		}
	})
);

export function Login(props) {
	const { handleLoginSubmit } = props;
	return (
		<Page
			{...props}
			className={classnames('login-page')}
			withContent
			pageName="login"
			title="Log in"
		>
			<Card>
				<Headline tag="h1" size={FONT_SIZES.xl4} weight={FONT_WEIGHTS.black}>
					Welcome back!
				</Headline>
				<LoginForm onSubmit={handleLoginSubmit} />
			</Card>
		</Page>
	);
}

export default enhance(Login);
