import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import Text from '../text';

import './formFieldLabel.scss';

export function FormFieldLabel(props) {
	const labelProps = {
		...(props.name ? { htmlFor: props.name } : {})
	};
	return (
		<label
			className={classnames('form-field-label', props.className)}
			{...labelProps}
		>
			{props.children}
			{props.isRequired === true ? (<Text className="field-indicator required-indicator">*</Text>) : null}
			{props.isOptional === true ? (<Text className="field-indicator optional-indicator">(Optional)</Text>) : null}
		</label>
	);
}

FormFieldLabel.propTypes = {
	isRequired: propTypes.bool,
	isOptional: propTypes.bool
};

export default FormFieldLabel;
