import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { compose, withHandlers } from 'recompose';

import { createSelector } from 'reselect';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { buildCommunityUrl } from '../../lib/helpers';
import { mapDispatchUpdateAccountToProps, selectUpdateAccount } from '../../store/selectors/account';
import UserDateOfBirthForm from '../forms/userDateOfBirthForm';
import { Button } from '../lib/button';
import Card from '../lib/card';
import CardBody from '../lib/cardBody';
import Headline from '../lib/headline';
import Paragraph from '../lib/paragraph';
import Text from '../lib/text';

import './restrictedCommunity.scss';

export const enhance = compose(
	withToastManager,
	connect(
		createSelector(
			selectUpdateAccount,
			(updateAccount) => ({
				...updateAccount
			})
		),
		{
			...mapDispatchUpdateAccountToProps,
		}
	),
	withHandlers({
		handleUpdateUserDoB: (props) => (fields) => {
			const {
				sessionData,
				updateUser,
				toastManager
			} = props;

			return updateUser(sessionData.id, fields)
			.then((data) => {
				if (!data.error) {
					toastManager.add('Date of birth updated!', { appearance: 'success', autoDismiss: true });
					return data;
				}
				toastManager.add('There was a problem saving your date of birth', { appearance: 'error', autoDismiss: true });
				return data;
			});
		}
	})
);

export function RestrictedCommunity(props) {
	const {
		community,
		sessionData,
		handleUpdateUserDoB,
		updatingUser
	} = props;

	return (
		<div className={classnames('restricted-community', props.className)}>
			<Card className="nsfw-message">
				<CardBody>
					<Text size={FONT_SIZES.xl3} weight={FONT_WEIGHTS.bold}>
						18+
					</Text>
					<Paragraph>
						{buildCommunityUrl(community.displaySlug)} is restricted to adults only
					</Paragraph>
				</CardBody>
			</Card>
			{sessionData.hasSession ? (
				<>
					{!!(sessionData.dateOfBirth && !sessionData.isAdult) ? (
						<Headline tag="h3" size={FONT_SIZES.lg} weight={FONT_WEIGHTS.normal}>
							You must be 18 years old or older to view this content
						</Headline>
					) : (
						<div className="confirm-user-birthdate">
							<Headline tag="h3" size={FONT_SIZES.lg} weight={FONT_WEIGHTS.normal}>
								To continue, please provide your date of birth:
							</Headline>
							<UserDateOfBirthForm
								onSubmit={handleUpdateUserDoB}
								loading={updatingUser.loading}
								formError={updatingUser.error}
							/>
						</div>
					)}
				</>
			) : (
				<div className="login-or-signup">
					<Headline tag="h3" size={FONT_SIZES.lg} weight={FONT_WEIGHTS.normal}>
						To continue, log in or sign up to confirm your age
					</Headline>
					<div className="button-container">
						<Button
							size="small"
							display="primary-outline"
							segmentConfig={{ label: 'header-login' }}
							isLink
							href={`/login?returnTo=${buildCommunityUrl(community.displaySlug)}`}
						>
							Log In
						</Button>
						<Button
							size="small"
							segmentConfig={{ label: 'header-signup' }}
							isLink
							href={`/signup?returnTo=${buildCommunityUrl(community.displaySlug)}`}
						>
							Sign Up
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

RestrictedCommunity.propTypes = {
	sessionData: propTypes.shape({
		hasSession: propTypes.bool,
		dateOfBirth: propTypes.string,
	}).isRequired,
	community: propTypes.shape({
		displaySlug: propTypes.string
	}).isRequired
}

export default enhance(RestrictedCommunity);
