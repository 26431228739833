import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import MoonLoader from "react-spinners/MoonLoader";
import './spinner.scss';

export function Spinner({ className, size, kind, ...rest }) {
	if (kind === 'moon') {
		let moonSize = 18;
		if (size === 'small') {
			moonSize = 14;
		} else if (size === 'large') {
			moonSize = 22;
		} else if (size === 'xlarge') {
			moonSize = 70;
		}

		return (
			<div className={classnames('spinner', 'moon', size)}>
				<MoonLoader color={'#4b5563'} size={moonSize} {...rest}/>
			</div>
		)
	}

	return (
		<div className={classnames('spinner-wrapper', className)}>
			<div className={classnames('spinner', size)} />
		</div>
	);
}

Spinner.propTypes = {
	size: propTypes.oneOf(['small', 'regular', 'large', 'xlarge']),
	kind: propTypes.oneOf(['cached', 'moon'])

};

Spinner.defaultProps = {
	size: 'large',
	kind: 'moon'
};

export default Spinner;

