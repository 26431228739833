export const EPISODE_TYPES = [
	{ label: 'Full', value: 'full' },
	{ label: 'Trailer', value: 'trailer' },
	{ label: 'Bonus Content', value: 'bonus' },
];


export const FONT_SIZES = {
	inherit: 'inherit',
	xs: 'xs',
	sm: 'sm',
	regular: 'regular',
	lg: 'lg',
	xl: 'xl',
	xl2: '2xl',
	xl3: '3xl',
	xl4: '4xl',
	xl5: '5xl',
	xl6: '6xl',
	xl7: '7xl',
	xl8: '8xl',
	xl9: '9xl'
};

export const FONT_WEIGHTS = {
	inherit: 'inherit',
	thin: 'thin',
	extralight: 'extralight',
	light: 'light',
	normal: 'normal',
	medium: 'medium',
	semibold: 'semibold',
	bold: 'bold',
	extrabold: 'extrabold',
	black: 'black'
};

export const FONT_COLORS = {
	inherit: 'inherit',
	regular: 'regular',
	black: 'black',
	white: 'white',
	gray: 'gray',
	lightGray: 'light-gray',
	darkGray: 'dark-gray',
	mediumGray: 'medium-gray',
	primary: 'primary',
	secondary: 'secondary',
	teal: 'teal',
	green: 'green',
	red: 'red',
	purple: 'purple',
	indigo: 'indigo'
};

export const FONT_STYLES = {
	sizes: Object.values(FONT_SIZES),
	weights: Object.values(FONT_WEIGHTS),
	colors: Object.values(FONT_COLORS)
};

export const AREA_CODES_BY_STATE = {
	'Mississippi': [
		228,
		601,
		662,
		769
	],
	'Northern Mariana Islands': [
		670
	],
	'Oklahoma': [
		405,
		539,
		580,
		918
	],
	'Delaware': [
		302
	],
	'Minnesota': [
		218,
		320,
		507,
		612,
		651,
		763,
		952
	],
	'Illinois': [
		217,
		224,
		309,
		312,
		331,
		618,
		630,
		708,
		773,
		779,
		815,
		847,
		872
	],
	'Arkansas': [
		479,
		501,
		870
	],
	'New Mexico': [
		505,
		575
	],
	'Indiana': [
		219,
		260,
		317,
		574,
		765,
		812
	],
	'Maryland': [
		240,
		301,
		410,
		443,
		667
	],
	'Louisiana': [
		225,
		318,
		337,
		504,
		985
	],
	'Idaho': [
		208
	],
	'Wyoming': [
		307
	],
	'Tennessee': [
		423,
		615,
		731,
		865,
		901,
		931
	],
	'Arizona': [
		480,
		520,
		602,
		623,
		928
	],
	'Iowa': [
		319,
		515,
		563,
		641,
		712
	],
	'Michigan': [
		231,
		248,
		269,
		313,
		517,
		586,
		616,
		734,
		810,
		906,
		947,
		989
	],
	'Kansas': [
		316,
		620,
		785,
		913
	],
	'Utah': [
		385,
		435,
		801
	],
	'American Samoa': [
		684
	],
	'Oregon': [
		458,
		503,
		541,
		971
	],
	'Connecticut': [
		203,
		475,
		860
	],
	'Montana': [
		406
	],
	'California': [
		209,
		213,
		310,
		323,
		408,
		415,
		424,
		442,
		510,
		530,
		559,
		562,
		619,
		626,
		650,
		657,
		661,
		669,
		707,
		714,
		747,
		760,
		805,
		818,
		831,
		858,
		909,
		916,
		925,
		949,
		951
	],
	'Massachusetts': [
		339,
		351,
		413,
		508,
		617,
		774,
		781,
		857,
		978
	],
	'Puerto Rico': [
		787,
		939
	],
	'South Carolina': [
		803,
		843,
		864
	],
	'New Hampshire': [
		603
	],
	'Wisconsin': [
		262,
		414,
		534,
		608,
		715,
		920
	],
	'Vermont': [
		802
	],
	'Georgia': [
		229,
		404,
		470,
		478,
		678,
		706,
		762,
		770,
		912
	],
	'North Dakota': [
		701
	],
	'Pennsylvania': [
		215,
		267,
		272,
		412,
		484,
		570,
		610,
		717,
		724,
		814,
		878
	],
	'West Virginia': [
		304,
		681
	],
	'Florida': [
		239,
		305,
		321,
		352,
		386,
		407,
		561,
		727,
		754,
		772,
		786,
		813,
		850,
		863,
		904,
		941,
		954
	],
	'Hawaii': [
		808
	],
	'Kentucky': [
		270,
		502,
		606,
		859
	],
	'Alaska': [
		907
	],
	'Nebraska': [
		308,
		402,
		531
	],
	'Missouri': [
		314,
		417,
		573,
		636,
		660,
		816
	],
	'Ohio': [
		216,
		234,
		330,
		419,
		440,
		513,
		567,
		614,
		740,
		937
	],
	'Alabama': [
		205,
		251,
		256,
		334,
		938
	],
	'Rhode Island': [
		401
	],
	'District of Columbia': [
		202
	],
	'Virgin Islands': [
		340
	],
	'South Dakota': [
		605
	],
	'Colorado': [
		303,
		719,
		720,
		970
	],
	'New Jersey': [
		201,
		551,
		609,
		732,
		848,
		856,
		862,
		908,
		973
	],
	'Virginia': [
		276,
		434,
		540,
		571,
		703,
		757,
		804
	],
	'Guam': [
		671
	],
	'Washington': [
		206,
		253,
		360,
		425,
		509
	],
	'North Carolina': [
		252,
		336,
		704,
		828,
		910,
		919,
		980,
		984
	],
	'New York': [
		212,
		315,
		347,
		516,
		518,
		585,
		607,
		631,
		646,
		716,
		718,
		845,
		914,
		917,
		929
	],
	'Texas': [
		210,
		214,
		254,
		281,
		325,
		346,
		361,
		409,
		430,
		432,
		469,
		512,
		682,
		713,
		737,
		806,
		817,
		830,
		832,
		903,
		915,
		936,
		940,
		956,
		972,
		979
	],
	'Nevada': [
		702,
		725,
		775
	],
	'Maine': [
		207
	]
};

export const AREA_CODE_STATES = Object.keys(AREA_CODES_BY_STATE).sort();
export const ALL_AREA_CODES = AREA_CODE_STATES.reduce((codes, state) => {
	codes.push(...AREA_CODES_BY_STATE[state]);
	return codes;
}, []).sort();

export const ALL_STATES = [
	{
		name: 'Alabama',
		'abbreviation': 'AL'
	},
	{
		name: 'Alaska',
		'abbreviation': 'AK'
	},
	{
		name: 'American Samoa',
		'abbreviation': 'AS'
	},
	{
		name: 'Arizona',
		'abbreviation': 'AZ'
	},
	{
		name: 'Arkansas',
		'abbreviation': 'AR'
	},
	{
		name: 'California',
		'abbreviation': 'CA'
	},
	{
		name: 'Colorado',
		'abbreviation': 'CO'
	},
	{
		name: 'Connecticut',
		'abbreviation': 'CT'
	},
	{
		name: 'Delaware',
		'abbreviation': 'DE'
	},
	{
		name: 'District of Columbia',
		'abbreviation': 'DC'
	},
	{
		name: 'Florida',
		'abbreviation': 'FL'
	},
	{
		name: 'Georgia',
		'abbreviation': 'GA'
	},
	{
		name: 'Guam',
		'abbreviation': 'GU'
	},
	{
		name: 'Hawaii',
		'abbreviation': 'HI'
	},
	{
		name: 'Idaho',
		'abbreviation': 'ID'
	},
	{
		name: 'Illinois',
		'abbreviation': 'IL'
	},
	{
		name: 'Indiana',
		'abbreviation': 'IN'
	},
	{
		name: 'Iowa',
		'abbreviation': 'IA'
	},
	{
		name: 'Kansas',
		'abbreviation': 'KS'
	},
	{
		name: 'Kentucky',
		'abbreviation': 'KY'
	},
	{
		name: 'Louisiana',
		'abbreviation': 'LA'
	},
	{
		name: 'Maine',
		'abbreviation': 'ME'
	},
	{
		name: 'Maryland',
		'abbreviation': 'MD'
	},
	{
		name: 'Massachusetts',
		'abbreviation': 'MA'
	},
	{
		name: 'Michigan',
		'abbreviation': 'MI'
	},
	{
		name: 'Minnesota',
		'abbreviation': 'MN'
	},
	{
		name: 'Mississippi',
		'abbreviation': 'MS'
	},
	{
		name: 'Missouri',
		'abbreviation': 'MO'
	},
	{
		name: 'Montana',
		'abbreviation': 'MT'
	},
	{
		name: 'Nebraska',
		'abbreviation': 'NE'
	},
	{
		name: 'Nevada',
		'abbreviation': 'NV'
	},
	{
		name: 'New Hampshire',
		'abbreviation': 'NH'
	},
	{
		name: 'New Jersey',
		'abbreviation': 'NJ'
	},
	{
		name: 'New Mexico',
		'abbreviation': 'NM'
	},
	{
		name: 'New York',
		'abbreviation': 'NY'
	},
	{
		name: 'North Carolina',
		'abbreviation': 'NC'
	},
	{
		name: 'North Dakota',
		'abbreviation': 'ND'
	},
	{
		name: 'Northern Mariana Islands',
		'abbreviation': 'MP'
	},
	{
		name: 'Ohio',
		'abbreviation': 'OH'
	},
	{
		name: 'Oklahoma',
		'abbreviation': 'OK'
	},
	{
		name: 'Oregon',
		'abbreviation': 'OR'
	},
	{
		name: 'Pennsylvania',
		'abbreviation': 'PA'
	},
	{
		name: 'Puerto Rico',
		'abbreviation': 'PR'
	},
	{
		name: 'Rhode Island',
		'abbreviation': 'RI'
	},
	{
		name: 'South Carolina',
		'abbreviation': 'SC'
	},
	{
		name: 'South Dakota',
		'abbreviation': 'SD'
	},
	{
		name: 'Tennessee',
		'abbreviation': 'TN'
	},
	{
		name: 'Texas',
		'abbreviation': 'TX'
	},
	{
		name: 'Utah',
		'abbreviation': 'UT'
	},
	{
		name: 'Vermont',
		'abbreviation': 'VT'
	},
	{
		name: 'Virgin Islands',
		'abbreviation': 'VI'
	},
	{
		name: 'Virginia',
		'abbreviation': 'VA'
	},
	{
		name: 'Washington',
		'abbreviation': 'WA'
	},
	{
		name: 'West Virginia',
		'abbreviation': 'WV'
	},
	{
		name: 'Wisconsin',
		'abbreviation': 'WI'
	},
	{
		name: 'Wyoming',
		'abbreviation': 'WY'
	}
];

export const unitedStatesList = [
	{ name: 'Alabama', id: 'AL' },
	{ name: 'Alaska', id: 'AK' },
	{ name: 'Arizona', id: 'AZ' },
	{ name: 'Arkansas', id: 'AR' },
	{ name: 'California', id: 'CA' },
	{ name: 'Colorado', id: 'CO' },
	{ name: 'Connecticut', id: 'CT' },
	{ name: 'Delaware', id: 'DE' },
	{ name: 'District Of Columbia', id: 'DC' },
	{ name: 'Florida', id: 'FL' },
	{ name: 'Georgia', id: 'GA' },
	{ name: 'Hawaii', id: 'HI' },
	{ name: 'Idaho', id: 'ID' },
	{ name: 'Illinois', id: 'IL' },
	{ name: 'Indiana', id: 'IN' },
	{ name: 'Iowa', id: 'IA' },
	{ name: 'Kansas', id: 'KS' },
	{ name: 'Kentucky', id: 'KY' },
	{ name: 'Louisiana', id: 'LA' },
	{ name: 'Maine', id: 'ME' },
	{ name: 'Maryland', id: 'MD' },
	{ name: 'Massachusetts', id: 'MA' },
	{ name: 'Michigan', id: 'MI' },
	{ name: 'Minnesota', id: 'MN' },
	{ name: 'Mississippi', id: 'MS' },
	{ name: 'Missouri', id: 'MO' },
	{ name: 'Montana', id: 'MT' },
	{ name: 'Nebraska', id: 'NE' },
	{ name: 'Nevada', id: 'NV' },
	{ name: 'New Hampshire', id: 'NH' },
	{ name: 'New Jersey', id: 'NJ' },
	{ name: 'New Mexico', id: 'NM' },
	{ name: 'New York', id: 'NY' },
	{ name: 'North Carolina', id: 'NC' },
	{ name: 'North Dakota', id: 'ND' },
	{ name: 'Ohio', id: 'OH' },
	{ name: 'Oklahoma', id: 'OK' },
	{ name: 'Oregon', id: 'OR' },
	{ name: 'Pennsylvania', id: 'PA' },
	{ name: 'Rhode Island', id: 'RI' },
	{ name: 'South Carolina', id: 'SC' },
	{ name: 'South Dakota', id: 'SD' },
	{ name: 'Tennessee', id: 'TN' },
	{ name: 'Texas', id: 'TX' },
	{ name: 'Utah', id: 'UT' },
	{ name: 'Vermont', id: 'VT' },
	{ name: 'Virginia', id: 'VA' },
	{ name: 'Washington', id: 'WA' },
	{ name: 'West Virginia', id: 'WV' },
	{ name: 'Wisconsin', id: 'WI' },
	{ name: 'Wyoming', id: 'WY' },
];

export const countryWhiteList = [
	{ name: 'Afghanistan', code: 'AF' },
	{ name: 'Åland Islands', code: 'AX' },
	{ name: 'Albania', code: 'AL' },
	{ name: 'Algeria', code: 'DZ' },
	{ name: 'American Samoa', code: 'AS' },
	{ name: 'AndorrA', code: 'AD' },
	{ name: 'Angola', code: 'AO' },
	{ name: 'Anguilla', code: 'AI' },
	{ name: 'Antarctica', code: 'AQ' },
	{ name: 'Antigua and Barbuda', code: 'AG' },
	{ name: 'Argentina', code: 'AR' },
	{ name: 'Armenia', code: 'AM' },
	{ name: 'Aruba', code: 'AW' },
	{ name: 'Australia', code: 'AU' },
	{ name: 'Austria', code: 'AT' },
	{ name: 'Azerbaijan', code: 'AZ' },
	{ name: 'Bahamas', code: 'BS' },
	{ name: 'Bahrain', code: 'BH' },
	{ name: 'Bangladesh', code: 'BD' },
	{ name: 'Barbados', code: 'BB' },
	{ name: 'Belarus', code: 'BY' },
	{ name: 'Belgium', code: 'BE' },
	{ name: 'Belize', code: 'BZ' },
	{ name: 'Benin', code: 'BJ' },
	{ name: 'Bermuda', code: 'BM' },
	{ name: 'Bhutan', code: 'BT' },
	{ name: 'Bolivia', code: 'BO' },
	{ name: 'Bosnia and Herzegovina', code: 'BA' },
	{ name: 'Botswana', code: 'BW' },
	{ name: 'Bouvet Island', code: 'BV' },
	{ name: 'Brazil', code: 'BR' },
	{ name: 'British Indian Ocean Territory', code: 'IO' },
	{ name: 'Brunei Darussalam', code: 'BN' },
	{ name: 'Bulgaria', code: 'BG' },
	{ name: 'Burkina Faso', code: 'BF' },
	{ name: 'Burundi', code: 'BI' },
	{ name: 'Cambodia', code: 'KH' },
	{ name: 'Cameroon', code: 'CM' },
	{ name: 'Canada', code: 'CA' },
	{ name: 'Cape Verde', code: 'CV' },
	{ name: 'Cayman Islands', code: 'KY' },
	{ name: 'Central African Republic', code: 'CF' },
	{ name: 'Chad', code: 'TD' },
	{ name: 'Chile', code: 'CL' },
	{ name: 'Christmas Island', code: 'CX' },
	{ name: 'Cocos (Keeling) Islands', code: 'CC' },
	{ name: 'Colombia', code: 'CO' },
	{ name: 'Comoros', code: 'KM' },
	{ name: 'Congo', code: 'CG' },
	{ name: 'Congo, The Democratic Republic of the', code: 'CD' },
	{ name: 'Cook Islands', code: 'CK' },
	{ name: 'Costa Rica', code: 'CR' },
	{ name: 'Cote D\'Ivoire', code: 'CI' },
	{ name: 'Croatia', code: 'HR' },
	{ name: 'Cyprus', code: 'CY' },
	{ name: 'Czech Republic', code: 'CZ' },
	{ name: 'Denmark', code: 'DK' },
	{ name: 'Djibouti', code: 'DJ' },
	{ name: 'Dominica', code: 'DM' },
	{ name: 'Dominican Republic', code: 'DO' },
	{ name: 'Ecuador', code: 'EC' },
	{ name: 'Egypt', code: 'EG' },
	{ name: 'El Salvador', code: 'SV' },
	{ name: 'Equatorial Guinea', code: 'GQ' },
	{ name: 'Eritrea', code: 'ER' },
	{ name: 'Estonia', code: 'EE' },
	{ name: 'Ethiopia', code: 'ET' },
	{ name: 'Falkland Islands (Malvinas)', code: 'FK' },
	{ name: 'Faroe Islands', code: 'FO' },
	{ name: 'Fiji', code: 'FJ' },
	{ name: 'Finland', code: 'FI' },
	{ name: 'France', code: 'FR' },
	{ name: 'French Guiana', code: 'GF' },
	{ name: 'French Polynesia', code: 'PF' },
	{ name: 'French Southern Territories', code: 'TF' },
	{ name: 'Gabon', code: 'GA' },
	{ name: 'Gambia', code: 'GM' },
	{ name: 'Georgia', code: 'GE' },
	{ name: 'Germany', code: 'DE' },
	{ name: 'Ghana', code: 'GH' },
	{ name: 'Gibraltar', code: 'GI' },
	{ name: 'Greece', code: 'GR' },
	{ name: 'Greenland', code: 'GL' },
	{ name: 'Grenada', code: 'GD' },
	{ name: 'Guadeloupe', code: 'GP' },
	{ name: 'Guam', code: 'GU' },
	{ name: 'Guatemala', code: 'GT' },
	{ name: 'Guernsey', code: 'GG' },
	{ name: 'Guinea', code: 'GN' },
	{ name: 'Guinea-Bissau', code: 'GW' },
	{ name: 'Guyana', code: 'GY' },
	{ name: 'Haiti', code: 'HT' },
	{ name: 'Heard Island and Mcdonald Islands', code: 'HM' },
	{ name: 'Holy See (Vatican City State)', code: 'VA' },
	{ name: 'Honduras', code: 'HN' },
	{ name: 'Hong Kong', code: 'HK' },
	{ name: 'Hungary', code: 'HU' },
	{ name: 'Iceland', code: 'IS' },
	{ name: 'India', code: 'IN' },
	{ name: 'Indonesia', code: 'ID' },
	{ name: 'Ireland', code: 'IE' },
	{ name: 'Isle of Man', code: 'IM' },
	{ name: 'Israel', code: 'IL' },
	{ name: 'Italy', code: 'IT' },
	{ name: 'Jamaica', code: 'JM' },
	{ name: 'Japan', code: 'JP' },
	{ name: 'Jersey', code: 'JE' },
	{ name: 'Jordan', code: 'JO' },
	{ name: 'Kazakhstan', code: 'KZ' },
	{ name: 'Kenya', code: 'KE' },
	{ name: 'Kiribati', code: 'KI' },
	{ name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
	{ name: 'Korea, Republic of', code: 'KR' },
	{ name: 'Kuwait', code: 'KW' },
	{ name: 'Kyrgyzstan', code: 'KG' },
	{ name: 'Lao People\'S Democratic Republic', code: 'LA' },
	{ name: 'Latvia', code: 'LV' },
	{ name: 'Lebanon', code: 'LB' },
	{ name: 'Lesotho', code: 'LS' },
	{ name: 'Liberia', code: 'LR' },
	{ name: 'Libyan Arab Jamahiriya', code: 'LY' },
	{ name: 'Liechtenstein', code: 'LI' },
	{ name: 'Lithuania', code: 'LT' },
	{ name: 'Luxembourg', code: 'LU' },
	{ name: 'Macao', code: 'MO' },
	{ name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
	{ name: 'Madagascar', code: 'MG' },
	{ name: 'Malawi', code: 'MW' },
	{ name: 'Malaysia', code: 'MY' },
	{ name: 'Maldives', code: 'MV' },
	{ name: 'Mali', code: 'ML' },
	{ name: 'Malta', code: 'MT' },
	{ name: 'Marshall Islands', code: 'MH' },
	{ name: 'Martinique', code: 'MQ' },
	{ name: 'Mauritania', code: 'MR' },
	{ name: 'Mauritius', code: 'MU' },
	{ name: 'Mayotte', code: 'YT' },
	{ name: 'Mexico', code: 'MX' },
	{ name: 'Micronesia, Federated States of', code: 'FM' },
	{ name: 'Moldova, Republic of', code: 'MD' },
	{ name: 'Monaco', code: 'MC' },
	{ name: 'Mongolia', code: 'MN' },
	{ name: 'Montserrat', code: 'MS' },
	{ name: 'Morocco', code: 'MA' },
	{ name: 'Mozambique', code: 'MZ' },
	{ name: 'Myanmar', code: 'MM' },
	{ name: 'Namibia', code: 'NA' },
	{ name: 'Nauru', code: 'NR' },
	{ name: 'Nepal', code: 'NP' },
	{ name: 'Netherlands', code: 'NL' },
	{ name: 'Netherlands Antilles', code: 'AN' },
	{ name: 'New Caledonia', code: 'NC' },
	{ name: 'New Zealand', code: 'NZ' },
	{ name: 'Nicaragua', code: 'NI' },
	{ name: 'Niger', code: 'NE' },
	{ name: 'Nigeria', code: 'NG' },
	{ name: 'Niue', code: 'NU' },
	{ name: 'Norfolk Island', code: 'NF' },
	{ name: 'Northern Mariana Islands', code: 'MP' },
	{ name: 'Norway', code: 'NO' },
	{ name: 'Oman', code: 'OM' },
	{ name: 'Pakistan', code: 'PK' },
	{ name: 'Palau', code: 'PW' },
	{ name: 'Palestinian Territory, Occupied', code: 'PS' },
	{ name: 'Panama', code: 'PA' },
	{ name: 'Papua New Guinea', code: 'PG' },
	{ name: 'Paraguay', code: 'PY' },
	{ name: 'Peru', code: 'PE' },
	{ name: 'Philippines', code: 'PH' },
	{ name: 'Pitcairn', code: 'PN' },
	{ name: 'Poland', code: 'PL' },
	{ name: 'Portugal', code: 'PT' },
	{ name: 'Puerto Rico', code: 'PR' },
	{ name: 'Qatar', code: 'QA' },
	{ name: 'Reunion', code: 'RE' },
	{ name: 'Romania', code: 'RO' },
	{ name: 'RWANDA', code: 'RW' },
	{ name: 'Saint Helena', code: 'SH' },
	{ name: 'Saint Kitts and Nevis', code: 'KN' },
	{ name: 'Saint Lucia', code: 'LC' },
	{ name: 'Saint Pierre and Miquelon', code: 'PM' },
	{ name: 'Saint Vincent and the Grenadines', code: 'VC' },
	{ name: 'Samoa', code: 'WS' },
	{ name: 'San Marino', code: 'SM' },
	{ name: 'Sao Tome and Principe', code: 'ST' },
	{ name: 'Saudi Arabia', code: 'SA' },
	{ name: 'Senegal', code: 'SN' },
	{ name: 'Serbia and Montenegro', code: 'CS' },
	{ name: 'Seychelles', code: 'SC' },
	{ name: 'Sierra Leone', code: 'SL' },
	{ name: 'Singapore', code: 'SG' },
	{ name: 'Slovakia', code: 'SK' },
	{ name: 'Slovenia', code: 'SI' },
	{ name: 'Solomon Islands', code: 'SB' },
	{ name: 'Somalia', code: 'SO' },
	{ name: 'South Africa', code: 'ZA' },
	{ name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
	{ name: 'Spain', code: 'ES' },
	{ name: 'Sri Lanka', code: 'LK' },
	{ name: 'Sudan', code: 'SD' },
	{ name: 'Suriname', code: 'SR' },
	{ name: 'Svalbard and Jan Mayen', code: 'SJ' },
	{ name: 'Swaziland', code: 'SZ' },
	{ name: 'Sweden', code: 'SE' },
	{ name: 'Switzerland', code: 'CH' },
	{ name: 'Taiwan, Province of China', code: 'TW' },
	{ name: 'Tajikistan', code: 'TJ' },
	{ name: 'Tanzania, United Republic of', code: 'TZ' },
	{ name: 'Thailand', code: 'TH' },
	{ name: 'Timor-Leste', code: 'TL' },
	{ name: 'Togo', code: 'TG' },
	{ name: 'Tokelau', code: 'TK' },
	{ name: 'Tonga', code: 'TO' },
	{ name: 'Trinidad and Tobago', code: 'TT' },
	{ name: 'Tunisia', code: 'TN' },
	{ name: 'Turkey', code: 'TR' },
	{ name: 'Turkmenistan', code: 'TM' },
	{ name: 'Turks and Caicos Islands', code: 'TC' },
	{ name: 'Tuvalu', code: 'TV' },
	{ name: 'Uganda', code: 'UG' },
	{ name: 'Ukraine', code: 'UA' },
	{ name: 'United Arab Emirates', code: 'AE' },
	{ name: 'United Kingdom', code: 'GB' },
	{ name: 'United States', code: 'US' },
	{ name: 'United States Minor Outlying Islands', code: 'UM' },
	{ name: 'Uruguay', code: 'UY' },
	{ name: 'Uzbekistan', code: 'UZ' },
	{ name: 'Vanuatu', code: 'VU' },
	{ name: 'Venezuela', code: 'VE' },
	{ name: 'Viet Nam', code: 'VN' },
	{ name: 'Virgin Islands, British', code: 'VG' },
	{ name: 'Virgin Islands, U.S.', code: 'VI' },
	{ name: 'Wallis and Futuna', code: 'WF' },
	{ name: 'Western Sahara', code: 'EH' },
	{ name: 'Yemen', code: 'YE' },
	{ name: 'Zambia', code: 'ZM' },
	{ name: 'Zimbabwe', code: 'ZW' }
];

export const countryBlackList = [
	{ name: 'Iran', id: 'IR' },
	{ name: 'North Korea', id: 'KP' },
	{ name: 'Syria', id: 'SY' },
	{ name: 'Russia', id: 'RU' },
	{ name: 'China', id: 'CN' },
	{ name: 'Cuba', id: 'CU' },
];

export const MONTHS = [
	{
		name: 'January',
		short: 'Jan',
		number: 1,
		days: 31
	}, {
		name: 'February',
		short: 'Feb',
		number: 2,
		days: 28
	}, {
		name: 'March',
		short: 'Mar',
		number: 3,
		days: 31
	}, {
		name: 'April',
		short: 'Apr',
		number: 4,
		days: 30
	}, {
		name: 'May',
		short: 'May',
		number: 5,
		days: 31
	}, {
		name: 'June',
		short: 'Jun',
		number: 6,
		days: 30
	}, {
		name: 'July',
		short: 'Jul',
		number: 7,
		days: 31
	}, {
		name: 'August',
		short: 'Aug',
		number: 8,
		days: 31
	}, {
		name: 'September',
		short: 'Sep',
		number: 9,
		days: 30
	}, {
		name: 'October',
		short: 'Oct',
		number: 10,
		days: 31
	}, {
		name: 'November',
		short: 'Nov',
		number: 11,
		days: 30
	}, {
		name: 'December',
		short: 'Dec',
		number: 12,
		days: 31
	}
];
