import _ from 'lodash';

import { removeComment, removePost } from '../moderation/actions';

export const selectModerateComment = (state) => ({
	removingComment: _.get(state.moderation, 'removingComment') || {}
});

export const mapDispatchModerateCommentToProps = {
	removeComment
};

export const selectModeratePost = (state) => ({
	removingPost: _.get(state.moderation, 'removingPost') || {}
});

export const mapDispatchModeratePostToProps = {
	removePost,
};
