import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { compose, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import ChangeEmailForm from '../../components/changeEmailForm';
import UpdatePasswordForm from '../../components/forms/updatePasswordForm';
import HeadlineWrapper from '../../components/headlineWrapper';
import AuthenticatedPage from '../../components/layouts/authenticatedPage';
import Card from '../../components/lib/card';
import Headline from '../../components/lib/headline';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { mapDispatchUpdateAccountToProps, selectUpdateAccount } from '../../store/selectors/account';
import { selectSession } from '../../store/selectors/session';

import './account.scss';

export const enhance = compose(
	withToastManager,
	connect(
		createSelector(
			selectSession,
			selectUpdateAccount,
			(session, account) => ({
				...session,
				...account
			})
		),
		mapDispatchUpdateAccountToProps
	),
	withState('updatingEmail', 'setUpdatingEmail', false),
	withHandlers({
		handleUpdateAccount: (props) => (fields) => {
			return props.updateUser(props.sessionData.id, fields);
		},
		handleUpdatePassword: (props) => (fields) => {
			const {
				sessionData,
				updateUserPassword,
				toastManager
			} = props;

			return updateUserPassword(sessionData.id, fields)
			.then((data) => {
				if (!data.error) {
					toastManager.add('Password updated', { appearance: 'success', autoDismiss: true });
				} else {
					toastManager.add(_.get(data.error, 'response.data.message') || 'There was a problem updating your password', { appearance: 'error', autoDismiss: true });
				}
				return data;
			});
		}
	}),
	withHandlers({
		handleUpdateEmail: (props) => (fields) => {
			const { toastManager } = props;
			props.setUpdatingEmail(true);
			return props.handleUpdateAccount(fields)
			.then((data) => {
				props.setUpdatingEmail(false);
				if (!data.error) {
					toastManager.add('Email updated', { appearance: 'success', autoDismiss: true });
				} else {
					toastManager.add(_.get(data.error, 'response.data.message') || 'There was a problem updating your email', { appearance: 'error', autoDismiss: true });
				}
				return data;
			});
		},
	})
);

export function Account(props) {
	const {
		updatingUser,
		updatingUserPassword,
		sessionData,
		updatingEmail,
		handleUpdateEmail,
		handleUpdatePassword,
	} = props;
	return (
		<AuthenticatedPage
			{...props}
			className={classnames('account-page')}
			withContent
			pageName="account"
			requiresSession
			title="Your Account"
		>
			<HeadlineWrapper underline>
				<Headline tag="h1" weight={FONT_WEIGHTS.medium} size={FONT_SIZES.xl}>
					Account Settings
				</Headline>
			</HeadlineWrapper>
			<div className="card-column">
				<Card>
					<HeadlineWrapper underline={true}>
						<Headline tag="h3" as="h3">
							Update your email address
						</Headline>
					</HeadlineWrapper>
					<ChangeEmailForm
						onSubmit={handleUpdateEmail}
						loading={updatingEmail}
						formError={updatingUser.error}
						hasEmail={!!(sessionData.email && sessionData.email.length)}
						initialValues={{
							currentEmail: sessionData.email
						}}
					/>
				</Card>
				<Card>
					<HeadlineWrapper underline={true}>
						<Headline tag="h3" as="h3">
							Update your password
						</Headline>
					</HeadlineWrapper>
					<UpdatePasswordForm
						onSubmit={handleUpdatePassword}
						loading={updatingUserPassword.loading}
						formError={updatingUserPassword.error}
					/>
				</Card>
			</div>
		</AuthenticatedPage>
	);
}

export default enhance(Account);
