import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { renderInput } from '../../../lib/formHelpers';
import { Button } from '../../lib/button';
import Form from '../../lib/form';
import { ButtonRow, FormItem, FormRow } from '../../lib/formRow';

import './communityRuleForm.scss';

const FORM_NAME = 'community-rule-form';

function buildFormName(props) {
	if (!props.initialValues) {
		return FORM_NAME;
	}
	return `${FORM_NAME}-${_.get(props, 'initialValues.id')}`;
}

export const enhance = compose(
	withProps((props) => ({
		...props,
		form: buildFormName(props),
		formName: buildFormName(props)
	})),
	reduxForm({ destroyOnUnmount: true }),
	connect(
		createSelector(
			(state, ownProps) => ({ formValues: getFormValues(ownProps.formName)(state) }),
			(formValues) => ({
				...formValues
			})
		)
	),
	withHandlers({
		interceptSubmit: (props) => (fields) => {
			return props.onSubmit(fields);
		}
	})
);

const hasValidName = (name) => {
	if (!name || !name.length) {
		return 'Please name your rule';
	}
}

const hasValidRule = (rule) => {
	if (!rule || !rule.length) {
		return 'Please describe your rule';
	}
}

export function CommunityRuleForm(props) {
	const {
		interceptSubmit,
		handleSubmit,
		valid,
		loading,
		formError,
		onDelete,
		action
	} = props;
	const buttonProps = {
		loading: loading,
		size: 'regular'
	};
	if (!valid) {
		buttonProps.disabled = true;
	}
	return (
		<Form
			onSubmit={handleSubmit(interceptSubmit)}
			className={classnames('community-rule-form', props.className)}
		>
			<FormRow>
				<FormItem>
					<Field
						id={`title`}
						name={`title`}
						type="text"
						label="Rule"
						isRequired
						component={renderInput}
						helpText="Name of the rule"
						validate={[hasValidName]}
					/>
				</FormItem>
			</FormRow>
			<FormRow>
				<FormItem>
					<Field
						id={`rawRule`}
						name={`rawRule`}
						type="text"
						label="Rule details"
						inputType="textarea"
						isRequired
						component={renderInput}
						helpText="Details about the rule (markdown supported)"
						validate={[hasValidRule]}
					/>
				</FormItem>
			</FormRow>
			<ButtonRow>
				<Button
					{...buttonProps}
					size="small"
				>
					{action === 'update' ? 'Save' : 'Create'}
				</Button>
				{action === 'update' && (
					<Button
						size="small"
						display="danger-outline"
						type="button"
						onClick={onDelete}
					>
						Remove
					</Button>
				)}
			</ButtonRow>
			{!!(formError && formError.message) && (
				<FormRow>
					<FormItem>
						<div className="form-formError-message">
							{formError.message}
						</div>
					</FormItem>
				</FormRow>
			)}
		</Form>
	);
}

CommunityRuleForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
	onDelete: propTypes.func.isRequired,
	loading: propTypes.bool.isRequired,
	formError: propTypes.any,
	action: propTypes.oneOf(['create', 'update'])
};

CommunityRuleForm.defaultProps = {
	action: 'update'
};

export default enhance(CommunityRuleForm);
