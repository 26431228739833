import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, mapProps, withHandlers } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { renderInput } from '../../../lib/formHelpers';
import { mapDispatchCrawlUrlToProps, selectCrawlUrl } from '../../../store/selectors/data';
import { Button } from '../../lib/button';
import Form from '../../lib/form';
import { FormItem, FormRow } from '../../lib/formRow';

import './updateCommunityPostForm.scss';

const FORM_NAME = 'update-community-post';

export const enhance = compose(
	mapProps((props) => ({
		...props,
	})),
	reduxForm({ form: FORM_NAME, enableReinitialize: true }),
	connect(
		createSelector(
			(state) => ({ formValues: getFormValues(FORM_NAME)(state) }),
			selectCrawlUrl,
			(formValues, crawlUrl) => ({
				...formValues,
				...crawlUrl
			})
		),
		mapDispatchCrawlUrlToProps
	),
	withHandlers({
		interceptSubmit: (props) => (fields) => {
			props.onSubmit(fields);
		}
	})
);

const hasPostContent = (content, allValues, props) => {
	if (props.postType === 'text' && (!content || !_.trim(content.length))) {
		return 'Please provide content for your post';
	}
}

export function UpdateCommunityPostForm(props) {
	const {
		interceptSubmit,
		handleSubmit,
		valid,
		loading,
		formError,
		formValues,
	} = props;
	const buttonProps = {
		loading: loading,
		size: 'regular'
	};
	if (!valid) {
		buttonProps.disabled = true;
	}
	return (
		<Form
			onSubmit={handleSubmit(interceptSubmit)}
			className={classnames('update-community-post-form', props.className)}
		>
			<FormRow>
				<FormItem>
					<Field
						id="rawBody"
						name="rawBody"
						type="text"
						label="Content"
						isRequired={_.get(formValues, 'postType') === 'text'}
						inputType="textarea"
						component={renderInput}
						validate={[hasPostContent]}
					/>
				</FormItem>
			</FormRow>
			<FormRow>
				<FormItem>
					<Button {...buttonProps}>
						Save
					</Button>
				</FormItem>
			</FormRow>
			{!!(formError && formError.message) && (
				<FormRow>
					<FormItem>
						<div className="form-formError-message">
							{formError.message}
						</div>
					</FormItem>
				</FormRow>
			)}
		</Form>
	);
}

UpdateCommunityPostForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
	loading: propTypes.bool,
	formError: propTypes.any,
	postType: propTypes.oneOf(['text', 'link'])
};

export default enhance(UpdateCommunityPostForm);
