import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { FONT_SIZES } from '../../../lib/constants';
import { buildModifierClassFromProps } from '../../../lib/helpers';
import ErrorMessage from '../errorMessage';
import FormFieldLabel from '../formFieldLabel';
import InputDecoration from '../inputDecoration';

import './textInput.scss';
import Text from '../text';

const enhance = compose(
	withState('focused', 'setFocused', (props) => {
		if (props.value && props.value.length) {
			return true;
		}
		return false;
	}),
	withHandlers({
		handleFocusBlur: (props) => (focused, evt) => {
			props.setFocused(focused);
			if (typeof props.onFocus === 'function' && focused) {
				props.onFocus(evt);
			}
			if (typeof props.onBlur === 'function' && !focused) {
				props.onBlur(evt);
			}
		},
		handleAutocompleteResultSelected: (props) => (placeId) => {
			if (props.onResultSelect) {
				props.onResultSelect(placeId);
			}
		}
	}),
	lifecycle({
		componentDidMount() {
			if (this.props.value && this.props.value !== '') {
				this.props.setFocused(true);
			}
		}
	})
);

const modifierProps = ['size', 'type'];

function TextInput(props) {
	const { focused, handleFocusBlur, hasError, errorMessage, className, decoration, helpText } = props;
	const { decorationPosition, isRequired, isOptional } = props;
	const isFocused = !!focused || (props.value && props.value.length);
	const hasMessage = hasError && !!(errorMessage && errorMessage.length);
	const { autocompleteResults, showAutocompleteResults, handleAutocompleteResultSelected } = props;

	const fieldProps = {
		..._.omit(props, [
			'className', 'handleFocusBlur', 'focused', 'setFocused', 'withLabel',
			'errorMessage', 'hasError', 'showAutocompleteResults', 'autocompleteResults',
			'onResultSelect', 'decorationPosition', 'decoration', 'isRequired', 'isOptional',
			'inputType', 'helpText', 'handleAutocompleteResultSelected'
		]),
		className: classnames('input', props.className, ...buildModifierClassFromProps(modifierProps, props), {
			'has-error': hasMessage
		}),
		onFocus: (evt) => {
			handleFocusBlur(true, evt);
		},
		onBlur: (evt) => {
			handleFocusBlur(false, evt);
		}
	};

	const TextInputOmitProps = ['maskPlaceholder', 'maskChar', 'mask', 'label'];
	return (
		<div className={classnames('text-input', className, `decoration-position-${decorationPosition || 'outside'}`)}>
			<div className={classnames('text-input-wrapper', { focused: isFocused, 'read-only': props.readOnly, 'has-error': hasMessage })}>
				<FormFieldLabel
					name={props.name}
					isRequired={isRequired}
					isOptional={isOptional}
				>
					{props.label || props.placeholder}
				</FormFieldLabel>
				<InputDecoration decoration={decoration}>
					{props.inputType === 'input' && (
						props.mask && props.mask.length ? (
							<InputMask {..._.omit(fieldProps, ['maskPlaceholder'])} />
						) : (
							<input {..._.omit(fieldProps, TextInputOmitProps)} />
						)
					)}
					{props.inputType === 'textarea' && (
						<textarea {..._.omit(fieldProps, TextInputOmitProps)} />
					)}
				</InputDecoration>
				{!!helpText && (
					<Text
						className="help-text"
						transform="italic"
						size={FONT_SIZES.sm}
					>
						{helpText}
					</Text>
				)}
			</div>
			{!!(showAutocompleteResults === true && autocompleteResults && autocompleteResults.length) && (
				<div className="autocomplete-results-container">
					{_.map(autocompleteResults, (res) => (
						<li className="result" key={res.id} onClick={() => handleAutocompleteResultSelected(res.id)}>
							{res.display}
						</li>
					))}
				</div>
			)}
			{hasError && !!(errorMessage && errorMessage.length) && (
				<ErrorMessage size="small">
					{errorMessage}
				</ErrorMessage>
			)}
		</div>
	);
}

TextInput.propTypes = {
	size: propTypes.oneOf(['small', 'regular', 'medium', 'large']),
	className: propTypes.string,
	inputType: propTypes.oneOf(['input', 'textarea']),
	errorMessage: propTypes.string,
	hasError: propTypes.bool,
	decoration: propTypes.any,
	decorationPosition: propTypes.oneOf(['outside', 'inside']),
	focused: propTypes.bool,
	isRequired: propTypes.bool,
	isOptional: propTypes.bool
};

TextInput.defaultProps = {
	inputType: 'input',
	size: 'regular',
	decorationPosition: 'outside'
};

export default enhance(TextInput);
