import classnames from 'classnames';
import React from 'react';
import { compose } from 'recompose';
import Headline from '../../components/lib/headline';
import Page from '../../components/page';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';

import './comingSoon.scss';

export const enhance = compose(

);

export function ComingSoonPage(props) {
	return (
		<Page
			{...props}
			className={classnames('reset-password-page')}
			withContent
			pageName="resetPassword"
			title="Reset Password"
		>
			<Headline tag="h1" size={FONT_SIZES.xl5} weight={FONT_WEIGHTS.bold}>
				Coming soon...
			</Headline>
		</Page>
	);
}

export default enhance(ComingSoonPage);
