import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import { Link as RRLink, NavLink as RRNavLink } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { compose, mapProps, withHandlers } from 'recompose';
import {
	ANALYTICS_OMIT_PROPS,
	ATTRIBUTED_SEGMENT_OMIT_PROPS,
	withSegmentClickInterceptor,
	withSegmentContext
} from '../../../lib/analytics';
import { buildModifierClassFromProps, hasSegment } from '../../../lib/helpers';
import Clipboard from 'react-clipboard.js';

import './button.scss';

export const enhance = compose(
	mapProps((props) => {
		const segmentConfig = {
			action: 'click',
			targetType: 'button',
			...(props.segmentConfig || {})
		};
		return {
			...props,
			segmentConfig
		};
	}),
	withSegmentContext(),
	withSegmentClickInterceptor(),
	withToastManager,
	withHandlers({
		handleCopySuccess: (props) => () => {
			const { toastManager } = props;
			toastManager.add('Copied to clipboard', { appearance: 'success', autoDismiss: true });

			if (props.copyOptions && typeof props.copyOptions.onSuccess === 'function') {
				props.copyOptions.onSuccess();
			}
		}
	}),
);

const modifierProps = ['display', 'size', 'kind'];

export function Button(props) {
	const mergedProps = {
		..._.omit(props, [
			'isLink', 'isNavLink', 'getTrackData', 'interceptOnClick', 'copyOptions', 'isCopyButton',
			'toastManager', 'handleCopySuccess', ...modifierProps, ...ANALYTICS_OMIT_PROPS, ...ATTRIBUTED_SEGMENT_OMIT_PROPS
		]),
		onClick: props.onClick
	};

	if (props.loading) {
		mergedProps.disabled = true;
	}
	if (mergedProps.disabled) {
		mergedProps.display = 'disabled';
	}

	const builtClass = classnames('button', props.className, ...buildModifierClassFromProps(modifierProps, props), {
		loading: !!props.loading,
		'is-link': props.isLink
	});

	if (props.isLink) {
		const linkProps = {
			..._.omit(mergedProps, ['loading']),
			className: classnames(builtClass, 'is-link')
		};
		if (_.isString(props.href) && props.href.length && props.href.indexOf('http') === 0) {
			return (<a {...linkProps} />);
		}
		linkProps.to = linkProps.href;
		if (props.isNavLink) {
			return (<RRNavLink {..._.omit(linkProps, ['href', 'getTrackData'])}/>);
		}
		return (
			<RRLink {..._.omit(linkProps, ['href', 'getTrackData'])} />
		);
	}
	if (props.isCopyButton === true) {
		return (
			<Clipboard
				{..._.omit(mergedProps, ['loading', 'getTrackData'])} className={classnames(builtClass, 'clipboard-button')}
				{...(props.copyOptions || {})}
				onSuccess={props.handleCopySuccess}
			/>
		);
	}
	return (
		<button {..._.omit(mergedProps, ['loading', 'getTrackData'])} className={builtClass} />
	);
}

Button.propTypes = {
	size: propTypes.oneOf(['xs', 'small', 'regular', 'medium', 'large']),
	display: propTypes.oneOf(['primary', 'secondary', 'secondary-outline', 'tertiary', 'primary-outline', 'danger-outline', 'white-indigo']),
	isLink: propTypes.bool,
	loading: propTypes.bool,
	isNavLink: propTypes.bool,
	getTrackData: propTypes.func,
	isCopyButton: propTypes.bool,
	copyOptions: propTypes.object
};

Button.defaultProps = {
	size: 'regular',
	display: 'primary',
	isLink: false,
	loading: false,
	isNavLink: false
};

export default enhance(Button);
