import classnames from 'classnames';
import { isString, omit } from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import { Link as RRLink, NavLink as RRNavLink } from 'react-router-dom';
import { buildModifierClassFromProps } from '../../../lib/helpers';

import './link.scss';

const modifierProps = ['size', 'weight'];

export function Link(props) {
	const combinedProps = {
		...omit(props, ['linkStyle', 'underline', 'isNavLink', 'activeClassName', 'partiallyActive']),
		className: classnames('link', props.className, ...buildModifierClassFromProps(modifierProps, props))
	};
	if (props.isNavLink || (props.to || '').indexOf('/') === 0) {
		combinedProps.exact = true;
		return (<RRNavLink {...combinedProps} className={classnames(combinedProps.className, 'nav-link')}/>);
	}
	if (isString(props.to) && props.to.length && props.to.charAt(0) !== '/') {
		combinedProps.href = props.to;
		return (<a {...combinedProps} />);
	}
	return (
		<RRLink {...combinedProps} />
	);
}

Link.propTypes = {
	size: propTypes.oneOf(['xs', 'sm', 'regular', 'lg', 'xl', '2xl']),
	weight: propTypes.oneOf(['thin', 'light', 'normal', 'bold', 'black']),
	isNavLink: propTypes.bool
};

Link.defaultProps = {
	size: 'regular',
	weight: 'normal',
	isNavLink: false
};

export default Link;
