import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { FONT_SIZES } from '../../lib/constants';
import { formatCommunitySubscribers, buildCommunityUrl } from '../../lib/helpers';
import Link from '../lib/link';
import Text from '../lib/text';

import './communityListItem.scss';

const enhance = compose()

export function CommunityListItem(props) {
	const {
		community
	} = props;

	return (
		<div className={classnames('community-list-item', props.className)}>
			<Link to={buildCommunityUrl(community.displaySlug)}>
				{community.displaySlug}
			</Link>
			<Text size={FONT_SIZES.sm}>
				({formatCommunitySubscribers(community.subscriberCount)})
			</Text>
		</div>
	);
}

CommunityListItem.propTypes = {
	community: propTypes.shape({
		id: propTypes.string.isRequired,
		displaySlug: propTypes.string.isRequired
	}),
	sessionData: propTypes.object,
};

export default enhance(CommunityListItem);
