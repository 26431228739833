import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { MONTHS } from '../../../lib/constants';
import { Button } from '../../lib/button';
import Form from '../../lib/form';
import { ButtonRow, FormItem, FormRow } from '../../lib/formRow';
import SelectBox from '../../lib/selectBox';

import './userDateOfBirthForm.scss';

const FORM_NAME = 'user-date-of-birth-form';

export const enhance = compose(
	reduxForm({ form: FORM_NAME }),
	connect(
		createSelector(
			(state) => ({ formValues: getFormValues(FORM_NAME)(state) }),
			(formValues) => ({
				...formValues
			})
		)
	),
	withHandlers({
		interceptSubmit: (props) => (fields) => {
			const dateOfBirth = `${fields.year}-${fields.month}-${fields.day}`;

			return props.onSubmit({ dateOfBirth });
		}
	})
);

const hasValidDate = (d) => {
	if (typeof d === 'undefined' || !_.isNumber(d)) {
		return 'Select a date';
	}
}

export function UserDateOfBirthForm(props) {
	const {
		onSubmit,
		handleSubmit,
		valid,
		anyTouched,
		loading,
		formError,
		interceptSubmit,
		formValues
	} = props;
	const buttonProps = {
		size: 'regular',
		display: 'primary',
		loading
	};
	if (!valid) {
		buttonProps.disabled = true;
	}

	const days = (formValues && formValues.month) ? _.range(1, MONTHS[formValues.month - 1].days + 1) : [];

	return (
		<div className={classnames('user-date-of-birth-form')}>
			<Form onSubmit={handleSubmit(interceptSubmit)} className={classnames(`${props.color}-text`)}>
				<FormRow className="date-selects">
					<Field
						name="month"
						component={SelectBox}
						type="text"
						label="Month"
						options={_.map(MONTHS, (mo) => ({
							value: mo.number, label: mo.name
						}))}
						isRequired
						validate={[hasValidDate]}
					/>
					<Field
						name="day"
						component={SelectBox}
						type="text"
						label="Day"
						options={_.map(days, (d) => ({
							value: d, label: d
						}))}
						isRequired
						validate={[hasValidDate]}
					/>
					<Field
						name="year"
						component={SelectBox}
						type="text"
						label="Year"
						options={_.range(1900, (new Date()).getUTCFullYear() + 1).reverse().map((year) => ({
							value: year, label: year
						}))}
						isRequired
						validate={[hasValidDate]}
					/>
				</FormRow>
				<ButtonRow>
					<Button {...buttonProps} size="small">
						Save
					</Button>
				</ButtonRow>
				{!!(formError && formError.message) && (
					<FormRow>
						<FormItem>
							<div className="form-formError-message">
								{formError.message}
							</div>
						</FormItem>
					</FormRow>
				)}
			</Form>
		</div>
	);
}

UserDateOfBirthForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
	loading: propTypes.bool,
	formError: propTypes.any,
};

export default enhance(UserDateOfBirthForm);
