import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { ACCOUNT } from '../account/actions';
import { getFailureAction, getRequestAction, getSuccessAction } from '../helpers';

import { SESSION } from './actions';

export const initialState = {
	sessionData: null,
	getSession: {
		loading: false,
		error: null
	},
	createSession: {
		loading: false,
		error: null
	},
	requestingVerificationLink: {
		loading: false,
		error: null
	},
	creatingSubscription: {
		loading: false,
		error: null
	},
	deletingSubscription: {
		loading: false,
		error: null
	}
};

export default handleActions({
	[SESSION.GET_ACTIVE_SESSION_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			getSession: {
				...state.getSession,
				loading: true,
				error: null
			}
		};
	},
	[SESSION.GET_ACTIVE_SESSION_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			sessionData: payload.sessionData,
			getSession: {
				...state.getSession,
				loading: false,
				error: null
			}
		};
	},
	[SESSION.GET_ACTIVE_SESSION_FAILURE]: (state, { error }) => {
		return {
			...state,
			getSession: {
				...state.getSession,
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	[SESSION.CREATE_SESSION_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			createSession: {
				...state.createSession,
				loading: true,
				error: null
			}
		};
	},
	[SESSION.CREATE_SESSION_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			sessionData: payload.sessionData,
			createSession: {
				...state.createSession,
				loading: false,
				error: null
			}
		}
	},
	[SESSION.CREATE_SESSION_FAILURE]: (state, { error }) => {
		return {
			...state,
			createSession: {
				...state.createSession,
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	[SESSION.REQUEST_VERIFICATION_LINK_REQUEST]: (state, { payload, options }) => {
		return {
			...state,
			requestingVerificationLink: {
				...state.requestingVerificationLink,
				loading: true,
				error: null
			}
		};
	},
	[SESSION.REQUEST_VERIFICATION_LINK_SUCCESS]: (state, { payload, options }) => {
		return {
			...state,
			requestingVerificationLink: {
				...state.requestingVerificationLink,
				loading: false,
				error: null
			}
		}
	},
	[SESSION.REQUEST_VERIFICATION_LINK_FAILURE]: (state, { error }) => {
		return {
			...state,
			requestingVerificationLink: {
				...state.requestingVerificationLink,
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	[ACCOUNT.CREATE_ACCOUNT_SUCCESS]: (state, { payload }) => {
		return {
			...state,
			sessionData: payload.sessionData,
		}
	},
	[ACCOUNT.UPDATE_USER_SUCCESS]: (state, { payload }) => {
		return {
			...state,
			sessionData: {
				...state.sessionData,
				...payload
			},
		}
	},
	// Create subscription
	[getRequestAction(SESSION.CREATE_SUBSCRIPTION)]: (state, { payload, options }) => {
		return {
			...state,
			creatingSubscription: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(SESSION.CREATE_SUBSCRIPTION)]: (state, { payload, options }) => {
		const { id, communityId } = payload;

		const currSubscriptions = _.get(state.sessionData, 'subscriptions') || [];

		currSubscriptions.push({ id, communityId });

		return {
			...state,
			creatingSubscription: {
				loading: false,
				error: null
			},
			sessionData: {
				...state.sessionData,
				subscriptions: currSubscriptions,
			}
		};
	},
	[getFailureAction(SESSION.CREATE_SUBSCRIPTION)]: (state, { error, options }) => {
		return {
			...state,
			creatingSubscription: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
	// Delete subscription
	[getRequestAction(SESSION.DELETE_SUBSCRIPTION)]: (state, { payload, options }) => {
		return {
			...state,
			deletingSubscription: {
				loading: true,
				error: null,
			}
		};
	},
	[getSuccessAction(SESSION.DELETE_SUBSCRIPTION)]: (state, { payload, options }) => {
		const { id } = payload;

		const currSubscriptions = _.get(state.sessionData, 'subscriptions') || [];

		_.remove(currSubscriptions, (s) => s.id === id);

		return {
			...state,
			deletingSubscription: {
				loading: false,
				error: null
			},
			sessionData: {
				...state.sessionData,
				subscriptions: currSubscriptions,
			}
		};
	},
	[getFailureAction(SESSION.DELETE_SUBSCRIPTION)]: (state, { error, options }) => {
		return {
			...state,
			deletingSubscription: {
				loading: false,
				error: _.get(error, 'response.data'),
			}
		};
	},
}, initialState);
