import _ from 'lodash';

import {
	createPostComment,
	getAllPosts,
	getPostComments,
	updatePostContent,
	upsertCommentVote,
	upsertPostVote,
	getUserComment,
	updatePostComment
} from '../posts/actions';

export const selectAllPosts = (state, props) => ({
	gettingAllPosts: _.get(state, 'posts.gettingAllPosts') || {},
	postsCursor: _.get(state, `posts.posts.cursor`) || {},
	posts: _.map(_.get(state, `posts.posts.sort[${props.sort}]`), (postId) => {
		return _.get(state, `posts.postsById[${postId}]`);
	})
});

export const mapDispatchAllPostsToProps = {
	getAllPosts
};

export const selectCreatePostComment = (state) => ({
	creatingPostComment: _.get(state.posts, 'creatingPostComment') || {}
});

export const mapDispatchCreatePostCommentToProps = {
	createPostComment
};

export const selectPostComments = (state, props) => ({
	gettingPostComments: _.get(state.posts, 'gettingPostComments') || {},
	commentUsers: _.get(state.posts, `postCommentsByPostShortId[${props.postShortId}].users`) || {},
	postComments: _.get(state.posts, `postCommentsByPostShortId[${props.postShortId}].commentsById`) || {},
	commentTree: _.get(state.posts, `postCommentsByPostShortId[${props.postShortId}].commentTree`) || [],
	userCommentVotes: _.get(state.posts, `userCommentVotesByCommentId`) || {}
});

export const mapDispatchGetPostCommentsToProps = {
	getPostComments,
};

export const selectEditPostComment = (state, props) => ({
	gettingUserComment: _.get(state.posts, 'gettingUserComment') || {},
	updatingPostComment: _.get(state.posts, 'updatingPostComment') || {},
});

export const mapDispatchEditPostCommentToProps = {
	getUserComment,
	updatePostComment
};

export const selectUpsertingPostVote = (state) => ({
	upsertingPostVote: _.get(state.posts, 'upsertingPostVote') || {}
});

export const mapDispatchUpsertPostVoteToProps = {
	upsertPostVote
};

export const selectUpsertingCommentVote = (state) => ({
	upsertingCommentVote: _.get(state.posts, 'upsertingCommentVote') || {}
});

export const mapDispatchUpsertCommentVoteToProps = {
	upsertCommentVote
};

export const selectUpdatePostContent = (state) => ({
	updatingPostContent: _.get(state.posts, 'updatingPostContent') || {},
});

export const mapDispatchUpdatePostContentToProps = {
	updatePostContent
};
