import { generateCRUDActions, spreadCRUDActions } from '../helpers';
import { CALL_API } from '../middleware/api';

const namespace = 'MODERATION';

export const MODERATION = {
	REMOVE_COMMENT: generateCRUDActions(namespace, 'REMOVE_COMMENT'),
	REMOVE_POST: generateCRUDActions(namespace, 'REMOVE_POST'),
};

export function removeComment(communityId, postId, commentId, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(MODERATION.REMOVE_COMMENT),
			endpoint: `/communities/${communityId}/posts/${postId}/comments/${commentId}/remove`,
			method: 'put',
			data: {},
			options: {
				...options,
				communityId,
				postId,
				commentId
			}
		}
	};
}

export function removePost(communityId, postId, options = {}) {
	return {
		[CALL_API]: {
			types: spreadCRUDActions(MODERATION.REMOVE_POST),
			endpoint: `/communities/${communityId}/posts/${postId}/remove`,
			method: 'put',
			data: {},
			options: {
				...options,
				communityId,
				postId,
			}
		}
	};
}
