import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import './cardBody.scss';

export function CardBody(props) {
	const {
		className,
		children
	} = props;

	return (
		<div className={classnames('card-body', className)}>
			{children}
		</div>
	);
}

CardBody.propTypes = {
	className: propTypes.string,
	children: propTypes.any
};

export default CardBody;
