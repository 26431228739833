import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, mapProps, withHandlers, withState } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { createSelector } from 'reselect';
import { renderInput } from '../../../lib/formHelpers';
import { mapDispatchCrawlUrlToProps, selectCrawlUrl } from '../../../store/selectors/data';
import { Button } from '../../lib/button';
import Form from '../../lib/form';
import { FormItem, FormRow } from '../../lib/formRow';
import SelectBox from '../../lib/selectBox';
import validator from 'validator';

import './createCommunityPostForm.scss';

const FORM_NAME = 'create-community-post';

export const enhance = compose(
	mapProps((props) => ({
		...props,
		initialValues: {
			postType: 'text',
		}
	})),
	reduxForm({ form: FORM_NAME, enableReinitialize: true }),
	connect(
		createSelector(
			(state) => ({ formValues: getFormValues(FORM_NAME)(state) }),
			selectCrawlUrl,
			(formValues, crawlUrl) => ({
				...formValues,
				...crawlUrl
			})
		),
		mapDispatchCrawlUrlToProps
	),
	withState('crawlingUrl', 'setCrawlingUrl', false),
	withHandlers({
		handleUrlBlur: (props) => (url) => {
			const { crawlUrl, setCrawlingUrl } = props;
			const { fullLink, title } = props.formValues;

			if (!validator.isURL(fullLink, validateUrlOptions)) {
				return;
			}

			if (title && title.length) {
				return;
			}

			setCrawlingUrl(true);

			return crawlUrl(fullLink)
			.then((data) => {
				setCrawlingUrl(false);

				if (!data.error) {
					const title = _.get(data, 'payload.title') || '';
					if (title && title.length) {
						props.change('title', title);
					}
				}
			});
		},
		interceptSubmit: (props) => (fields) => {
			props.onSubmit(fields);
		}
	})
);

const hasValidTitle = (title) => {
	if (!title || !_.trim(title.length)) {
		return 'Please provide a title';
	}
}

const hasPostContent = (content, allvalues) => {
	if (allvalues.postType === 'text' && (!content || !_.trim(content.length))) {
		return 'Please provide content for your post';
	}
}

const validateUrlOptions = {
	require_valid_protocol: true,
	protocols: ['http', 'https']
};

const hasValidLink = (link) => {
	if (!_.isString(link) || !link || !link.length) {
		return 'Please provide a link for a link post.';
	}

	if (!validator.isURL(link, validateUrlOptions)) {
		return 'Please provide a valid link';
	}
};

export function CreateCommunityPostForm(props) {
	const {
		interceptSubmit,
		handleSubmit,
		valid,
		loading,
		formError,
		formValues,
		handleUrlBlur,
		crawlingUrl
	} = props;
	const buttonProps = {
		loading: loading,
		size: 'regular'
	};
	if (!valid) {
		buttonProps.disabled = true;
	}
	return (
		<Form
			onSubmit={handleSubmit(interceptSubmit)}
			className={classnames('create-community-post-form', props.className)}
		>
			<FormRow>
				<FormItem>
					<Field
						name="postType"
						component={SelectBox}
						type="text"
						label="Post Type"
						options={[
							{ value: 'text', label: 'Text' },
							{ value: 'link', label: 'Link' },
						]}
						isRequired
					/>
				</FormItem>
			</FormRow>
			{_.get(formValues, 'postType') === 'link' && (
				<FormRow>
					<FormItem>
						<Field
							id="fullLink"
							name="fullLink"
							label="Link"
							type="text"
							isRequired
							component={renderInput}
							validate={[hasValidLink]}
							onBlur={handleUrlBlur}
						/>
					</FormItem>
				</FormRow>
			)}
			<FormRow>
				<FormItem>
					<Field
						id="title"
						name="title"
						label="Title"
						type="text"
						isRequired
						component={renderInput}
						validate={[hasValidTitle]}
						readOnly={crawlingUrl === true}
					/>
				</FormItem>
			</FormRow>
			<FormRow>
				<FormItem>
					<Field
						id="rawBody"
						name="rawBody"
						type="text"
						label="Content"
						isRequired={_.get(formValues, 'postType') === 'text'}
						inputType="textarea"
						component={renderInput}
						validate={[hasPostContent]}
					/>
				</FormItem>
			</FormRow>
			<FormRow>
				<FormItem>
					<Button {...buttonProps}>
						Create
					</Button>
				</FormItem>
			</FormRow>
			{!!(formError && formError.message) && (
				<FormRow>
					<FormItem>
						<div className="form-formError-message">
							{formError.message}
						</div>
					</FormItem>
				</FormRow>
			)}
		</Form>
	);
}

CreateCommunityPostForm.propTypes = {
	onSubmit: propTypes.func.isRequired,
	loading: propTypes.bool,
	formError: propTypes.any,
};

export default enhance(CreateCommunityPostForm);
