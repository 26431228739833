import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import Card from '../../components/lib/card';
import Paragraph from '../../components/lib/paragraph';
import Page from '../../components/page';
import RequestPasswordResetForm from '../../components/requestPasswordResetForm';
import { mapDispatchResetPasswordToProps, selectResetPassword } from '../../store/selectors/account';

import './requestResetPasswordPage.scss';

export const enhance = compose(
	connect(
		createSelector(
			selectResetPassword,
			(reset) => ({
				...reset,
			})
		),
		mapDispatchResetPasswordToProps
	),
	withState('passwordResetRequested', 'setPasswordResetRequested', false),
	withHandlers({
		handleResetPassword: (props) => (fields) => {
			return props.requestPasswordReset(fields.email)
			.then((data) => {
				props.setPasswordResetRequested(true);
				return data;
			});
		},
	}),
);

export function RequestResetPasswordPage(props) {
	const { handleResetPassword, requestingPasswordReset, passwordResetRequested } = props;
	return (
		<Page
			{...props}
			className={classnames('request-reset-password-page')}
			withContent
			pageName="resetPassword"
			title="Reset Password"
		>
			<Card>
				{passwordResetRequested ? (
					<>
						<Paragraph>
							Your reset request has been submitted. If you have an account, an email with a reset link will be sent.
						</Paragraph>
					</>
				) : (
					<RequestPasswordResetForm
						onSubmit={handleResetPassword}
						loading={requestingPasswordReset.loading}
						formError={requestingPasswordReset.error}
					/>
				)}
			</Card>
		</Page>
	);
}

export default enhance(RequestResetPasswordPage);
