import qs from 'querystring';
import React from 'react';
import { compose, lifecycle, mapProps, withHandlers } from 'recompose';
import { providePageContext, withAttributedSegmentTracking } from '../../../lib/analytics';

function buildPage(page, search) {
	const queryParams = _.transform(search && search.length ? qs.parse((search || '').replace(/^\?/, '')) : {}, (pickedParams, val, key) => {
		if (key.match(/^(q|utm_.*)$/)) {
			pickedParams[key] = val;
		}
		return pickedParams;
	}, {});
	const queryString = !_.isEmpty(queryParams) ? `?${qs.stringify(queryParams)}` : '';
	return `${page}${queryString}`;
}

export const withTracker = compose(
	withAttributedSegmentTracking,
	mapProps((props) => ({
		...props,
		pageContext: {
			pageName: _.get(props, 'route.name'),
			pagePath: _.get(props, 'route.path'),
			pageUrlParams: { ...(_.get(props, 'match.params') || {}) }
		}
	})),
	providePageContext,
	withHandlers({
		trackPage: (props) => () => {
			props.segmentPage(props.pageContext || {}, {
				integrations: {
					'Facebook Pixel': false
				}
			});
		}
	}),
	lifecycle({
		componentDidMount() {
			const { trackPage, location } = this.props;
			const page = location.pathname;
			const search = location.search;

			trackPage(buildPage(page, search));
		},
		componentDidUpdate(prevProps) {
			const currentPage = prevProps.location.pathname;

			const { trackPage, location } = this.props;
			const nextPage = location.pathname;
			const nextSearch = location.search;

			if (currentPage !== nextPage) {
				trackPage(buildPage(nextPage, nextSearch));
			}
		}
	})
);
