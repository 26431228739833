import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { compose, lifecycle, mapProps, withHandlers, withState } from 'recompose';
import { createSelector } from 'reselect';
import CreateCommunityForm from '../../components/forms/createCommunityForm';
import HeadlineWrapper from '../../components/headlineWrapper';
import AuthenticatedPage from '../../components/layouts/authenticatedPage';
import Card from '../../components/lib/card';
import CardBody from '../../components/lib/cardBody';
import Headline from '../../components/lib/headline';
import ManageCommunityRules from '../../components/manageCommunityRules';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { selectApp, selectAssetUrl } from '../../store/selectors/app';
import {
	mapDispatchCommunityToProps,
	mapDispatchManageCommunityToProps,
	selectCommunity,
	selectManageCommunity
} from '../../store/selectors/community';

import './moderateCommunity.scss';

export const enhance = compose(
	withToastManager,
	mapProps((props) => ({
		...props,
		displaySlug: _.get(props, 'match.params.displaySlug')
	})),
	connect(
		createSelector(
			selectApp,
			selectAssetUrl,
			selectCommunity,
			selectManageCommunity,
			(app, asset, community, communityRules) => ({
				...app,
				...asset,
				...community,
				...communityRules,
			})
		),
		{
			...mapDispatchCommunityToProps,
			...mapDispatchManageCommunityToProps
		}
	),
	withState('loadedCommunity', 'setLoadedCommunity', (props) => {
		return !!props.community;
	}),
	withState('loadedCommunityRules', 'setLoadedCommunityRules', false),
	withHandlers({
		handleUpdateCommunity: (props) => (fields) => {
			const { community, updateCommunity, toastManager } = props;

			return updateCommunity(community.id, fields)
			.then((data) => {
				if (!data.error) {
					toastManager.add('Community updated', { appearance: 'success', autoDismiss: true });
				} else {
					toastManager.add(_.get(data.error, 'response.data.message') || 'There was a problem updating the community', { appearance: 'error', autoDismiss: true });
				}
				return data;
			});
		}
	}),
	lifecycle({
		componentDidMount() {
			const {
				displaySlug,
				setLoadedCommunity,
				getCommunityBySlug,
				loadedCommunity,
				sessionData,
				history,
				listCommunityRulesByCommunitySlug,
				setLoadedCommunityRules
			} = this.props;

			const userIsModOf = _.map(sessionData.moderators, (m) => m.communityId);

			if (!loadedCommunity) {
				getCommunityBySlug(displaySlug, { includeDescription: true })
				.then((data) => {
					if (!userIsModOf.includes(data.payload.id)) {
						history.push('/');
						return;
					}
					setLoadedCommunity(true);

					return data;
				});
			}
			listCommunityRulesByCommunitySlug(displaySlug)
			.then((rulesData) => {
				setLoadedCommunityRules(true);
				return rulesData;
			})
		}
	})
);

export function ModerateCommunityPage(props) {
	const {
		community,
		loadedCommunityRules,
		loadedCommunity,
		handleUpdateCommunity,
		updatingCommunity
	} = props;
	return (
		<AuthenticatedPage
			{...props}
			className={classnames('moderate-community-page')}
			withContent
			pageName="moderate-community"
			requiresSession
			title="ModerateCommunity"
		>
			<Card className="update-community-wrapper">
				<CardBody>
					<HeadlineWrapper underline>
						<Headline tag="h2" size={FONT_SIZES.xl} weight={FONT_WEIGHTS.bold}>
							Update Community Details
						</Headline>
					</HeadlineWrapper>
					{(loadedCommunity && loadedCommunityRules) && (
						<CreateCommunityForm
							action="update"
							onSubmit={handleUpdateCommunity}
							formError={updatingCommunity.error}
							loading={updatingCommunity.loading}
							initialValues={{
								...community,
								rawDescription: _.get(community, 'description.rawDescription')
							}}
						/>
					)}
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					{(loadedCommunity && loadedCommunityRules) && (
						<ManageCommunityRules
							community={community}
							rules={community.rules}
						/>
					)}
				</CardBody>
			</Card>
		</AuthenticatedPage>
	);
}

export default enhance(ModerateCommunityPage);
