import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { compose, lifecycle, withHandlers } from 'recompose';
import { createSelector } from 'reselect';
import { formatFullName, hasHelpscout, hasSegment } from '../../lib/helpers';
import { hasRollbar } from '../../lib/rollbar/rollbar';
import { selectApp } from '../../store/selectors/app';

import './appWrapper.scss';
import { selectSession } from '../../store/selectors/session';

export const withAppWrapper = compose(
	connect(
		createSelector(
			selectApp,
			selectSession,
			(app, session) => ({
				...app,
				...session
			})
		)
	),
	withHandlers({
		handleIdentify: (props) => () => {
			const { sessionData } = props;
			hasSegment(() => {
				window.analytics.identify(sessionData.id, {
					username: sessionData.username,
					email: sessionData.email,
					verified: sessionData.verified,
					signupDate: sessionData.createdAt,
					deleted: sessionData.deleted,
					deletedAt: sessionData.deletedAt,
					accountState: _.get(sessionData, 'accountState.state'),
				});
			});
			hasRollbar((rollbar) => {
				if (sessionData.hasSession) {
					rollbar.configure({
						payload: {
							person: {
								id: sessionData.id,
								email: sessionData.email
							}
						}
					});
				}
			});
			hasHelpscout(() => {
				if (sessionData.hasSession) {
					window.Beacon('identify', {
						name: formatFullName(sessionData.firstName, sessionData.lastName),
						email: sessionData.email,
						avatar: _.get(sessionData, 'profile.avatar.url') || sessionData.gravatarUrl
					});
				}
			});
		}
	}),
	lifecycle({
		componentDidMount() {
			const { sessionData, handleIdentify } = this.props;
			if (sessionData.hasSession) {
				handleIdentify();
			}
		}
	})
);

