import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { componentFromProp, defaultProps } from 'recompose';
import { FONT_STYLES } from '../../../lib/constants';
import { buildModifierClassFromProps } from '../../../lib/helpers';

import './headline.scss';

export const modifierProps = ['color', 'weight', 'size'];

export function Headline(props) {
	const InnerHeadline = defaultProps({
		tag: props.tag,
		onClick: props.onClick
	})(componentFromProp('tag'));

	const style = `headline-${(props.tag)}`;
	return (
		<InnerHeadline
			className={classnames(props.className, 'headline', {
				underline: props.underline,
				centered: props.centered,
			}, style, ...buildModifierClassFromProps(modifierProps, props))}
		>
			{props.children}
		</InnerHeadline>
	);
}

Headline.propTypes = {
	tag: propTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	underline: propTypes.bool,
	centered: propTypes.bool,
	weight: propTypes.oneOf(FONT_STYLES.weights),
	color: propTypes.oneOf(FONT_STYLES.colors),
	size: propTypes.oneOf(FONT_STYLES.sizes)
};

Headline.defaultProps = {
	underline: false,
	centered: false,
	weight: 'medium',
	tag: 'h3'
};

export default Headline;
