/**
 * Expects:
 * - showLoginSignupModal, setShowLoginSignupModal
 * - handlePostVote(post, vote)
 * - upsertCommentVote(postId, commentId, vote)
 */
export const handleLoginSignupVote = (props) => () => {
	const {
		showLoginSignupModal,
		setShowLoginSignupModal,
		handlePostVote,
		upsertCommentVote
	} = props;

	if (!showLoginSignupModal) {
		return;
	}

	const { action } = showLoginSignupModal;

	setShowLoginSignupModal(false);

	if (action === 'vote') {
		const { vote, post } = showLoginSignupModal;
		return handlePostVote(post, vote);
	}
	if (action === 'commentVote') {
		const { postId, commentId, vote } = showLoginSignupModal;
		return upsertCommentVote(postId, commentId, vote);
	}

}
