import classnames from 'classnames';
import _ from 'lodash';
import propTypes from 'prop-types';
import React from 'react';

import CommunityRuleListItem from '../communityRuleListItem';

import './communityRuleList.scss';

export function CommunityRuleList(props) {
	const sortedRules = _.sortBy(props.rules, (r) => r.position);
	return (
		<div className={classnames('community-rule-list', props.className)}>
			{_.map(sortedRules, (r, index) => (
				<CommunityRuleListItem
					key={index}
					rule={r}
				/>
			))}
		</div>
	);
}

CommunityRuleList.propTypes = {
	rules: propTypes.arrayOf(propTypes.shape({
		compiledRule: propTypes.string,
		position: propTypes.number,
		title: propTypes.string
	}))
};

export default CommunityRuleList;
