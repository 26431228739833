import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import advFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localFormat);
dayjs.extend(advFormat);
dayjs.extend(relativeTime);
