import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { compose, withState } from 'recompose';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../lib/constants';
import Headline from '../../lib/headline';
import AnimateHeight from 'react-animate-height';

import './communityRuleListItem.scss';
import Heroicon from '../../lib/heroicon';
import RenderedMarkdown from '../../renderedMarkdown';

const enhance = compose(
	withState('isOpen', 'setIsOpen', false),
)

export function CommunityRuleListItem(props) {
	const {
		rule,
		isOpen,
		setIsOpen
	} = props;

	return (
		<div className={classnames('community-rule-list-item', props.className)}>
			<Headline
				tag="h3"
				size={FONT_SIZES.regular}
				weight={FONT_WEIGHTS.semibold}
				onClick={() => setIsOpen(!isOpen)}
			>
				<span className="headline-text">
					{rule.position + 1}. {rule.title}
				</span>
				<span className="toggle">
					<Heroicon icon={isOpen ? 'ChevronUp' : 'ChevronDown'} />
				</span>
			</Headline>
			{!!(rule.compiledRule && rule.compiledRule.length) && (
				<AnimateHeight
					height={isOpen ? 'auto' : 0}
					duration={500}
					className="rule-content"
					animationStateClasses={{
						staticHeightZero: 'closed'
					}}
				>
					<RenderedMarkdown content={rule.compiledRule} />
				</AnimateHeight>
			)}
		</div>
	);
}

CommunityRuleListItem.propTypes = {
	rule: propTypes.shape({
		compiledRule: propTypes.string,
		position: propTypes.number,
		title: propTypes.string
	}),
	sessionData: propTypes.object,
};

export default enhance(CommunityRuleListItem);
