import _ from 'lodash';
import numeral from 'numeral';
import queryString from 'querystring';

export function reactifyBool(val) {
	return !!val ? 'true' : 'false';
}

function base64Decode(str) {
	if (typeof window !== 'undefined') {
		return atob(str);
	} else if (typeof Buffer !== 'undefined') {
		return Buffer.from(str, 'base64').toString();
	}
	return null;
}


export function parseQueryParams(location, options = {}) {
	const params = camelizeKeys(queryString.parse((location.search || '').replace(/^\?/, '')) || {});

	if (options.base64Fields) {
		_.each(options.base64Fields, (field) => {
			if (params[field]) {
				params[field] = base64Decode(params[field]);
			}
		});
	}

	return params;
}

export function camelizeKeys(obj) {
	if (!_.isArray(obj) && !_.isPlainObject(obj)) {
		return obj;
	}
	if (_.isArray(obj)) {
		return _.map(obj, (o) => camelizeKeys(o));
	}
	return _.transform(obj, (converted, val, key) => {
		if (_.isArray(val) || _.isPlainObject(val)) {
			val = camelizeKeys(val);
		}
		_.set(converted, _.camelCase(key), val);
		return converted;
	}, {});
}

export function modifiedSnakeCase(val) {
	return (_.snakeCase(val) || '').replace(/_([0-9]+)/g, '$1');
}

export function snakeifyKeys(obj) {
	if (!_.isArray(obj) && !_.isPlainObject(obj)) {
		return obj;
	}
	if (_.isArray(obj)) {
		return _.map(obj, (o) => snakeifyKeys(o));
	}
	return _.transform(obj, (converted, val, key) => {
		if (_.isArray(val) || _.isPlainObject(val)) {
			val = snakeifyKeys(val);
		}
		_.set(converted, modifiedSnakeCase(key), val);
		return converted;
	}, {});
}

export const extractQueryParams = (props) => ({
	...props,
	queryParams: camelizeKeys(queryString.parse((props.location.search || '').replace(/^\?/, '')))
});

export const buildReturnTo = ({ hostname, location }) => {
	return `returnTo=${hostname}${location.pathname}${location.search}`;
};

export const buildLoginReturnTo = ({ baseHostname, hostname, location }) => {
	return `${baseHostname}/login?${buildReturnTo({ hostname, location })}`;
};

export const buildLogoutReturnTo = ({ baseHostname, hostname, location }) => {
	return `${baseHostname}/logout?${buildReturnTo({ hostname, location })}`;
};

export const buildSignupReturnTo = ({ baseHostname, hostname, location }) => {
	return `${baseHostname}/signup?${buildReturnTo({ hostname, location })}`;
};

export const buildCommunityUrl = (communitySlug) => {
	return `/c/${communitySlug}`;
}

export const buildPostUrl = (communitySlug, postSlug, postShortId) => {
	return `${buildCommunityUrl(communitySlug)}/post/${postSlug}/${postShortId}`;
}

export const buildUserUrl = (username) => {
	return `/users/${username}`;
}

export const normalizeCommunitySlug = (slug) => {
	return (slug || '').toLowerCase();
};

export function hasSegment(fn) {
	if (typeof window.analytics === 'undefined') {
		return false
	}

	try {
		fn();
		return true;
	} catch(err) {
		console.log('Segment error: ', err);
	}
	return false;
}

export function hasHelpscout(fn) {
	if (typeof window.Beacon !== 'undefined') {
		try {
			fn();
		} catch(err) {
			console.log('Helpscout error: ', err);
		}
	}
}

const ONE_HOUR = 60 * 60;
const ONE_MIN = 60;

export function convertSecondsToHoursMinutesSeconds(sec) {
	sec = sec || 0;
	if (sec <= 0) {
		return { hours: 0, min: 0, sec: 0 };
	}
	if (sec < 60) {
		return { hours: 0, min: 0, sec };
	}
	const hours = ~~(sec / ONE_HOUR);
	const minutes = ~~((sec - (hours * ONE_HOUR)) / ONE_MIN);
	const seconds = sec - (hours * ONE_HOUR) - (minutes * ONE_MIN);

	return { hours, min: minutes, sec: seconds };
}

export const currencyFormatter = (minFractionDigits = 0, maxFractionDigits = 0) => Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: minFractionDigits,
	maximumFractionDigits: maxFractionDigits || minFractionDigits,
});

export function slugify(str) {
	return _.kebabCase(_.trim(str).replace(/[^a-zA-Z0-9\s-]/, '')).toLowerCase();
}

export function formatFullName(firstName, lastName) {
	return [firstName, lastName].join(' ');
}

export function buildModifierClassFromProps(modifiers = [], props) {
	return _.reduce(modifiers, (classes, modifier) => {
		if (props[modifier]) {
			classes.push(`${_.kebabCase(modifier)}-${props[modifier]}`);
		}
		return classes;
	}, []);
}

export function flattenCategories(categories = [], flatList = []) {
	return _.reduce(categories, (flattened, c) => {
		flattened.push(_.omit(c, ['children']));
		flattened.push(..._.map(c.children, (child) => {
			return {
				...child,
				name: `${c.name} > ${child.name}`
			};
		}));
		return flattened;
	}, flatList);
}

export function getHostnameForEnvironment(environment) {
	switch (environment) {
		case 'production':
			return 'https://klique.io';
		case 'staging':
		case 'development':
		default:
			return `https://${environment}.klique.io`;
	}
}

export function formatCommunitySubscribers(subscribers) {
	const intSubscribers = parseInt(subscribers);
	return `${numeral(intSubscribers).format('0,0')} subscriber${intSubscribers !== 1 ? 's' : ''}`;
}

export function isClientSide() {
	return typeof window !== 'undefined';
}
