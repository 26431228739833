export function getBaseTitle(title) {
	if (title && title.length) {
		return `${title} | Klique`;
	}
	return 'Your internet home page | Klique';
}

export function getBaseDescription(description) {
	if (!description || !description.length) {
		return ``;
	}
	return description;
}
