import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { buildModifierClassFromProps } from '../../../lib/helpers';

import './card.scss';

const modifierProps = ['background', 'shadow'];

export function Card(props) {
	const {
		className,
		children
	} = props;

	return (
		<div className={classnames('card', className, ...buildModifierClassFromProps(modifierProps, props))}>
			{children}
		</div>
	);
}

Card.propTypes = {
	className: propTypes.string,
	children: propTypes.any,
	shadow: propTypes.oneOf(['none', 'small', 'regular'])
};

Card.defaultProps = {
	shadow: 'small'
};

export default Card;
