import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import Link from '../../lib/link';
import { buildUserUrl } from '../../../lib/helpers';
import { FONT_SIZES } from '../../../lib/constants';

import './communityModeratorListItem.scss';

const enhance = compose()

export function CommunityModeratorListItem(props) {
	const {
		moderator,
		isCurrentUser
	} = props;

	return (
		<div className={classnames('community-moderator-list-item', props.className)}>
			<Link to={buildUserUrl(moderator.username)} size={FONT_SIZES.sm}>
				{moderator.username} {isCurrentUser ? (<span className="thats-you">(that's you!)</span>) : null}
			</Link>
		</div>
	);
}

CommunityModeratorListItem.propTypes = {
	moderator: propTypes.shape({
		username: propTypes.string,
	}),
	isCurrentUser: propTypes.bool
};

export default enhance(CommunityModeratorListItem);
