import { createAction } from 'redux-actions';
import { CALL_API } from '../middleware/api';

export const APP = {
	SET: 'APP/SET',
	SET_THEME: 'APP/SET_THEME'
};

export const setApp = createAction(APP.SET, (app) => ({ app }));

export const setTheme = createAction(APP.SET_THEME, (theme) => ({ theme }));

export function getTOS() {
	return {
		[CALL_API]: {
			types: [APP.GET_TOS_REQUEST, APP.GET_TOS_SUCCESS, APP.GET_TOS_FAILURE],
			endpoint: `/tos`,
			method: 'get',
		}
	};
}
