import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { withHandlers, withState } from 'recompose';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import { FONT_SIZES, FONT_WEIGHTS } from '../../lib/constants';
import { mapDispatchManageCommunityToProps, selectManageCommunity } from '../../store/selectors/community';
import CommunityRuleForm from '../forms/communityRuleForm';
import HeadlineWrapper from '../headlineWrapper';
import { Button } from '../lib/button';
import Headline from '../lib/headline';
import { Modal } from '../lib/modal';

import './manageCommunityRules.scss';

export const enhance = compose(
	withToastManager,
	connect(
		createSelector(
			selectManageCommunity,
			(communityRules) => ({
				...communityRules,
			})
		),
		{
			...mapDispatchManageCommunityToProps
		}
	),
	withState('updatingRule', 'setUpdatingRule', {}),
	withState('addingNewRule', 'setAddingNewRule', false),
	withHandlers({
		handleDeleteRule: (props) => (ruleId) => {
			const { community, updateCommunity, toastManager, setUpdatingRule, updatingRule } = props;

			updatingRule[ruleId] = true;
			setUpdatingRule(updatingRule);

			return updateCommunity(community.id, {
				rules: [
					{ id: ruleId, remove: true }
				]
			})
			.then((data) => {
				updatingRule[ruleId] = false;
				setUpdatingRule(updatingRule);
				if (!data.error) {
					toastManager.add('Rule deleted', { appearance: 'success', autoDismiss: true });
				} else {
					toastManager.add(_.get(data.error, 'response.data.message') || 'There was a problem deleting that rule', { appearance: 'error', autoDismiss: true });
				}
				return data;
			});
		},
		handleUpdateRule: (props) => (ruleId, fields) => {
			const { community, updateCommunity, toastManager, updatingRule, setUpdatingRule } = props;

			updatingRule[ruleId] = true;
			setUpdatingRule(updatingRule);

			return updateCommunity(community.id, {
				rules: [
					{ id: ruleId, data: fields }
				]
			})
			.then((data) => {
				updatingRule[ruleId] = false;
				setUpdatingRule(updatingRule);
				if (!data.error) {
					toastManager.add('Rule updated', { appearance: 'success', autoDismiss: true });
				} else {
					toastManager.add(_.get(data.error, 'response.data.message') || 'There was a problem updating the rule', { appearance: 'error', autoDismiss: true });
				}
				return data;
			});
		},
		handleAddNewRule: (props) => (fields) => {
			const {
				updatingRule,
				setUpdatingRule,
				updateCommunity,
				community,
				toastManager,
				setAddingNewRule
			} = props;

			updatingRule.newRule = true;
			setUpdatingRule(updatingRule);

			return updateCommunity(community.id, {
				rules: [
					{ data: fields }
				]
			})
			.then((data) => {
				updatingRule.newRule = true;
				setUpdatingRule(updatingRule);
				if (!data.error) {
					toastManager.add('Rule added', { appearance: 'success', autoDismiss: true });
					setAddingNewRule(false);
				} else {
					toastManager.add(_.get(data.error, 'response.data.message') || 'There was a problem adding a new rule', { appearance: 'error', autoDismiss: true });
				}
				return data;
			});
		}
	})
);

export function ManageCommunityRules(props) {
	const {
		rules,
		handleDeleteRule,
		handleUpdateRule,
		updatingCommunity,
		updatingRule,
		addingNewRule,
		setAddingNewRule,
		handleAddNewRule
	} = props;
	const sortedRules = _.sortBy(rules, (r) => r.position);
	return (
		<div className={classnames('manage-community-rules', props.className)}>
			<HeadlineWrapper underline>
				<Headline tag="h2" size={FONT_SIZES.xl} weight={FONT_WEIGHTS.bold}>
					Update Community Rules
				</Headline>
				<Button size="small" onClick={() => setAddingNewRule(true)}>
					Add new rule
				</Button>
			</HeadlineWrapper>
			<div className="community-rules-wrapper">
				{_.map(sortedRules, (r) => (
					<CommunityRuleForm
						key={r.id}
						onSubmit={(fields) => handleUpdateRule(r.id, fields)}
						onDelete={() => handleDeleteRule(r.id)}
						formError={updatingCommunity.error}
						loading={updatingRule[r.id] === true}
						initialValues={{
							...r
						}}
					/>
				))}
			</div>
			<Modal
				onClose={() => setAddingNewRule(false)}
				closeable={true}
				showing={addingNewRule === true}
			>
				<Headline tag="h2" size={FONT_SIZES.lg} weight={FONT_WEIGHTS.bold}>
					Add new Community Rule
				</Headline>
				{addingNewRule === true && (
					<CommunityRuleForm
						onSubmit={handleAddNewRule}
						onDelete={() => {}}
						formError={updatingCommunity.error}
						loading={updatingRule.newRule === true}
						action="create"
					/>
				)}
			</Modal>
		</div>
	);
}

ManageCommunityRules.propTypes = {
	community: propTypes.shape({
		id: propTypes.string,
	}).isRequired,
	rules: propTypes.arrayOf(propTypes.shape({
		id: propTypes.string,
		title: propTypes.string,
		position: propTypes.number,
		rawRule: propTypes.string
	}))
};

export default enhance(ManageCommunityRules);
