import axios from 'axios';
import { cloneDeep, get, omit } from 'lodash';

export const CALL_API = 'CALL_API';

export function callApi(endpoint, options = {
	method: 'GET',
	headers: {},
	query: {},
	data: {}
}, next, progressActionData) {
	const payload = {
		method: options.method,
		url: `${endpoint}`,
		params: options.query,
		headers: options.headers,
		validateStatus: (status) => status < 400
	};

	if (options.formData) {
		payload.data = options.formData;
		payload.headers = {
			...payload.headers,
			'content-type': 'multipart/form-data'
		};
		payload.onUploadProgress = (progress) => {
			const progressPercent = Math.round((progress.loaded / progress.total) * 100);
			if (next && progressActionData) {
				const { action, actionData } = progressActionData;
				next(actionWith(action, {
					...actionData,
					payload: { progressPercent }
				}));
			}
		};
	} else {
		payload.data = options.data;
	}

	return axios(_.cloneDeep(payload))
	.then((response) => get(response, 'data') || {})
	.catch((err) => {
		if (err.response.status !== 404) {
			// hasRoll((rollbar) => {
			// 	rollbar.error(err, {
			// 		payload,
			// 		response: err.response
			// 	});
			// });
		}
		throw err;
	});
}

function actionWith(action, actionData) {
	return omit({
		...action,
		...actionData
	}, CALL_API);
}

export default (store) => (next) => (action = {}) => {
	const callService = action[CALL_API];

	if (typeof callService === 'undefined') {
		return next(action);
	}

	const { types, method, data, formData, query, options } = callService;
	const [requestType, successType, failureType, progressType] = types;
	const state = store.getState();

	let { endpoint } = callService;
	if (typeof endpoint === 'function') {
		endpoint = endpoint(state);
	}

	// Fire Request Action
	next(actionWith(action, {
		type: requestType,
		data,
		formData,
		query,
		options: cloneDeep(options)
	}));

	const requestOptions = {
		method: method || 'GET',
		data,
		formData,
		query
	};

	if (endpoint.indexOf('http') < 0) {
		let host = state.app.api.baseUrl;
		if (typeof window !== 'undefined') {
			host = `${get(window, 'location.origin')}/api`;
		} else {
			requestOptions.headers = {
				...(requestOptions.headers || {}),
				...(state.session.token ? {
					'x-access-token': state.session.token,
					'x-visitor-id': state.session.visitorId
				} : {})
			};
		}

		endpoint = `${host}${endpoint}`;
	}

	return callApi(endpoint, requestOptions, next, {
		action,
		actionData: {
			type: progressType,
			options: { ...(options || {}) }
		}
	})
	.then((payload) => {
		return next(actionWith(action,{
			type: successType,
			payload,
			options: cloneDeep(options || {})
		}))
	})
	.catch((error) => next(actionWith(action,{
		type: failureType,
		error,
		options: cloneDeep(options || {})
	})));
};
