import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';

import './progressBar.scss';

export function ProgressBar(props) {
	return (
		<div className={classnames('progress-bar', props.className)}>
			<div
				className="progress-overlay"
				style={{ width: `${~~(props.percent || 0)}%`}}
			/>
		</div>
	);
}

ProgressBar.propTypes = {
	percent: propTypes.number
};

export default ProgressBar;
