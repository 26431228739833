import React from 'react';
import { compose, lifecycle } from 'recompose';
import { hasHelpscout, hasSegment } from '../../lib/helpers';

export const enhance = compose(
	lifecycle({
		componentDidMount() {
			hasHelpscout(() => {
				window.Beacon('logout', { endActiveChat: true });
				window.Beacon('reset');
			});
			hasSegment(() => {
				window.analytics.reset();
			});
			setTimeout(() => {
				location.href = '/logout';
			}, 200);
		}
	})
);

export function LogoutPage() {
	return null;
}

export default enhance(LogoutPage);
